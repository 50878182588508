/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./Deliveryandreturn.css";
import logo from "../../assets/images/crest-large.png";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { listPrice } from "../../actions/productaction";
import { Translate } from "react-auto-translate";
import { useLayoutEffect } from "react";

export const Deliveryandreturn = () => {
    const [datas, setdatas] = useState(null);
    const dispatch = useDispatch();

    useEffect(async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}return`);
        setdatas(data.user[0]);
    }, []);
    const prices = useSelector((state) => state.priceList);
    const { price } = prices;

    useEffect(() => {
        dispatch(listPrice());
    }, [dispatch]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    let contactPage = () => {
        window.location.replace("/contact")
    }

    const currentLocation = localStorage.getItem('country_code_iso3')

    return (
        localStorage.getItem("translate") === "en"
            ? currentLocation === "KSA"
                ? <div className="aboutus-container">
                    <div className="aboutus-logo">
                        <hr />
                        <img src={logo} alt="" />
                        <hr />
                    </div>

                    <div className="aboutus-head">
                        <h1>RETURNS & CANCELLATIONS</h1>
                    </div>

                    <div className="aboutus-container-box">
                        <div className="about-us-decor">
                            <div>
                                <h5>RETURN POLICY</h5>
                                <p>
                                    <ol>
                                        <li>All items are subject to return except for: Agarwood, D/Oudh and attars in loose forms.</li>
                                        <li>Return is accepted if products with original packaging.</li>
                                        <li>The submissions of a return request have to be within seven (7) days from the date of delivery.</li>
                                        <li>In case of damaged or pilferage items the submissions of a return request have to be within seven (7) days from the date of delivery.</li>
                                        <li>The submissions of a return request for items purchased on offer is within seven (7) days from the date of delivery.</li>
                                        <li>Any Complimentary gifts which comes along with purchase needs to be returned.</li>
                                        <li>Return Shipping charges will be borne by the customer except for damaged or pilferage items.</li>
                                        <li>Original invoice is required for the return.</li>
                                    </ol>
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5>HOW TO RETURN YOUR PURCHASE</h5>
                                <p>To submit a return request, please email: estore.ksa@makkaj.com ,with your order number and description of the issue you faced.</p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5>RECEIVING A REFUND</h5>
                                <p>
                                    Refund will be made to the customer after receiving the returned goods within 10-15 working days via credit card or bank transfer.
                                    <br></br>
                                    <br></br>
                                    Business days from from Sunday till Thursday, except national holidays in Saudi Arabia.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5>EXCHANGES</h5>
                                <p>Currently orders cannot be exchanged online.</p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5>CANCELLATIONS</h5>
                                <p>
                                    In certain circumstances, before your order has been dispatched, we may be able to cancel your order. If you wish to cancel your order, then you’re required to inform Makkaj Perfumes through email or telephone about your cancellation within 24 hour from the time of order being placed.
                                    <br></br>
                                    <br></br>
                                    Please note, we are unable to combine orders, change the items, edit your billing and shipping details or add pieces to an existing order once it has been placed.
                                    <br></br>
                                    <br></br>
                                    Your order will be processed and, subject to being accepted by us, a confirmation email will be sent upon shipping.
                                </p>
                            </div>
                        </div>

                        <div style={{ textAlign: 'center', marginTop: '10vh' }}>
                            <p className="contact_us" onClick={() => window.location.href = '/contact'}>For any further queries, please contact us</p>
                        </div>
                    </div>
                </div>
                : <div className="aboutus-container">
                    <div className="aboutus-logo">
                        <hr />
                        <img src={logo} alt="" />
                        <hr />
                    </div>

                    <div className="aboutus-head">
                        <h1>RETURNS & CANCELLATIONS</h1>
                    </div>

                    <div className="aboutus-container-box">
                        <div className="about-us-decor">
                            <div>
                                <h5>RETURN POLICY</h5>
                                <p>
                                    <ol>
                                        <li>All items are subject to return except for: Agarwood, D/Oudh and attars in loose forms.</li>
                                        <li>Return is accepted if products with original packaging.</li>
                                        <li>The submissions of a return request have to be within seven (7) days from the date of delivery.</li>
                                        <li>In case of damaged or pilferage items the submissions of a return request have to be within seven (7) days from the date of delivery.</li>
                                        <li>The submissions of a return request for items purchased on offer is within seven (7) days from the date of delivery.</li>
                                        <li>Any Complimentary gifts which comes along with purchase needs to be returned.</li>
                                        <li>Return Shipping charges will be borne by the customer except for damaged or pilferage items.</li>
                                        <li>Original invoice is required for the return.</li>
                                    </ol>
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5>HOW TO RETURN YOUR PURCHASE</h5>
                                <p>To submit a return request, please email: estore.uae@makkaj.com ,with your order number and description of the issue you faced.</p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5>RECEIVING A REFUND</h5>
                                <p>
                                    Refund will be made to the customer after receiving the returned goods within 10-15 working days via credit card or bank transfer.
                                    <br></br>
                                    <br></br>
                                    Business days from Monday to Friday, except national holidays in UAE.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5>EXCHANGES</h5>
                                <p>Currently orders cannot be exchanged online.</p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5>CANCELLATIONS</h5>
                                <p>
                                    In certain circumstances, before your order has been dispatched, we may be able to cancel your order. If you wish to cancel your order, then you’re required to inform Makkaj Perfumes through email or telephone about your cancellation within 24 hour from the time of order being placed.
                                    <br></br>
                                    <br></br>
                                    Please note, we are unable to combine orders, change the items, edit your billing and shipping details or add pieces to an existing order once it has been placed.
                                    <br></br>
                                    <br></br>
                                    Your order will be processed and, subject to being accepted by us, a confirmation email will be sent upon shipping.
                                </p>
                            </div>
                        </div>

                        <div style={{ textAlign: 'center', marginTop: '10vh' }}>
                            <p className="contact_us" onClick={() => window.location.href = '/contact'}>For any further queries, please contact us</p>
                        </div>
                    </div>
                </div>
            : currentLocation === "KSA"
                ? <div className="aboutus-container">
                    <div className="aboutus-logo">
                        <hr />
                        <img src={logo} alt="" />
                        <hr />
                    </div>

                    <div className="aboutus-head">
                        <h1>عمليات الإرجاع والإلغاء</h1>
                    </div>

                    <div className="aboutus-container-box">
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>سياسة الإسترجاع</p></h5>
                                <p className="text_rtl">
                                    <ol>
                                        <li>يشمل الإسترجاع جميع الأصناف بإستثناء خشب العود ودهن العود والعطورات غير المغلفة وذلك لصحة وسلامة المستهلك .</li>
                                        <li>يمكن الإسترجاع بشرط أن يكون المنتج بنفس حالته الأصلية عند الإستلام ومغلفًا بالغلاف الأصلي.</li>
                                        <li>رفع طلب الإسترجاع يكون خلال سبعة ( ٧ ) ايام من تاريخ استلام الطلب .</li>
                                        <li>في حال وجود عيب مصنعي يكون رفع طلب الإسترجاع خلال ثلاثة ( ( ٣ ايام من تاريخ استلام الطلب .</li>
                                        <li>يمكن رفع طلب إسترجاع المنتجات المخفضة خلال ثلاثة ( ( ٣ ايام من تاريخ استلام الطلب .</li>
                                        <li>يلزم عند الإسترجاع إعادة جميع الهدايا المستلمة مع الطلب .</li>
                                        <li>يتكفل العميل بدفع رسوم شحن الإسترجاع ويستثنى من ذلك في حال وجود عيب مصنعي .</li>
                                        <li>يرجى الإحتفاظ بإشعار الشحن الى ان يتم إعادة المبلغ</li>
                                    </ol>
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>كيفية إعادة مشترياتك</p></h5>
                                <p className="text_rtl">رفع طلب الإسترجاع عن طريق البريد الإلكتروني : estore.ksa@makkaj.com مع توضيح رقم الطلب والمشكلة التي تواجهك</p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>طريقة إعادة المبلغ</p></h5>
                                <p className="text_rtl">
                                    سيتم إعادة المبلغ للعميل بعد استلام الشحنة المعادة في مدة تتراوح من ١٠-١٥ ايام عمل عن طريق البطاقة الإئتمانية أو حوالة بنكية على حساب العميل
                                    <br></br>
                                    <br></br>
                                    أيام العمل الرسمية هي من الاحد إلى الخميس، بإستثناء العطل الرسمية في المملكة العربية السعودية
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>الاستبدال</p></h5>
                                <p className="text_rtl">حالياً لا يمكن استبدال الطلبات عبر الإنترنت</p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>الإلغاء</p></h5>
                                <p className="text_rtl">
                                    في ظروف معينة، قبل إرسال طلبك، قد يكون بمقدورك إلغاء طلبك. إذا رغبت بإلغاء طلبك، يجب عليك إعلام عطور أجمل بواسطة البريد الإلكتروني أو الهاتف حول إلغائك خلال ٢٤ ساعة عمل من وقت إجراء الطلب
                                    <br></br>
                                    <br></br>
                                    يرجى أخذ العلم أننا لا نستطيع دمج الطلبات، تغيير المنتجات، تعديل فاتورتك وتفاصيل الشحن أو إضافة قطع إلى طلب موجود بعد تقديمه
                                    <br></br>
                                    <br></br>
                                    سيتم تجهيز طلبك، وفي حال موافقتنا عليه، سنرسل إليك رسالة تأكيد بالبريد الإلكتروني عندما يتم الشحن
                                </p>
                            </div>
                        </div>

                        <div style={{ textAlign: 'center', marginTop: '10vh' }}>
                            <p className="contact_us" onClick={() => window.location.href = '/contact'}>للحصول على أي استفسارات أخرى ، يرجى الاتصال بنا</p>
                        </div>
                    </div>
                </div>
                : <div className="aboutus-container">
                    <div className="aboutus-logo">
                        <hr />
                        <img src={logo} alt="" />
                        <hr />
                    </div>

                    <div className="aboutus-head">
                        <h1>عمليات الإرجاع والإلغاء</h1>
                    </div>

                    <div className="aboutus-container-box">
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>سياسة الإسترجاع</p></h5>
                                <p className="text_rtl">
                                    <ol>
                                        <li>يشمل الإسترجاع جميع الأصناف بإستثناء خشب العود ودهن العود والعطورات غير المغلفة وذلك لصحة وسلامة المستهلك .</li>
                                        <li>يمكن الإسترجاع بشرط أن يكون المنتج بنفس حالته الأصلية عند الإستلام ومغلفًا بالغلاف الأصلي.</li>
                                        <li>رفع طلب الإسترجاع يكون خلال سبعة ( ٧ ) ايام من تاريخ استلام الطلب .</li>
                                        <li>في حال وجود عيب مصنعي يكون رفع طلب الإسترجاع خلال ثلاثة ( ( ٣ ايام من تاريخ استلام الطلب .</li>
                                        <li>يمكن رفع طلب إسترجاع المنتجات المخفضة خلال ثلاثة ( ( ٣ ايام من تاريخ استلام الطلب .</li>
                                        <li>يلزم عند الإسترجاع إعادة جميع الهدايا المستلمة مع الطلب .</li>
                                        <li>يتكفل العميل بدفع رسوم شحن الإسترجاع ويستثنى من ذلك في حال وجود عيب مصنعي .</li>
                                        <li>يرجى الإحتفاظ بإشعار الشحن الى ان يتم إعادة المبلغ</li>
                                    </ol>
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>كيفية إعادة مشترياتك</p></h5>
                                <p className="text_rtl">رفع طلب الإسترجاع عن طريق البريد الإلكتروني : estore.uae@makkaj.com مع توضيح رقم الطلب والمشكلة التي تواجهك</p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>طريقة إعادة المبلغ</p></h5>
                                <p className="text_rtl">
                                    سيتم إعادة المبلغ للعميل بعد استلام الشحنة المعادة في مدة تتراوح من ١٠-١٥ ايام عمل عن طريق البطاقة الإئتمانية أو حوالة بنكية على حساب العميل
                                    <br></br>
                                    <br></br>
                                    أيام العمل الرسمية هي من من الاثنين إلى الجمعة ، باستثناء أيام العطل الرسمية في دولة الإمارات العربية المتحدة
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>الاستبدال</p></h5>
                                <p className="text_rtl">حالياً لا يمكن استبدال الطلبات عبر الإنترنت</p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>الإلغاء</p></h5>
                                <p className="text_rtl">
                                    في ظروف معينة، قبل إرسال طلبك، قد يكون بمقدورك إلغاء طلبك. إذا رغبت بإلغاء طلبك، يجب عليك إعلام عطور أجمل بواسطة البريد الإلكتروني أو الهاتف حول إلغائك خلال ٢٤ ساعة عمل من وقت إجراء الطلب
                                    <br></br>
                                    <br></br>
                                    يرجى أخذ العلم أننا لا نستطيع دمج الطلبات، تغيير المنتجات، تعديل فاتورتك وتفاصيل الشحن أو إضافة قطع إلى طلب موجود بعد تقديمه
                                    <br></br>
                                    <br></br>
                                    سيتم تجهيز طلبك، وفي حال موافقتنا عليه، سنرسل إليك رسالة تأكيد بالبريد الإلكتروني عندما يتم الشحن
                                </p>
                            </div>
                        </div>

                        <div style={{ textAlign: 'center', marginTop: '10vh' }}>
                            <p className="contact_us" onClick={() => window.location.href = '/contact'}>للحصول على أي استفسارات أخرى ، يرجى الاتصال بنا</p>
                        </div>
                    </div>
                </div>
    )
};