import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { orderPost, shippingaddressget } from "../actions/orderaction";
import { listPrice } from "../actions/productaction";
import { shippingaddress } from "../actions/orderaction";
import axios from "axios";


export const UpdateShipping = (props) => {
    const [email, setemail] = useState("");
    const [addresseses, setaddress] = useState("");
    const [fname, setfname] = useState("");
    const [lname, setlname] = useState("");
    const [apartment, setapartment] = useState("");
    const [phone, setphone] = useState("");
    const [city, setcity] = useState("");
    const [state, setstate] = useState("");
    const [pin, setpin] = useState("");
    const [country, setcountry] = useState("");
    const [countryList, setCountryList] = useState("");

    //dispatch
    const dispatch = useDispatch();

    const changeHandler = (e) => {
        setemail(e.target.value);
    };
    const changeHandler1 = (e) => {
        setaddress(e.target.value);
    };
    const changeHandler2 = (e) => {
        setfname(e.target.value);
    };
    const changeHandler3 = (e) => {
        setlname(e.target.value);
    };
    const changeHandler4 = (e) => {
        setapartment(e.target.value);
    };
    const changeHandler5 = (e) => {
        setphone(e.target.value);
    };
    const changeHandler6 = (e) => {
        setcity(e.target.value);
    };
    const changeHandler7 = (e) => {
        setstate(e.target.value);
    };
    const changeHandler8 = (e) => {
        setpin(e.target.value);
    };
    const changeHandler9 = (e) => {
        setcountry(e.target.value);
    };

    const addresses = useSelector((state) => state.shipping);
    const { address } = addresses;

    const getCountryList = async () => {
        const data = await axios.get(`${process.env.REACT_APP_API_URL}countrylist`);
        console.log(data.data)
        setCountryList(data.data)
    }

    let location = useLocation();

    const submitHandler = async (e) => {
        let searchParams = new URLSearchParams(location.search);
        let index = searchParams.get('index');

        let resp = await axios.post(`${process.env.REACT_APP_API_URL}update-address`, {
            "index": index,
            "userId": JSON.parse(localStorage.getItem("userInfo")).user,
            "firstname": fname,
            "lastname": lname,
            "address": addresseses,
            "appartment": apartment,
            "city": city,
            "country": country,
            "state": state,
            "pin": pin,
            "phone": phone,
            "email": email
        })

        console.log({ resp })
        window.location.replace("/add-address")
    };

    let defaultLanguage = localStorage.getItem('translate')

    useEffect(() => {
        let searchParams = new URLSearchParams(location.search);
        let index = searchParams.get('index');

        getCountryList();
        dispatch(
            shippingaddressget(JSON.parse(localStorage.getItem("userInfo")).user)
        );
        dispatch(listPrice());

        const fetchData = async () => {
            try {
                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_API_URL}address/${JSON.parse(localStorage.getItem("userInfo")).user}`,
                    headers: {}
                };

                await axios.request(config)
                    .then((response) => {
                        console.log(response.data.user[0]);

                        setemail(response.data.user[0].address[index - 1]['email'])
                        setfname(response.data.user[0].address[index - 1]['firstname'])
                        setlname(response.data.user[0].address[index - 1]['lastname'])
                        setaddress(response.data.user[0].address[index - 1]['address'])
                        setapartment(response.data.user[0].address[index - 1]['appartment'])
                        setcity(response.data.user[0].address[index - 1]['city'])
                        setcountry(response.data.user[0].address[index - 1]['country'])
                        setstate(response.data.user[0].address[index - 1]['state'])
                        setpin(response.data.user[0].address[index - 1]['pin'])
                        setphone(response.data.user[0].address[index - 1]['phone'])
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [dispatch])

    return (
        <div style={{}} className="checkout-page">
            <div className="top-info">
                <h3>
                    {
                        defaultLanguage === 'en'
                            ? 'ADD ADDRESS'
                            : 'اضف عنوان'
                    }
                </h3>
            </div>

            <div className="left-info">
                <div className="top-logo">
                    <img src="/images/logo.png" alt="logo" />
                </div>
                <div className="contact-info">
                    <p>
                        {
                            defaultLanguage === 'en'
                                ? 'Contact information'
                                : 'معلومات الاتصال'
                        }
                    </p>
                    <p className="just-width"></p>

                    {JSON.parse(localStorage.getItem("login")) !== true && (
                        <p>Already Have an account? Log in</p>
                    )}

                    <input
                        type="email"
                        placeholder={
                            defaultLanguage === 'en'
                                ? 'Email'
                                : 'بريد إلكتروني'
                        }
                        value={email}
                        onChange={changeHandler}
                    />
                </div>
                <div className="lower-info">
                    <h2 style={{ paddingLeft: "0" }}>
                        {
                            defaultLanguage === 'en'
                                ? 'Shipping address'
                                : 'عنوان الشحن'
                        }
                    </h2>
                </div>
                <div>
                    <form
                        onSubmit={submitHandler}
                        className="checkout-form">
                        <input
                            value={fname}
                            onChange={changeHandler2}
                            style={{ width: "40.7%", padding: "1rem", margin: "0.5rem" }}
                            type="text"
                            placeholder={
                                defaultLanguage === 'en'
                                    ? 'First Name'
                                    : 'الاسم الأول'
                            }
                        />
                        <input
                            value={lname}
                            onChange={changeHandler3}
                            style={{ width: "40.7%", padding: "1rem", margin: "0.5rem" }}
                            type="text"
                            placeholder={
                                defaultLanguage === 'en'
                                    ? 'last Name'
                                    : 'اسم العائلة'
                            }
                        />
                        <input
                            value={addresseses}
                            onChange={changeHandler1}
                            style={{ width: "88%", padding: "1rem", margin: "0.5rem" }}
                            type="text"
                            placeholder={
                                defaultLanguage === 'en'
                                    ? 'Address'
                                    : 'عنوان'
                            }
                            required
                        />
                        <input
                            value={apartment}
                            onChange={changeHandler4}
                            style={{ width: "88%", padding: "1rem", margin: "0.5rem" }}
                            type="text"
                            placeholder={
                                defaultLanguage === 'en'
                                    ? 'Apartment, suite, etc. (Optional)'
                                    : 'شقة، جناح'
                            }
                            required
                        />
                        <input
                            value={city}
                            onChange={changeHandler6}
                            style={{ width: "88%", padding: "1rem", margin: "0.5rem" }}
                            type="text"
                            placeholder={
                                defaultLanguage === 'en'
                                    ? 'City'
                                    : 'مدينة'
                            }
                            required
                        />

                        <select
                            className="select-country"
                            name="size"
                            onChange={changeHandler9}
                            value={country}
                            style={{ width: "30%", padding: "1rem", margin: "0.5rem", color: country !== "" ? 'white' : '', border: "1px solid #c69736" }}
                        >
                            <option name="size" value="">
                                {
                                    defaultLanguage === 'en'
                                        ? 'Choose Country'
                                        : 'اختر البلد'
                                }
                            </option>
                            {
                                countryList.length > 0
                                    ? countryList.map((C, i) => {
                                        return (
                                            <option name="size" value={C.name}>
                                                {C.name}
                                            </option>
                                        )
                                    })
                                    : null
                            }
                        </select>

                        <input
                            value={state}
                            onChange={changeHandler7}
                            style={{ width: "25%", padding: "1rem", margin: "0.5rem" }}
                            type="text"
                            placeholder={
                                defaultLanguage === 'en'
                                    ? 'State'
                                    : 'ولاية'
                            }
                        />
                        <input
                            value={pin}
                            onChange={changeHandler8}
                            style={{ width: "25%", padding: "1rem", margin: "0.5rem" }}
                            type="text"
                            placeholder={
                                defaultLanguage === 'en'
                                    ? 'Zip code'
                                    : 'الرمز البريدي'
                            }
                        />
                        <input
                            value={phone}
                            onChange={changeHandler5}
                            style={{ width: "88%", padding: "1rem", margin: "0.5rem" }}
                            type="text"
                            placeholder={
                                defaultLanguage === 'en'
                                    ? 'Phone number'
                                    : 'رقم التليفون'
                            }
                        />
                    </form>
                    <br></br>
                    <input
                        style={{
                            width: "25%",
                            padding: "1rem",
                            margin: "1rem",
                            marginLeft: "0.5rem",
                            color: "#fff",
                            backgroundColor: "#c69736",
                        }}
                        type="button"
                        onClick={() => submitHandler()}
                        value={
                            defaultLanguage === 'en'
                                ? 'Save Address'
                                : 'حفظ العنوان'
                        }
                    />
                </div>
            </div>

        </div>
    );
};
