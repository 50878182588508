import axios from "axios";
import { BANNER_FAIL, BANNER_REQUEST, BANNER_SUCCESS } from "../constant/bannerconstant";
export const getbanner = () => async (dispatch) => {
    try {

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}banner`
        );

        dispatch({
            type: BANNER_SUCCESS,
            payload: data.user,
        });
    } catch (error) {
        dispatch({
            type: BANNER_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};