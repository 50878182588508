import { BiEditAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin5Line } from "react-icons/ri";
import "./wishlistcard.css";
import { removeproductwishlist } from "../../actions/wishlistaction";
import { useState } from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { addproductcart } from "../../actions/cartactions";

import { defaultLanguage } from "../../helpers"

const Wishlistcard = (props) => {
    let country = localStorage.getItem('country_code_iso3')
    let currency = localStorage.getItem('currency')

    const uid = JSON.parse(localStorage.getItem("userInfo")).user;
    const { img, description, size, productid, title, price, id, item } = props;

    const dispatch = useDispatch();

    const addtoCart = () => {
        dispatch(
            addproductcart(
                item.title,
                item.arabictitle,
                item.image,
                item.description,
                item.price[currency],
                item.weight,
                item.dimensions,
                1,
                item.productId,
                uid,
                item.discountprice[currency],
                item.priceicon
            )
        );

        window.alert(defaultLanguage === 'en' ? "Product is added to cart" : 'يضاف المنتج الى سلة التسويق')
        window.location.reload();
    }

    return (
        <div key={id} className="card">
            <RiDeleteBin5Line
                style={{
                    margin: "1rem",
                    color: "gold"
                }}
                onClick={() => {
                    dispatch(removeproductwishlist(id));
                    window.alert(defaultLanguage === 'en' ? "Product removed from wishlist" : "تمت إزالة المنتج من قائمة الرغبات")
                }}
            />

            <div>
                <img
                    style={{ marginTop: "1rem" }}
                    className="medium"
                    src={img}
                    alt={title}
                />
            </div>

            {/* <Link to={`/details/${productid}/${title}`}>
                <img
                    style={{ marginTop: "1rem" }}
                    className="medium"
                    src={img}
                    alt={title}
                />
            </Link> */}

            <div className="card-body">
                <h2>
                    {
                        defaultLanguage === "en"
                            ? title
                            : item.arabictitle
                    }
                </h2>

                <div className="short">
                    <p style={{ color: '#c69736' }}>{currency} {item.discountprice[currency]}</p>
                </div>

                <div
                    style={{ marginTop: "3rem", display: "flex" }}
                    className="add-to-cart-button"
                >
                    <button onClick={() => addtoCart(item)}>
                        ADD TO CART
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Wishlistcard;