import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import { Sidebar } from "../../components/sidebar/sidebar";

import { defaultLanguage } from "../../helpers"

import './reset.css'

export const ChangePass = () => {
    // const { userId, token } = useParams();

    const [password1, set_password1] = useState()
    const [password2, set_password2] = useState()
    const [password3, set_password3] = useState()

    const updatePassword = () => {
        let password_reset_url = process.env.REACT_APP_API_URL + 'change-password'
        let reqBody = {
            old_password: password3,
            new_password: password1,
            userId: JSON.parse(localStorage.getItem('userInfo'))['user'],
            token: JSON.parse(localStorage.getItem('userInfo'))['token'],
            email: JSON.parse(localStorage.getItem('userInfo'))['email']
        }

        console.log({ reqBody, password_reset_url, password1, password2, password3 })

        if (password1 !== password2) {
            window.alert('both new password must be same')
        } else if (password1 === undefined && password2 === undefined) {
            window.alert('both new password must be filled with same password')
        } else if (password3 === undefined) {
            window.alert('current password is also required')
        } else {
            axios.post(password_reset_url, reqBody)
                .then((response) => {
                    console.log(response)
                    if (response.data.msg === 'successfully changed password') {
                        localStorage.removeItem('userInfo')
                        localStorage.removeItem('login')
                        window.alert(response.data.msg)
                        window.location.href = '/';
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

            console.log({ reqBody, password_reset_url })
        }
    }

    return (

        <div>
            <h1 className="breadcrumb-css">
                {
                    defaultLanguage === "en"
                        ? 'Change Password'
                        : 'تغيير كلمة المرور'
                }
            </h1>
            <div className="my-account">
                <div className="sidebar-left">
                    {" "}
                    <Sidebar />
                </div>

                {/* .myorder-right {
                    width: 68%;
                display: inline-block;
                padding: 50px 0;
                text-align: center;
                margin: 0 80px; */}

                <div style={{
                    width: '75%',
                    minHeight: '30vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <div className='pass-change-form-ctr2'>
                        <div style={{ marginBottom: '45px' }}>
                            <h2 style={{ textAlign: 'center', padding: '0', margin: '0', color: '#c69736', }}>
                                {
                                    defaultLanguage === "en"
                                        ? 'Change Password'
                                        : 'تغيير كلمة المرور'
                                }
                            </h2>
                        </div>

                        <form method="POST" className="reset-pass-form" id="reset-pass-form">
                            <div className="form-group">
                                <label for="your_pass1"><i className="zmdi zmdi-lock"></i></label>
                                <input
                                    className="reset-pass-form-input"
                                    type="password"
                                    name="your_pass3"
                                    id="your_pass3"
                                    placeholder={
                                        defaultLanguage === "en"
                                            ? 'Enter Current Password'
                                            : 'إدخل كلمة السر الحالية'
                                    }
                                    onChange={(e) => set_password3(e.target.value)}
                                />
                            </div>

                            <div className="form-group">
                                <label for="your_pass1"><i className="zmdi zmdi-lock"></i></label>
                                <input
                                    className="reset-pass-form-input"
                                    type="password"
                                    name="your_pass1"
                                    id="your_pass1"
                                    placeholder={
                                        defaultLanguage === "en"
                                            ? 'Enter New Password'
                                            : 'أدخل كلمة المرور الجديدة'
                                    }
                                    onChange={(e) => set_password1(e.target.value)}
                                />
                            </div>

                            <div className="form-group">
                                <label for="your_pass2"><i className="zmdi zmdi-lock"></i></label>
                                <input
                                    className="reset-pass-form-input"
                                    type="password"
                                    name="your_pass2"
                                    id="your_pass2"
                                    placeholder={
                                        defaultLanguage === "en"
                                            ? 'Re-Enter New Password'
                                            : 'أعد إدخال كلمة المرور الجديدة'
                                    }
                                    onChange={(e) => set_password2(e.target.value)}
                                />
                            </div>
                        </form>

                        <div className="form-group form-button">
                            <input
                                type="button"
                                name="update"
                                id="update"
                                className="reset-form-submit-btn"
                                value={
                                    defaultLanguage === "en"
                                        ? 'Change Password'
                                        : 'تغيير كلمة المرور'
                                }
                                onClick={() => updatePassword()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
