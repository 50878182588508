import { BiEditAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Translate } from "react-auto-translate";
import "./cartcard.css";
import {
    cartdec,
    removeproductcart,
    cartadd,
    subtotal,
    addproductcart,
    cartlist
} from "../../actions/cartactions";
import { useState } from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import icon_p from '../../assets/icon/plus.png'
import icon_m from '../../assets/icon/minus.png'

const Cartcard = (props) => {
    const { img, description, size, productid, title, arabictitle, count, price, id, item } = props;

    const [input, setinput] = useState(count);

    const [maxProdCount, setMaxProdCount] = useState(10)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(subtotal(item.discountprice * input, 0));
    }, []);

    let currentUserID = JSON.parse(localStorage.getItem("login")) === true && localStorage.getItem("userInfo") !== null
        ? JSON.parse(localStorage.getItem("userInfo")).user
        : localStorage.getItem("guestid")

    let defaultLanguage = localStorage.getItem('translate')

    return (
        <div className="carts-box">
            <div
                className="remove"
                onClick={() => {
                    dispatch(removeproductcart(id));
                    dispatch(subtotal(0, item.discountprice * input));
                    window.location.reload();
                }}
            >
                X
            </div>
            <div className="carts-imgbox">
                <Link to={`/details/${productid}/${title}`}>
                    {" "}
                    <img src={img} alt={title} />
                </Link>
            </div>
            <div className="carts-quant-box">
                <div classNameName="carts-detail-heading">
                    <h1>{defaultLanguage === 'en' ? title : arabictitle}</h1>
                </div>
                <div className="carts-price">
                    <p
                        classNameName="subtotals-price"
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <div>
                            <p className="ccartprice"
                                style={{
                                    color: "#fff",
                                    paddingRight: "0rem",
                                    fontSize: "1.5rem",
                                    textAlign: "center",
                                }}
                            >
                                <span className="price-strike">
                                    {
                                        localStorage.getItem("translate") === "en"
                                            ? localStorage.getItem("currency_code")
                                            : localStorage.getItem("currency_icon")
                                    }
                                    {" "}
                                    {
                                        item.discountprice !== undefined || item.discountprice !== null
                                            ? <span
                                                style={{
                                                    textDecoration: "line-through",
                                                    padding: "0.5rem",
                                                    paddingLeft: "0",
                                                    display:
                                                        item.discountprice[0] === item.price[0]
                                                            ? "none"
                                                            : "inline",
                                                }}
                                            >
                                                {item.price[0]}{" "}
                                            </span>

                                            : <span></span>
                                    }
                                </span>
                                {
                                    localStorage.getItem("translate") === "en"
                                        ? localStorage.getItem("currency_code")
                                        : localStorage.getItem("currency_icon")
                                }
                                {" "}
                                {
                                    item.discountprice !== undefined || item.discountprice !== null
                                        ? item.discountprice[0]
                                        : ""
                                }
                            </p>
                            <div className="ccartqty" style={{ color: "#fff", fontWeight: "500" }}>
                                {
                                    input > 1 &&
                                    <span
                                        classNameName="increment"
                                        onClick={() => {
                                            setinput(input - 1);
                                            dispatch(
                                                cartdec(
                                                    id,
                                                    input - 1,
                                                    currentUserID
                                                )
                                            );
                                            dispatch(
                                                subtotal(
                                                    item.discountprice * input,
                                                    item.discountprice * (input + 1)
                                                )
                                            );
                                        }}
                                    >
                                        <img src={icon_m} alt="minus" className="qty-switch" />
                                    </span>
                                }


                                <p className="qty-text">{defaultLanguage === 'en' ? 'QTY' : 'الكمية'} : {input}</p>

                                {
                                    input < 10 &&
                                    <span
                                        classNameName="decrement"
                                        onClick={() => {
                                            setinput(input + 1);
                                            dispatch(
                                                cartdec(
                                                    id,
                                                    input + 1,
                                                    currentUserID
                                                )
                                            );
                                            dispatch(
                                                subtotal(
                                                    item.discountprice * input,
                                                    item.discountprice * (input - 1)
                                                )
                                            );
                                        }}
                                    >
                                        <img src={icon_p} alt="plus" className="qty-switch" />
                                    </span>
                                }
                            </div>
                            <p className="ccartsize" style={{ color: "#fff", fontWeight: "500" }}>
                                {defaultLanguage === 'en' ? 'Size' : 'المقاس'} : {item.weight} ml
                            </p>
                        </div>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Cartcard;