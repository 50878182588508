import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./paymentscreen.css";
import { cartlist } from "../../actions/cartactions";

import { clearCart } from '../../helpers';

export const PayTabFailed = ({ location }) => {
    const { failedResult } = location.state || {};

    console.log({ failedResult })

    const redirectToCart = async () => {
        // const clearCartRes = await clearCart()
        // console.log(clearCartRes)
        window.location.href = '/cart'
    }

    const dispatch = useDispatch();
    const cartList = useSelector((state) => state.addCart);

    const { cart, loading } = cartList;

    useEffect(() => {
        console.log(cart)

        if (JSON.parse(localStorage.getItem("login")) === true && localStorage.getItem("userInfo") !== null) {
            dispatch(cartlist(JSON.parse(localStorage.getItem("userInfo")).user));
        } else if (JSON.parse(localStorage.getItem("login")) !== true) {
            dispatch(cartlist(localStorage.getItem("guestid")));
        }
    }, []);

    return (
        <div className="failedCtr">
            <h1 style={{ color: 'white' }}>
                Payment Failed
            </h1>

            {
                failedResult !== undefined
                    ? <p style={{ color: 'white' }}>{failedResult.description}</p>
                    : ''
            }

            <p className="failedBtn" onClick={() => redirectToCart()}>
                Click here to go to cart and try again
            </p>
        </div>
    );
};