import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { shippingaddressget } from "../../actions/orderaction";
import { Sidebar } from "../../components/sidebar/sidebar";
import "./Address.css";
import { defaultLanguage } from "../../helpers"
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";

export const Address = () => {
    const dispatch = useDispatch();
    const addresses = useSelector((state) => state.shipping);
    const { address } = addresses;

    useEffect(() => {
        dispatch(
            shippingaddressget(JSON.parse(localStorage.getItem("userInfo")).user)
        );
    }, [dispatch]);

    const removeConfirmation = (index) => {
        confirmAlert({
            title: defaultLanguage === "en" ? "Info" : "معلومات",
            message: defaultLanguage === "en"
                ? <>Are you sure to delete this address?</>
                : <>هل أنت متأكد من حذف هذا العنوان؟</>
            ,
            buttons: [
                {
                    label: defaultLanguage === "en"
                        ? "Back"
                        : "خلف"
                    ,
                    onClick: () => { }
                },
                {
                    label: defaultLanguage === "en"
                        ? "Ok"
                        : "نعم"
                    ,
                    onClick: () => {
                        removeAddress(index);
                    }
                }
            ]
        });
    }

    const removeAddress = (index) => {
        let reqBody = {
            userId: JSON.parse(localStorage.getItem("userInfo")).user,
            index: index + 1
        }

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}remove-address`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: reqBody
        };

        axios.request(config)
            .then((response) => {
                if (response.data.msg === 'address removed') {
                    window.alert(response.data.msg);
                    window.location.reload();
                }
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const updateAddress = (index) => {
        window.location.href = `/update-shipping?index=${index + 1}`
    }

    return (
        <div>
            {
                defaultLanguage === "en"
                    ? <h1 className="breadcrumb-css">MY ADDRESS</h1>
                    : <h1 className="breadcrumb-css">عنواني</h1>
            }
            <div className="my-account">
                <div className="sidebar-left">
                    {" "}
                    <Sidebar />
                </div>
                <div className="address-right">
                    {
                        address !== undefined && address.address.length < 4
                            ? <div className="two-buttons">
                                <a
                                    href="/add-shipping"
                                    style={{
                                        background: "transparent",
                                        border: "1px solid #c69736",
                                        color: "#fff",
                                        padding: "1rem",
                                    }}
                                >
                                    {
                                        defaultLanguage === "en"
                                            ? "Add new shipping address"
                                            : "اضف عنوان شحن جديد"
                                    }
                                </a>
                            </div>
                            : ''
                    }
                    <h1 className="heading-address">
                        {
                            defaultLanguage === "en"
                                ? "Saved Addresses"
                                : "العناوين المحفوظة"
                        }
                    </h1>
                    <h4 className="heading-address">
                        ({address.address.length} / 4) Addresses Saved
                    </h4>

                    <div className="address-box">
                        {address !== undefined && JSON.parse(localStorage.getItem("login")) === true &&
                            address.address.map((item, index) => {
                                return (
                                    <div
                                        style={{
                                            border: "1px solid #c69736",
                                            color: "white",
                                            padding: "1.5rem"
                                        }}
                                    >
                                        <div>
                                            <p style={{ cursor: "pointer", textAlign: 'center' }}>
                                                {item.firstname}{" "}{item.lastname}
                                            </p>
                                            <p style={{ cursor: "pointer", textAlign: 'center' }}>
                                                {item.address}{" "}
                                                {item.apartment}
                                                {item.city}
                                                {item.state}{" "}
                                                {item.country}{" "}
                                            </p>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                            <i className="fas fa-trash" style={{ cursor: "pointer" }} onClick={() => removeConfirmation(index)}></i>
                                            <div style={{ margin: '5px' }}></div>
                                            <i className="fas fa-pencil-alt" style={{ cursor: "pointer" }} onClick={() => updateAddress(index)}></i>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
};
