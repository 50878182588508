import React, { Component } from "react";
import queryString from "query-string";
import axios from "axios";
import { orderPost } from "../../../actions/orderaction";
import store from "../../../store";
import { setcoupon } from "../../../actions/cartactions";
import './test.css';
import { withRouter } from 'react-router-dom';

class ResultPage extends Component {
    state = {
        showFailure: false,
        responseData: null,
        loading: true,
    };

    getTotalAmt = () => {
        let shipping = parseFloat(localStorage.getItem('shipping'))
        let total = parseFloat(localStorage.getItem('total'))
        let tax = parseFloat(localStorage.getItem('tax'))

        let amt = localStorage.getItem("country_code_iso3") === "KSA"
            ? total - tax > 200
                ? total
                : shipping + total
            : localStorage.getItem("country_code_iso3") === "UAE"
                ? total - tax > 200
                    ? total
                    : shipping + total
                : localStorage.getItem("country_code_iso3") === "IND"
                    ? total - tax > 4500
                        ? total
                        : shipping + total
                    : total - tax > 200
                        ? total
                        : shipping + total

        console.log({ amt, total, tax, shipping })
        console.log(total - tax)
        return amt
    }

    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        const type = parsed.type

        const resourcePath = parsed.resourcePath;
        let w = type === 'MADA' ? process.env.REACT_APP_HYPERPAY_ENTITY_ID_MADA : process.env.REACT_APP_HYPERPAY_ENTITY_ID_VISA_MASTER;

        axios.post(`${process.env.REACT_APP_API_URL}result`, {
            resourcePath,
            entityid: w,
        }).then((res) => {
            console.log({ type, res })
            this.setState({
                responseData: res.data,
                loading: false,
            });
        }).catch((error) => {
            console.log({ "error": error });
            this.setState({
                responseData: null,
                loading: false,
            });
        })
    }

    checkResult = async () => {
        let shipping = parseFloat(localStorage.getItem('shipping'))
        let total = parseFloat(localStorage.getItem('total'))
        let tax = parseFloat(localStorage.getItem('tax'))

        console.log({ amt: total - tax, total, tax, shipping })

        const successPattern = /^(000\.000\.|000\.100\.1|000\.[36])/;
        const manuallPattern = /^(000\.400\.0[^3]|000\.400\.100)/;

        const failedPatterns = [
            /^(000\.400\.[1][0-9][1-9]|000\.400\.2)/,
            /^(800\.[17]00|800\.800\.[123])/,
            /^(900\.[1234]00|000\.400\.030)/,
            /^(800\.[56]|999\.|600\.1|800\.800\.[84])/,
            /^(100\.39[765])/,
            /^(300\.100\.100)/,
            /^(100\.400\.[0-3]|100\.380\.100|100\.380\.11|100\.380\.4|100\.380\.5)/,
            /^(800\.400\.1)/,
            /^(800\.400\.2|100\.390)/,
            /^(800\.[32])/,
            /^(800\.1[123456]0)/,
            /^(600\.[23]|500\.[12]|800\.121)/,
            /^(100\.[13]50)/,
            /^(100\.250|100\.360)/,
            /^(700\.[1345][05]0)/,
            /^(200\.[123]|100\.[53][07]|800\.900|100\.[69]00\.500)/,
            /^(100\.800)/,
            /^(100\.700|100\.900\.[123467890][00-99])/,
            /^(100\.100|100.2[01])/,
            /^(100\.55)/,
            /^(100\.380\.[23]|100\.380\.101)/,
            /^(000\.100\.2)/
        ]

        console.log({ responseData: this.state.responseData })

        if (this.state.responseData === null) {
            window.location.href = '/paymentfailed'
        } else {
            const match1 = successPattern.test(this.state.responseData.result.code);
            const match2 = manuallPattern.test(this.state.responseData.result.code);

            const matchF = failedPatterns.some(pattern => pattern.test(this.state.responseData.result.code));

            if (match1 || match2) {
                console.log({ line108: this.state.responseData })
                if (JSON.parse(localStorage.getItem("login")) === true) {
                    await store.dispatch(
                        orderPost(
                            JSON.parse(localStorage.getItem("userInfo")).user,
                            this.state.responseData.paymentType,
                            {
                                id: this.state.responseData.id,
                                status: this.state.responseData.paymentType,
                                update_time: Date.now(),
                            },
                            localStorage.getItem("tax"),
                            total - tax > 200 ? 0 : shipping,
                            localStorage.getItem('total'),
                            this.state.responseData.merchantTransactionId,
                            true,
                            this.state.responseData.timestamp
                        )
                    );
                } else {
                    await store.dispatch(
                        orderPost(
                            localStorage.getItem("guestid"),
                            this.state.responseData.paymentType,
                            {
                                id: this.state.responseData.id,
                                status: this.state.responseData.paymentType,
                                update_time: Date.now(),
                            },
                            localStorage.getItem("tax"),
                            total - tax > 200 ? 0 : shipping,
                            localStorage.getItem('total'),
                            this.state.responseData.merchantTransactionId,
                            true,
                            this.state.responseData.timestamp
                        )
                    );
                }

                if (localStorage.getItem("coupon") !== "") {
                    store.dispatch(setcoupon(JSON.parse(localStorage.getItem("coupon"))));
                    localStorage.setItem("coupon", "");
                }

                localStorage.setItem("apply", false);

                window.location.href = process.env.REACT_APP_WEBSITE + 'orders'
            } else if (matchF) {
                const failedResult = this.state.responseData.result
                console.log({ matchF: failedResult })

                this.props.history.push({
                    pathname: '/paymentfailed',
                    state: { failedResult },
                });

                window.location.href = '/paymentfailed'
            } else {
                const failedResult = this.state.responseData.result
                console.log({ 'else': failedResult })

                this.props.history.push({
                    pathname: '/paymentfailed',
                    state: { failedResult },
                });

                window.location.href = '/paymentfailed'
            }
        }
    };

    render() {
        return (
            <div>
                {
                    this.state.loading === false
                        ? this.checkResult()
                        : <div className="failedCtr">
                            <h1>Loading...</h1>
                        </div>
                }
            </div>
        );
    }
}

export default withRouter(ResultPage);
