import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { Translate } from "react-auto-translate";
import { CloseButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";

import { getCurrencySymbol, defaultLanguage, getShippingCharge } from '../../helpers';

// import toast, { Toaster } from 'react-hot-toast';
import {
    orderaddress,
    shippingaddress,
    shippingaddressget,
} from "../../actions/orderaction";
import { Rightinfo } from "../../components/rightinfo/rightinfo";
import { LOGIN_DISPLAY_FLEX } from "../../constant/user";
import { listPrice } from "../../actions/productaction";

import "./checkout.css";
import logo from "../../assets/makkaj-website-logo.png";

export const Checkout = () => {
    const [countryList, setCountryList] = useState("");

    const targetElementRef = useRef(null);

    const [email, setemail] = useState("");
    const [addresss, setaddress] = useState("");
    const [fname, setfname] = useState("");
    const [lname, setlname] = useState("");
    const [apartment, setapartment] = useState("");
    const [phone, setphone] = useState("");
    const [city, setcity] = useState("");
    const [state, setstate] = useState("");
    const [pin, setpin] = useState("");
    const [country, setcountry] = useState("");
    const [cont, setcont] = useState({});

    const [chosenAddress, setChosenAddress] = useState(false)

    const dispatch = useDispatch();

    const history = useHistory();
    const addresses = useSelector((state) => state.shipping);
    const { address } = addresses;

    const getCountryList = async () => {
        const data = await axios.get(`${process.env.REACT_APP_API_URL}countrylist`);
        console.log(data.data)
        setCountryList(data.data)
    }

    useEffect(() => {
        getCountryList();

        if (JSON.parse(localStorage.getItem("login")) === true) {
            dispatch(
                shippingaddressget(JSON.parse(localStorage.getItem("userInfo")).user)
            );
        }

        window.scrollTo({ top: 0, behavior: 'smooth' });

        const total = Number(localStorage.getItem("total")) - Number(localStorage.getItem("tax"))

        if (total < 200) {
            freeShipWarning()
        }
    }, [dispatch]);

    const freeShipWarning = () => {
        confirmAlert({
            title: defaultLanguage === "en" ? "Info" : "معلومات",
            message: defaultLanguage === "en"
                ? <>Add more products to your cart and unlock FREE SHIPPING on purchases above {getCurrencySymbol()} 200.</>
                : <>أضف المزيد من المنتجات إلى سلة التسوق الخاصة بك واحصل على شحن مجاني على المشتريات أعلاه {getCurrencySymbol()} 200.</>
            ,
            buttons: [
                {
                    label: defaultLanguage === "en"
                        ? "Ok"
                        : "نعم"
                    ,
                    onClick: () => { }
                }
            ]
        });
    }

    const saveAddress = () => {
        if (email === "") {
            window.alert("Email is empty");
        } else if (fname === "") {
            window.alert("First name is empty");
        } else if (lname === "") {
            window.alert("Last name is empty");
        } else if (addresss === "") {
            window.alert("Address is empty");
        } else if (apartment === "") {
            window.alert("Apartments is empty");
        } else if (city === "") {
            window.alert("City is empty");
        } else if (country === "") {
            window.alert("country is empty");
        } else if (state === "") {
            window.alert("state is empty");
        } else if (phone === "") {
            window.alert("phone is empty");
        } else {
            if (JSON.parse(localStorage.getItem("login")) === true) {
                dispatch(
                    shippingaddress(
                        fname,
                        lname,
                        addresss,
                        apartment,
                        city,
                        country,
                        state,
                        pin,
                        phone,
                        email,
                        JSON.parse(localStorage.getItem("userInfo")).user
                    )
                );
            }
        }
    }

    const applyAddress = (data) => {
        console.log(data)

        if (localStorage.getItem('country_name') !== data.country) {
            confirmAlert({
                title: "Info",
                // message: 'Kingdom of Saudi Arabia to Kingdom of Saudi Arabia only.United Arab Emirates',
                message: <>
                    We are shipping from {localStorage.getItem('country_name')} to {localStorage.getItem('country_name')} only.
                    <br></br>
                    Please select appropriate country from top left corner if you want to ship to {data.country}
                </>,
                buttons: [
                    {
                        label: "Ok",
                        onClick: () => {
                            setemail("");
                            setaddress("");
                            setfname("");
                            setlname("");
                            setapartment("");
                            setphone("");
                            setcity("");
                            setstate("");
                            setpin("");
                            setcountry("");
                            setChosenAddress(false)
                        }
                    }
                ]
            });
        } else {
            setemail(data.email);
            setaddress(data.address);
            setfname(data.firstname);
            setlname(data.lastname);
            setapartment(data.appartment);
            setphone(data.phone);
            setcity(data.city);
            setstate(data.state);
            setpin(data.pin);
            setcountry(data.country);
        }
    }

    const changeHandler = (e) => {
        setemail(e.target.value);
    };
    const changeHandler1 = (e) => {
        setaddress(e.target.value);
    };
    const changeHandler2 = (e) => {
        setfname(e.target.value);
    };
    const changeHandler3 = (e) => {
        setlname(e.target.value);
    };
    const changeHandler4 = (e) => {
        setapartment(e.target.value);
    };
    const changeHandler5 = (e) => {
        setphone(e.target.value);
    };
    const changeHandler6 = (e) => {
        setcity(e.target.value);
    };
    const changeHandler7 = (e) => {
        setstate(e.target.value);
    };
    const changeHandler8 = (e) => {
        setpin(e.target.value);
    };
    const changeHandler9 = (e) => {
        if (e.target.value === 'Kingdom of Saudi Arabia' || e.target.value === 'United Arab Emirates') {
            if (localStorage.getItem('country_name') !== e.target.value) {
                confirmAlert({
                    title: "Info",
                    // message: 'Kingdom of Saudi Arabia to Kingdom of Saudi Arabia only.United Arab Emirates',
                    message: <>
                        We are shipping from {localStorage.getItem('country_name')} to {localStorage.getItem('country_name')} only.
                        <br></br>
                        Please select appropriate country from top left corner if you want to ship to {e.target.value}
                    </>,
                    buttons: [
                        {
                            label: "Ok",
                            onClick: () => { console.log(e.target.value) }
                        }
                    ]
                });
            } else {
                console.log(e.target.value)
                setcountry(e.target.value);
            }
        } else {
            confirmAlert({
                title: "Info",
                message: "We are currently only shipping to United Arab Emirates (UAE) and Saudi Arabia (KSA) only",
                buttons: [
                    {
                        label: "Ok",
                        onClick: () => { console.log(e.target.value) }
                    }
                ]
            });
        }
    };

    const submitHandler = (e) => {
        if (email === "") {
            window.alert("Email is empty");
        } else if (fname === "") {
            window.alert("First name is empty");
        } else if (lname === "") {
            window.alert("Last name is empty");
        } else if (addresss === "") {
            window.alert("Address is empty");
        } else if (apartment === "") {
            window.alert("Apartments is empty");
        } else if (city === "") {
            window.alert("City is empty");
        } else if (country === "") {
            window.alert("country is empty");
        } else if (state === "") {
            window.alert("state is empty");
        } else if (phone === "") {
            window.alert("phone is empty");
        } else {
            if (JSON.parse(localStorage.getItem("login")) === true) {
                dispatch(
                    orderaddress(
                        fname,
                        lname,
                        addresss,
                        apartment,
                        city,
                        country,
                        state,
                        pin,
                        phone,
                        email,
                        JSON.parse(localStorage.getItem("userInfo")).user
                    )
                );
            }
            else {
                dispatch(
                    orderaddress(
                        fname,
                        lname,
                        addresss,
                        apartment,
                        city,
                        country,
                        state,
                        pin,
                        phone,
                        email,
                        localStorage.getItem("guestid")
                    )
                );
            }
            history.push("/shipping");
        }
    };

    let defaultLanguage = localStorage.getItem('translate')

    return (
        <>
            <div className="checkout-page">
                <div className="top-info">
                    <h3>
                        {defaultLanguage === 'en' ? 'CHECKOUT' : 'الدفع'}
                    </h3>
                </div>
                <div className="left-info">
                    <div className="top-logo">
                        <img src={logo} alt="logo" />
                    </div>
                    <div className="contact-info">
                        <p>
                            {defaultLanguage === 'en' ? 'Contact information' : 'معلومات الاتصال'}
                        </p>
                        <p className="just-width"></p>
                        {JSON.parse(localStorage.getItem("login")) !== true && (
                            <p onClick={() => {
                                dispatch({
                                    type: LOGIN_DISPLAY_FLEX,
                                })
                            }}>
                                {defaultLanguage === 'en' ? 'Already Have an account? Log in' : 'هل لديك حساب؟ تسجيل الدخول'}
                            </p>
                        )}
                        <input
                            required={true}
                            type="email"
                            placeholder={localStorage.getItem('translate') === 'en' ? "Email" : "البريد الإلكتروني"}
                            style={{ width: "79%" }}
                            value={email}
                            onChange={changeHandler}
                        />
                    </div>
                    <div className="lower-info">
                        <h2 style={{ padding: "0", margin: "0" }}>
                            {defaultLanguage === 'en' ? 'Shipping address' : 'عنوان الشحن'}
                        </h2>
                    </div>
                    <div className="main-checkoutform-div">
                        <form onSubmit={submitHandler} className="checkout-form">
                            <input
                                required={true}
                                value={fname}
                                onChange={changeHandler2}
                                style={{ padding: "1rem" }}
                                type="text"
                                placeholder={localStorage.getItem('translate') === 'en' ? "First Name" : "الاسم الاول"}
                            />
                            <input
                                required={true}
                                value={lname}
                                onChange={changeHandler3}
                                style={{ padding: "1rem" }}
                                type="text"
                                placeholder={localStorage.getItem('translate') === 'en' ? "Last Name" : "الكنية"}
                            />
                            <input
                                required={true}
                                value={addresss}
                                onChange={changeHandler1}
                                style={{ padding: "1rem" }}
                                type="text"
                                placeholder={localStorage.getItem('translate') === 'en' ? "Address" : "تبوك"}
                                maxLength={100}
                            />
                            {
                                addresss.length >= 100
                                    ? <div>
                                        Limit: {addresss.length}/100
                                    </div>
                                    : null
                            }
                            <input
                                required={true}
                                value={apartment}
                                onChange={changeHandler4}
                                style={{ padding: "1rem" }}
                                type="text"
                                placeholder={localStorage.getItem('translate') === 'en' ? "Apartment, suite, etc." : "شقة ، جناح ، إلخ."}
                            />
                            <input
                                className="checkout-form-input"
                                required={true}
                                value={city}
                                onChange={changeHandler6}
                                style={{ padding: "1rem" }}
                                type="text"
                                placeholder={localStorage.getItem('translate') === 'en' ? "City" : "مدينة"}
                            />
                            <div className="country-div">
                                <select
                                    className="select-country"
                                    name="size"
                                    onChange={changeHandler9}
                                    value={country}
                                    style={{ color: country !== "" ? 'white' : '' }}
                                >
                                    <option name="size" value="">
                                        {
                                            defaultLanguage === 'en'
                                                ? 'Choose Country'
                                                : 'اختر البلد'
                                        }
                                    </option>
                                    {
                                        countryList.length > 0
                                            ? countryList.map((C, i) => {
                                                return (
                                                    <option name="size" value={C.name}>
                                                        {C.name}
                                                    </option>
                                                )
                                            })
                                            : null
                                    }
                                </select>
                            </div>
                            <input
                                required={true}
                                className="checkout-form-input"
                                value={state}
                                onChange={changeHandler7}
                                style={{ padding: "1rem" }}
                                type="text"
                                placeholder={localStorage.getItem('translate') === 'en' ? "State" : "حالة"}
                            />
                            <input
                                // required={true}
                                value={pin}
                                onChange={changeHandler8}
                                style={{ padding: "1rem" }}
                                type="text"
                                placeholder={localStorage.getItem('translate') === 'en' ? "Zip code" : "الرمز البريدي"}

                            />
                            <input
                                required={true}
                                value={phone}
                                onChange={changeHandler5}
                                style={{ padding: "1rem" }}
                                type="number"
                                placeholder={localStorage.getItem('translate') === 'en' ? "Mobile number" : "رقم الهاتف المحمول"}
                            />

                            {
                                address.address.length < 4 &&
                                    chosenAddress === false
                                    ? JSON.parse(localStorage.getItem("login")) === true
                                        ? <>
                                            <input
                                                style={{ width: "auto", padding: "1rem" }}
                                                type="checkbox"
                                                id="checkout1"
                                                onChange={() => saveAddress()}
                                            />
                                            <label for="checkout1">
                                                {
                                                    defaultLanguage === 'en'
                                                        ? 'Save this info for future'
                                                        : 'حفظ هذة المعلومات للمستقبل'
                                                }
                                            </label>
                                        </>
                                        : ''
                                    : ''
                            }

                            <br></br>
                            <button required={true} className="continue-btn" type="submit">
                                {defaultLanguage === 'en' ? 'Continue to Shipping' : 'الاستمرار في الشحن'}
                            </button>

                            <a href="/cart">
                                {defaultLanguage === 'en' ? 'Return to cart' : 'العودة الى السلة'}
                            </a>
                        </form>

                        <div className="address-box">
                            {/* {
                                localStorage.getItem('login') !== null || localStorage.getItem('login') !== false
                                    ? <h2 className="saved-address">
                                        {defaultLanguage === 'en' ? 'Choose your saved address' : 'اختر عنوانك المحفوظ'}
                                    </h2>
                                    : null
                            } */}

                            {
                                localStorage.getItem('login') === null || localStorage.getItem('login') === 'false'
                                    ? <h2 className="saved-address">
                                        {defaultLanguage === 'en' ? 'Login to choose your saved addresses' : 'قم بتسجيل الدخول لاختيار عناوينك المحفوظة'}
                                    </h2>
                                    : <h2 className="saved-address">
                                        {defaultLanguage === 'en' ? 'Choose your saved address' : 'اختر عنوانك المحفوظ'}
                                    </h2>
                            }

                            {address !== undefined && JSON.parse(localStorage.getItem("login")) === true &&
                                address.address.map((item, index) => {
                                    return (
                                        <div
                                            style={{
                                                border: "1px solid #c69736",
                                                color: "white",
                                                padding: "1.5rem",
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                        >
                                            <div className="select-dot">
                                                <input
                                                    type="radio"
                                                    id={`add${index}`}
                                                    name="address"
                                                    value={item}
                                                    checked={chosenAddress === item}
                                                    onChange={(e) => {
                                                        setcont(item);
                                                        applyAddress(item)
                                                        setChosenAddress(item)
                                                    }}
                                                    style={{
                                                        marginRight: "2.5rem",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center"
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <p
                                                    style={{ cursor: "pointer" }}
                                                    htmlFor={`add${index}`}
                                                >
                                                    {item.firstname}{" "}{item.lastname}
                                                </p>
                                                <p
                                                    style={{ cursor: "pointer" }}
                                                    htmlFor={`add${index}`}
                                                >
                                                    {item.address}{" "}
                                                    {item.apartment}
                                                    {item.city}
                                                    {item.state}{" "}
                                                    {item.country}{" "}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}

                            {/* <button
                                required={true}
                                className="continue-btn"
                                onClick={() => {
                                    dispatch(
                                        orderaddress(
                                            cont.fname,
                                            cont.lname,
                                            cont.addresss,
                                            cont.apartment,
                                            cont.city,
                                            cont.country,
                                            cont.state,
                                            cont.pin,
                                            cont.phone,
                                            cont.email,
                                            JSON.parse(localStorage.getItem("userInfo")).user
                                        )
                                    );
                                    history.push("/shipping");
                                }}
                            >
                                Continue to Shipping
                            </button> */}
                        </div>
                    </div>
                </div>
                <Rightinfo />
            </div>
        </>
    );
};