import React from 'react'
import { Spinner } from 'react-bootstrap'
import './loader.css'

const Loader = (props) => {
    return (
        // <Spinner
        //     animation='border'
        //     role='status'
        //     style={{
        //         width: '100px',
        //         height: '100px',
        //         margin: 'auto',
        //         display: 'block',
        //     }}
        // >
        //     {console.log('running loader')}
        //     <span className='sr-only'>Loading...</span>
        // </Spinner>
        <div className="loaderCtr" >
            <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <div className='loaderText'>Loading {props.text} ...</div>
        </div>
    )
}

export default Loader;
