import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getwishlist } from "../../actions/wishlistaction";
import { listPrice } from "../../actions/productaction";
import Checkoutbox from "../../components/checkout/checkoutsummary";
import { Coupon } from "../../components/coupons/coupon";
import "./wishlist.css";
import Wishlistcard from "../../components/wishlistcard/wishlistcard";

import { defaultLanguage } from "../../helpers";
import { Sidebar } from "../../components/sidebar/sidebar";

export const Wishlist = () => {
    const dispatch = useDispatch();

    const wishlists = useSelector((state) => state.wishlist);
    const { wishlist } = wishlists;

    const prices = useSelector((state) => state.priceList);
    const { price } = prices;

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("login")) === true) {
            dispatch(getwishlist(JSON.parse(localStorage.getItem("userInfo")).user));
        }
        dispatch(listPrice());
    }, [dispatch]);
    return (
        <div>
            <h1 className="breadcrumb-css">
                {
                    defaultLanguage === "en"
                        ? 'Wishlist'
                        : 'قائمة الرغبات'
                }
            </h1>
            <div className="my-account">
                <div className="sidebar-left">
                    {" "}
                    <Sidebar />
                </div>

                <div className="myorder-right">

                    <div className="wishlist-cardd">
                        {
                            JSON.parse(localStorage.getItem("login")) === true
                                ? wishlist.length > 0
                                    ? wishlist.map((items) => {
                                        return (
                                            <Wishlistcard
                                                id={items._id}
                                                productid={items.productId}
                                                img={items.image}
                                                description={items.description}
                                                title={items.title}
                                                price={price}
                                                item={items}
                                            />
                                        );
                                    })
                                    : <div style={{
                                        width: '100%',
                                        height: '30vh',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <p style={{ color: 'white' }}>Wishlist is Empty</p>
                                    </div>
                                : <p style={{ color: 'white' }}>Please login to access wishlist</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
