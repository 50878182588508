import {
    NEW_PASSWORD_FAIL,
    NEW_PASSWORD_REQUEST,
    NEW_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_REGISTER_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
} from "../constant/user";
import axios from "axios";
export const logins = (email, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST,
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}login`,
            { email, password },
            config
        );

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data,
        });
        localStorage.setItem("userInfo", JSON.stringify(data));
        localStorage.setItem("jwt_access_token", data.token);
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const register =
    (firstName, lastName, phone, email, password, password2) =>
        async (dispatch) => {
            try {
                dispatch({
                    type: USER_REGISTER_REQUEST,
                });

                const config = {
                    headers: {
                        "Content-Type": "application/json",
                    },
                };

                const data = await axios.post(
                    `${process.env.REACT_APP_API_URL}register`,
                    { firstName, lastName, phone, email, password, password2 },
                    config
                );

                dispatch({
                    type: USER_REGISTER_SUCCESS,
                    payload: data,
                });

                dispatch({
                    type: USER_LOGIN_SUCCESS,
                    payload: data,
                });

                // localStorage.setItem("userInfo", JSON.stringify(data));
            } catch (error) {
                dispatch({
                    type: USER_REGISTER_FAIL,
                    payload: error,
                });
            }
        };

export const resetpass = (email) => async (dispatch) => {
    try {
        dispatch({
            type: RESET_PASSWORD_REQUEST,
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}resetpass`,
            { email },
            config
        );

        dispatch({
            type: RESET_PASSWORD_SUCCESS,
            payload: data,
        });



        // localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};



export const passchange = (pass, userId, token) => async (dispatch) => {

    try {
        dispatch({
            type: NEW_PASSWORD_REQUEST,
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}passwordreset/${userId}/${token}`,
            { pass },
            config
        );

        dispatch({
            type: NEW_PASSWORD_SUCCESS,
            payload: true,
        });



        // localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: NEW_PASSWORD_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};
