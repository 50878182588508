import React from "react";
import { useLayoutEffect, useEffect } from "react";
import { Translate } from "react-auto-translate";
import './Contact.css'
import logo from "../../assets/main-logo.png";

import shopData from "./contact.json"
import corpAddresses from "./corp_office_factory.json"

const CorpAddresses = (props) => {
    let defaultLanguage = localStorage.getItem('translate')

    return (
        <div className="corp-contact" style={{ textTransform: 'upperCase' }}>
            <img src={"./" + props.shopImg} alt="" />
            {
                defaultLanguage === 'en'
                    ? <>
                        <p className="contact-p1">{props.title}</p>
                        <p className="contact-p1">{props.shopLocationEN}</p>
                        <p className="contact-p2">{props.shopAddressEN}</p>
                        <p className="contact-p3">
                            {
                                props.shopContactTF === undefined || props.shopContactTF === ""
                                    ? null
                                    : <>Tel: {props.shopContactTF} (Toll-Free) <br /></>
                            }
                            Contact: {props.shopContact}<br />
                            Email: <a href="mailto:info@makkaj.com">{props.shopEmail}</a><br />
                            Web: <a href="http://www.makkajperfumes.com/" target="_blank">{props.shopWebsite}</a> <br />
                        </p>
                    </>
                    : <>
                        <p className="contact-p1">{props.title_ar}</p>
                        <p className="contact-p1">{props.shopLocationAR}</p>
                        <p className="contact-p2">{props.shopAddressAR}</p>
                        <p className="contact-p3">
                            {
                                props.shopContactTF === undefined || props.shopContactTF === ""
                                    ? null
                                    : <>تلفون: {props.shopContactTF} (الرقم مجاني) <br /></>
                            }
                            اتصال: {props.shopContact}<br />
                            ايميل: <a href="mailto:info@makkaj.com">{props.shopEmail}</a> <br />
                            ويب: <a href="http://www.makkajperfumes.com/" target="_blank">{props.shopWebsite}</a> <br />
                        </p>
                    </>
            }
        </div>
    )
}

const ShopAddress = (props) => {
    let defaultLanguage = localStorage.getItem('translate')

    return (
        <div className="contact-detail" style={{ textTransform: 'upperCase' }}>
            <img src={"./" + props.shopImg} alt="" />
            {
                defaultLanguage === 'en'
                    ? <>
                        <p className="contact-p1">{props.shopLocationEN}</p>
                        <p className="contact-p2">{props.shopAddressEN}</p>
                        <p className="contact-p3">
                            {
                                props.shopContactTF === undefined || props.shopContactTF === ""
                                    ? null
                                    : <>Tel: {props.shopContactTF} (Toll-Free) <br /></>
                            }
                            Contact: {props.shopContact}<br />
                            Email: <a href="mailto:info@makkaj.com">{props.shopEmail}</a><br />
                            Web: <a href="http://www.makkajperfumes.com/" target="_blank">{props.shopWebsite}</a> <br />
                        </p>
                    </>
                    : <>
                        <p className="contact-p1">{props.shopLocationAR}</p>
                        <p className="contact-p2">{props.shopAddressAR}</p>
                        <p className="contact-p3">
                            {
                                props.shopContactTF === undefined || props.shopContactTF === ""
                                    ? null
                                    : <>تلفون: {props.shopContactTF} (الرقم مجاني) <br /></>
                            }
                            اتصال: {props.shopContact}<br />
                            ايميل: <a href="mailto:info@makkaj.com">{props.shopEmail}</a> <br />
                            ويب: <a href="http://www.makkajperfumes.com/" target="_blank">{props.shopWebsite}</a> <br />
                        </p>
                    </>
            }
        </div>
    )
}

export const Contact = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    useEffect(() => {
        if (window.wpwl !== undefined && window.wpwl.unload !== undefined) {
            window.wpwl.unload();
        }
    }, [])

    let defaultLanguage = localStorage.getItem('translate')

    return (
        <div className="contact-back">
            <div className="shipping-top-info" style={{ backgroundColor: "black" }}>
                {
                    defaultLanguage === 'en'
                        ? <h3>CONTACT</h3>
                        : <h3>الاتصال</h3>
                }
            </div>
            <div className="contact-cont">
                <div>
                    <div className="aboutus-logo contact-logo" style={{ padding: "0px" }}>
                        <hr />
                        <img src={logo} alt="logo" />
                        <hr />
                    </div>
                    <h1 className="contact-head">
                        {
                            defaultLanguage === 'en'
                                ? 'Contact us'
                                : 'اتصل بنا'
                        }
                    </h1>
                    <p className="contact-para">
                        {
                            defaultLanguage === 'en'
                                ? 'We may have already answered your question in the FAQ page.If not please contact us and we will get back to you as soon as possible'
                                : 'ربما قمنا بالفعل بالإجابة على سؤالك في صفحة الأسئلة الشائعة. إذا لم يكن الأمر كذلك، فيرجى الاتصال بنا وسنقوم بالرد عليك في أقرب وقت ممكن'
                        }
                    </p>
                    <div className="aboutus-logo contact-logo" style={{ padding: "0px" }}>
                        <hr />
                        <p>
                            {
                                defaultLanguage === 'en'
                                    ? "STORES"
                                    : "متاجر"
                            }
                        </p>
                        <hr />
                    </div>

                    <div className="corp-contact-details-cont">
                        {
                            corpAddresses.map((S) => {
                                return (
                                    CorpAddresses(S)
                                )
                            })
                        }
                    </div>

                    <div className="contact-details-cont">
                        {
                            shopData.map((S) => {
                                return (
                                    ShopAddress(S)
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
