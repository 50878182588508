/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { orderlist } from "../../actions/orderaction";
import { Writereview } from "../writereview/writereview";
import "./Orderconfirmation.css";
import getSymbolFromCurrency from "currency-symbol-map";
import axios from "axios";
import { Translate } from 'react-auto-translate';
import { listPrice } from '../../actions/productaction';
import { getCurrencySymbol, defaultLanguage, formatDateTime } from '../../helpers';

import QrCode from "./QrCode"

export const Orderconfirmation = (props) => {
    const dispatch = useDispatch();
    const orderslist = useSelector((state) => state.orderlist);

    const [myLocation, setMyLocation] = useState("UAE");

    const prices = useSelector(state => state.priceList);
    const { price } = prices;

    const [shippingCosts, setShippingCosts] = useState()

    const { orders } = orderslist;

    const [invoiceUrl, setInvoiceUrl] = useState('')
    const [refresh, set_refreh] = useState(0)

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("login")) === true) {
            dispatch(orderlist(JSON.parse(localStorage.getItem("userInfo")).user));
            axios
                .post(`${process.env.REACT_APP_API_URL}clearcart`, {
                    userId: JSON.parse(localStorage.getItem("userInfo")).user,
                })
                .then((res) => {
                    console.log(res)
                });
        } else {
            dispatch(orderlist(localStorage.getItem("guestid")));
            axios
                .post(`${process.env.REACT_APP_API_URL}clearcart`, {
                    userId: localStorage.getItem("guestid"),
                })
                .then((res) => {
                    console.log(res)
                });
        }
    }, [dispatch]);

    useEffect(() => {
        setMyLocation(localStorage.getItem('country_code_iso3'))
    })

    useEffect(async () => {
        setShippingCosts(localStorage.getItem('shipping'))
        generateInvoices()
    }, [orders]);

    const generateInvoices = async () => {
        console.log(orders)
        let req_data = {
            orders,
            ship_location: myLocation,
            "currency_icon": localStorage.getItem("translate") === "en"
                ? localStorage.getItem("currency_code")
                : localStorage.getItem("currency_icon")
        }

        if (orders !== null) {
            let invoice_pdf = await axios.post(`${process.env.REACT_APP_API_URL}generate-invoice`, req_data)

            console.log({ req_data })
            console.log({ invoice_pdf })

            setInvoiceUrl(invoice_pdf.data.data.invoice_url)
        }
    }

    // useEffect(() => {
    //     const date = new Date();
    //     const config = {
    //         mode: 'no-cors',
    //         credentials: 'same-origin',
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'Content-Type': 'application/json',
    //             'Cache-Control': 'no-cache',
    //             'Pragma': 'no-cache'
    //         },
    //     };
    //     if (orders) {

    //         axios
    //             .post("https://track.smsaexpress.com/SecomRestWebApi/api/addship", {
    //                 "passkey": "ApT@4725",
    //                 "refno": date.getTime(),
    //                 "cPOBox": orders?.shippingAddress?.pin,
    //                 "cName": orders?.shippingAddress?.firstname + " " + orders?.shippingAddress?.lastname,
    //                 "cntry": orders?.shippingAddress?.country,
    //                 "cCity": orders?.shippingAddress?.city,
    //                 "cZip": orders?.shippingAddress?.pin,
    //                 "cMobile": orders?.shippingAddress?.phone,
    //                 "cTel1": orders?.shippingAddress?.phone,
    //                 "cTel2": orders?.shippingAddress?.phone,
    //                 "PCs": orders?.orderItems?.length,
    //                 "cAddr1": orders.shippingAddress.address + " " + orders.shippingAddress.appartment + " " + orders.shippingAddress.city + " " + orders.shippingAddress.state + " " + orders.shippingAddress.country,
    //                 "cAddr2": orders.shippingAddress.address + " " + orders.shippingAddress.appartment + " " + orders.shippingAddress.city + " " + orders.shippingAddress.state + " " + orders.shippingAddress.country,
    //                 "shipType": "DLV",
    //                 "idNo": date.getTime(),
    //                 "cEmail": orders?.shippingAddress?.email,
    //                 "carrValue": orders?.totalPrice,
    //                 "carrCurr": orders?.orderItems[0]?.pricecon,
    //                 "codAmt": orders?.totalPrice,
    //                 "weight": orders?.orderItems[0]?.weight,
    //                 "itemDesc": orders?.orderItems[0]?.description,
    //                 "custVal": orders?.totalPrice,
    //                 "custCurr": orders?.totalPrice,
    //                 "insrAmt": orders?.totalPrice,
    //                 "insrCurr": orders?.orderItems[0]?.pricecon,
    //                 "sName": orders?.shippingAddress?.firstname + " " + orders?.shippingAddress?.lastname,
    //                 "sContact": orders?.shippingAddress?.phone,
    //                 "sAddr1": orders.shippingAddress.address + " " + orders.shippingAddress.appartment + " " + orders.shippingAddress.city + " " + orders.shippingAddress.state + " " + orders.shippingAddress.country,
    //                 "sAddr2": orders.shippingAddress.address + " " + orders.shippingAddress.appartment + " " + orders.shippingAddress.city + " " + orders.shippingAddress.state + " " + orders.shippingAddress.country,
    //                 "sCity": orders?.shippingAddress?.city,
    //                 "sPhone": orders?.shippingAddress?.phone,
    //                 "sCntry": orders?.shippingAddress?.country,
    //                 "sentDate": date.getTime(),
    //                 "prefDelvDate": "3",
    //                 "gpsPoints": "123",
    //             }, config)
    //             .then((res) => {

    //             }).catch((err) => {
    //                 console.log(err)
    //             });
    //     }
    // }, [orders])

    // const formatDateTime = (date) => {
    //     let dts = new Date(date)

    //     let day = dts.getDate().toString().length > 1 ? dts.getDate() : "0" + dts.getDate()
    //     let hour = dts.getHours().toString().length > 1 ? dts.getHours() : "0" + dts.getHours()
    //     let minute = dts.getMinutes().toString().length > 1 ? dts.getMinutes() : "0" + dts.getMinutes()
    //     let second = dts.getSeconds().toString().length > 1 ? dts.getSeconds() : "0" + dts.getSeconds()
    //     let month = (dts.getMonth() + 1).toString().length > 1 ? dts.getMonth() + 1 : "0" + (dts.getMonth() + 1)
    //     let year = dts.getFullYear()
    //     return day + "-" + month + "-" + year + " " + hour + ":" + minute + ":" + second
    // }


    return orders != null ? (
        <div
            style={{
                width: "100%",
                height: "auto",
                borderBottom: "2px solid #c69736",
                paddingBottom: "5rem",
            }}
        >
            <div
                style={{
                    width: "100%",
                    height: "auto",
                    borderTop: "2px solid #fff",
                    borderBottom: "2px solid #c69736",
                }}
            >
                <h3
                    style={{
                        fontWeight: "400",
                        color: "#c69736",
                        margin: "0.4rem",
                        paddingLeft: "1rem",
                    }}
                >
                    {defaultLanguage === 'en' ? 'ORDER CONFIRMATION' : 'لتاكيد الطلب'}
                </h3>
            </div>

            <div className="order-top-section1">
                <div className="order-top-logo">
                    <img src="./images/logo.png" alt="logo" />
                </div>
                <h1>
                    {defaultLanguage === 'en' ? 'YOUR ORDER IS ON THE WAY' : 'الطلب في الطريق'}
                </h1>
                {/* <a href="/myorders" className="orderbtn">
                    {defaultLanguage === 'en' ? 'MY ORDER' : 'الطلب'}
                </a> */}
            </div>

            <div className="seller_address" style={{ marginBottom: '10px' }}>
                <div>
                    <p>
                        {
                            myLocation === "KSA"
                                ? <p>
                                    {
                                        defaultLanguage === 'en'
                                            ? 'Sold By: AL MAJEED MODERN INDUSTRY FOR PERFUME MFG. & OUDH PROCESSING'
                                            : 'البائع: شركة المجيد الحديثه لصناعة العطور ومعالجة والعود.'
                                    }
                                </p>
                                : myLocation === "UAE"
                                    ? <p>
                                        {
                                            defaultLanguage === 'en'
                                                ? 'Sold by: AMAYRA PERFUMES TRADING'
                                                : 'تم بيعها: أمايرا لتجارة العطور'
                                        }
                                    </p>
                                    : <p>
                                        {
                                            defaultLanguage === 'en'
                                                ? 'Sold by: AMAYRA PERFUMES TRADING'
                                                : 'تم بيعها: أمايرا لتجارة العطور'
                                        }
                                    </p>
                        }
                    </p>

                    <p>
                        {
                            myLocation === "KSA"
                                ? <p>
                                    {
                                        defaultLanguage === 'en'
                                            ? 'Ship From: AL MAJEED MODERN INDUSTRY FOR PERFUME MFG. & OUDH PROCESSING, AL UMRA INDUSTRIAL AREA, MAKKAH AL MUKARRAMAH, KINGDOM OF SAUDI ARABIA, TEL: +966 12 5203333 VAT # 300260999700003'
                                            : 'شركة المجيد للصناعة الحديثه لصناعه العطور ومعالجة العود , منطقة العمرة الصناعية مكة المكرمة المملكه العربية السعودية تلفون 00966125203333 القيمة المضافة 300260999700003'
                                    }
                                </p>
                                : myLocation === "UAE"
                                    ? <p>
                                        {
                                            defaultLanguage === 'en'
                                                ? 'AMAYRA PERFUMES TRADING OFFICE # 3, AL QUOZ INDUSTRIAL AREA, NO. 1, NEAR TO ARAMEX OFFICE, DUBAI, UAE. TEL: +9714 3383881, VAT # 100060275300003'
                                                : '"أمايرا لتجارة العطور  مكتب# ٣، القوز المنطقة الصناعية رقم ١،قريب من مكتب ارامكس دبي ، الإمارات العربية المتحدة تلفون: ٩٧١٤٣٣٨٣٨٨١+ ضريبة القيمه المضافة ١٠٠٠٦٠٢٧٥٣٠٠٠٠٣'
                                        }
                                    </p>
                                    : <p>
                                        {
                                            defaultLanguage === 'en'
                                                ? 'AMAYRA PERFUMES TRADING OFFICE # 3, AL QUOZ INDUSTRIAL AREA, NO. 1, NEAR TO ARAMEX OFFICE, DUBAI, UAE. TEL: +9714 3383881, VAT # 100060275300003'
                                                : '"أمايرا لتجارة العطور  مكتب# ٣، القوز المنطقة الصناعية رقم ١،قريب من مكتب ارامكس دبي ، الإمارات العربية المتحدة تلفون: ٩٧١٤٣٣٨٣٨٨١+ ضريبة القيمه المضافة ١٠٠٠٦٠٢٧٥٣٠٠٠٠٣'
                                        }
                                    </p>
                        }
                    </p>
                </div>

                {
                    orders !== null && invoiceUrl !== ''
                        ? <QrCode invoice={invoiceUrl} />
                        : null
                }
            </div>

            <div className="order-middle-section-new p-0">
                <div className="" style={{ backgroundColor: "#1f1f1f" }}>
                    <h4 style={{ color: "#c69736" }}>
                        {
                            defaultLanguage === 'en'
                                ? 'ORDER SUMMARY:'
                                : 'مجمل الطلب'
                        }
                    </h4>
                    <table className="min-w-full divide-y divide-gray-300" style={{ color: "#ffffff" }}>
                        <tbody className="divide-y divide-gray-200">
                            <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    {
                                        defaultLanguage === 'en'
                                            ? 'Order ID: '
                                            : 'رقم الهوية الخاص بالطلب: '
                                    }
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{orders._id}</td>
                            </tr>

                            <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    {
                                        defaultLanguage === 'en'
                                            ? 'Order Date: '
                                            : 'تاريخ الطلب: '
                                    }
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {
                                        formatDateTime(orders.createdAt)
                                    }
                                </td>
                            </tr>

                            <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    {
                                        defaultLanguage === 'en'
                                            ? 'Order Total: '
                                            : 'مجمع الطلب: '
                                    }
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {
                                        localStorage.getItem("translate") === "en"
                                            ? localStorage.getItem("currency_code")
                                            : localStorage.getItem("currency_icon")
                                    }
                                    {" "}
                                    {
                                        orders.totalPrice + parseFloat(
                                            localStorage.getItem("country_code_iso3") === "KSA"
                                                ? (orders.totalPrice - orders.taxPrice) > 200
                                                    ? 0
                                                    : shippingCosts
                                                : localStorage.getItem("country_code_iso3") === "UAE"
                                                    ? (orders.totalPrice - orders.taxPrice) > 200
                                                        ? 0
                                                        : shippingCosts
                                                    : localStorage.getItem("country_code_iso3") === "IND"
                                                        ? (orders.totalPrice - orders.taxPrice) > 4500
                                                            ? 0
                                                            : shippingCosts
                                                        : (orders.totalPrice - orders.taxPrice) > 200
                                                            ? 0
                                                            : shippingCosts
                                        )
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div>
                    <h4 style={{ color: "#c69736" }}>
                        {
                            defaultLanguage === 'en'
                                ? 'Shipping Address: '
                                : 'عنوان الشحن: '
                        }
                    </h4>
                    <table className="min-w-full divide-y divide-gray-300" style={{ color: "#ffffff" }}>
                        <tbody className="divide-y divide-gray-200">
                            <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{orders?.shippingAddress?.firstname + " " + orders?.shippingAddress?.lastname}</td>
                            </tr>

                            <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    {orders.shippingAddress.address} {orders.shippingAddress.appartment}{" "}
                                    {orders.shippingAddress.city}
                                    {orders.shippingAddress.state} {orders.shippingAddress.country}{" "}
                                </td>
                            </tr>

                            <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{orders?.shippingAddress?.phone}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div>
                    <h4 style={{ color: "#c69736" }}>
                        {
                            defaultLanguage === 'en'
                                ? 'Billing Address: '
                                : 'عنوان الشحن: '
                        }
                    </h4>
                    <table className="min-w-full divide-y divide-gray-300" style={{ color: "#ffffff" }}>
                        <tbody className="divide-y divide-gray-200">
                            <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{orders?.shippingAddress?.firstname + " " + orders?.shippingAddress?.lastname}</td>
                            </tr>

                            <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    {orders.shippingAddress.address} {orders.shippingAddress.appartment}{" "}
                                    {orders.shippingAddress.city}
                                    {orders.shippingAddress.state} {orders.shippingAddress.country}{" "}
                                </td>
                            </tr>

                            <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{orders?.shippingAddress?.phone}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="order-table-container m-0">
                <table className="order-table-new">
                    <tr className="order-table-th">
                        <th>
                            {
                                defaultLanguage === 'en'
                                    ? 'Item'
                                    : 'Item'
                            }
                        </th>
                        <th>
                            {
                                defaultLanguage === 'en'
                                    ? 'Price'
                                    : 'السعر'
                            }
                        </th>
                        <th>
                            {
                                defaultLanguage === 'en'
                                    ? 'Qty'
                                    : 'الكمية'
                            }
                        </th>
                        <th>
                            {
                                defaultLanguage === 'en'
                                    ? 'Status'
                                    : 'الحالة'
                            }
                        </th>
                    </tr>

                    {orders.orderItems.map((item) => {
                        return (
                            <tr className="order-table-tr">
                                <td className="order-table-img">
                                    <Link to={`/details/${item._id}/${item.title}`}>
                                        <img src={item.image} alt="perfume" />
                                    </Link>
                                    <h2 style={{ color: "#fff" }}>
                                        {
                                            defaultLanguage === 'en'
                                                ? item.title
                                                : item.arabictitle
                                        }
                                    </h2>
                                </td>
                                <td className="order-table-price">
                                    <p>
                                        {
                                            localStorage.getItem("translate") === "en"
                                                ? localStorage.getItem("currency_code")
                                                : localStorage.getItem("currency_icon")
                                        }
                                        {/* {item.discountprice[0] * item.count} */}
                                        {" "}
                                        {item.discountprice[0]}
                                    </p>
                                </td>
                                <td className="order-table-qty">
                                    <p>{item.count}</p>
                                </td>
                                <td>
                                    {
                                        defaultLanguage === 'en'
                                            ? <p>Processing</p>
                                            : <p>يعالج</p>
                                    }
                                </td>
                            </tr>
                        );
                    })}

                    <tr className="below-row">
                        <td className="text-right" colSpan="3">
                            <span className="row-heading">
                                {
                                    defaultLanguage === 'en'
                                        ? 'SUBTOTAL: '
                                        : 'مبلغ المجموع: '
                                }
                            </span>
                        </td>
                        <td className="text-right" colSpan="4">
                            <span>
                                {
                                    localStorage.getItem("translate") === "en"
                                        ? localStorage.getItem("currency_code")
                                        : localStorage.getItem("currency_icon")
                                } {" "}
                                {orders.totalPrice - orders.taxPrice}
                            </span>
                        </td>
                    </tr>

                    <tr className="below-row">
                        <td className="text-right" colSpan="3">
                            <span className="row-heading">
                                {
                                    defaultLanguage === 'en'
                                        ? 'VAT: '
                                        : 'الضريبة المقدرة: '
                                }
                            </span>
                        </td>
                        <td className="text-right" colSpan="4">
                            <span>
                                {
                                    localStorage.getItem("translate") === "en"
                                        ? localStorage.getItem("currency_code")
                                        : localStorage.getItem("currency_icon")
                                } {" "}
                                {
                                    orders.taxPrice
                                }
                            </span>
                        </td>
                    </tr>

                    <tr className="below-row">
                        <td className="text-right" colSpan="3">
                            <span className="row-heading">
                                {
                                    defaultLanguage === 'en'
                                        ? 'SHIPPING CHARGE: '
                                        : 'رسوم الشحن: '
                                }
                            </span>
                        </td>

                        <td className="text-right" colSpan="4">
                            <span>
                                {
                                    localStorage.getItem("translate") === "en"
                                        ? localStorage.getItem("currency_code")
                                        : localStorage.getItem("currency_icon")
                                } {" "}
                            </span>
                            <span>
                                {
                                    localStorage.getItem("country_code_iso3") === "KSA"
                                        ? (orders.totalPrice - orders.taxPrice) > 200
                                            ? '0 (Free)'
                                            : shippingCosts
                                        : localStorage.getItem("country_code_iso3") === "UAE"
                                            ? (orders.totalPrice - orders.taxPrice) > 200
                                                ? '0 (Free)'
                                                : shippingCosts
                                            : localStorage.getItem("country_code_iso3") === "IND"
                                                ? (orders.totalPrice - orders.taxPrice) > 4500
                                                    ? '0 (Free)'
                                                    : shippingCosts
                                                : (orders.totalPrice - orders.taxPrice) > 200
                                                    ? '0 (Free)'
                                                    : shippingCosts
                                }
                            </span>
                        </td>
                    </tr>

                    <tr className="below-row">
                        <td className="text-right" colSpan="3">
                            {
                                defaultLanguage === 'en'
                                    ? <span className="row-heading"> <span style={{ fontSize: '13px', color: 'red', verticalAlign: 'super' }}>*</span>TOTAL AMOUNT: </span>
                                    : <span className="row-heading"> <span style={{ fontSize: '13px', color: 'red', verticalAlign: 'super' }}>*</span>المبلغ الاجمالي</span>
                            }
                        </td>
                        <td className="text-right" colSpan="4">
                            <span>
                                {
                                    localStorage.getItem("translate") === "en"
                                        ? localStorage.getItem("currency_code")
                                        : localStorage.getItem("currency_icon")
                                } {" "}
                                {
                                    orders.totalPrice + parseFloat(
                                        localStorage.getItem("country_code_iso3") === "KSA"
                                            ? (orders.totalPrice - orders.taxPrice) > 200
                                                ? 0
                                                : shippingCosts
                                            : localStorage.getItem("country_code_iso3") === "UAE"
                                                ? (orders.totalPrice - orders.taxPrice) > 200
                                                    ? 0
                                                    : shippingCosts
                                                : localStorage.getItem("country_code_iso3") === "IND"
                                                    ? (orders.totalPrice - orders.taxPrice) > 4500
                                                        ? 0
                                                        : shippingCosts
                                                    : (orders.totalPrice - orders.taxPrice) > 200
                                                        ? 0
                                                        : shippingCosts
                                    )
                                }
                            </span>
                        </td>
                    </tr>

                    {/*  */}
                </table>
            </div>

            <div style={{
                textAlign: 'center',
                fontSize: '18px',
                border: '0px',
                paddingTop: '10px',
                color: '#ffffff'
            }}>
                {
                    localStorage.getItem("country_code_iso3") === "KSA"
                        ? defaultLanguage === 'en'
                            ? <><span style={{ fontSize: '13px', color: 'red', verticalAlign: 'super' }}>*</span>An additional fee of 15 {getCurrencySymbol()} for the order amount of up to SAR 1000 , {getCurrencySymbol()} 20 up to SAR 3750, and 1% of order amount above {getCurrencySymbol()} 3750 will be applied for payments made using a card upon the time of delivery.</>
                            : <><span style={{ fontSize: '13px', color: 'red', verticalAlign: 'super' }}>*</span>سيتم تطبيق رسوم إضافية قدرها 15 ريال سعودي على مبلغ الطلب حتى 1000 ريال سعودي، و20 ريال سعودي حتى 3750 ريال سعودي، و1% من مبلغ الطلب الذي يزيد عن 3750 ريال سعودي على عمليات الدفع التي تتم باستخدام البطاقة في وقت التسليم.</>
                        : localStorage.getItem("country_code_iso3") === "UAE"
                            ? defaultLanguage === 'en'
                                ? <><span style={{ fontSize: '13px', color: 'red', verticalAlign: 'super' }}>*</span>An additional fee of 1% of the total bill will be applied for payments made using a card upon the time of delivery.</>
                                : <><span style={{ fontSize: '13px', color: 'red', verticalAlign: 'super' }}>*</span>سيتم تطبيق رسوم إضافية بنسبة 1% من إجمالي الفاتورة على الدفعات التي تتم باستخدام البطاقة عند وقت التسليم.</>
                            : <></>
                }
            </div>

            {/* <div className="order-table-container m-0">
                <table className="order-table-new">
                    <tr className="order-table-th">
                        <th> ITEM</th>
                        <th> PRICE</th>
                        <th> QTY</th>
                        <th> STATUS</th>
                    </tr>
                </table>
            </div> */}

            {/* <Writereview/> */}
        </div>
    ) : (
        <div></div>
    );
};