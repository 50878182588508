import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from 'react-router-dom';
import { fetchSingleOrderDetails } from "../../actions/orderaction";
import "./myorders.css";

import QrCode from "./QrCode"

import logo from "../../assets/makkaj-website-logo.png";

import { getCurrencySymbol, defaultLanguage, formatDateTime, getShippingCharge } from "../../helpers";

export const OrderDetails = () => {
    const { ordId } = useParams();
    const dispatch = useDispatch();
    const singleOrderDetails = useSelector((state) => state.singleOrderDetails);

    const [myLocation, setMyLocation] = useState("UAE");
    const [shippingCosts, setShippingCosts] = useState()
    const [finalShippingCosts, setFinalShippingCosts] = useState()

    useEffect(() => {
        calculateShipping()
        dispatch(fetchSingleOrderDetails(ordId));
        console.log({ ordId, singleOrderDetails });
    }, [dispatch, ordId]);

    const calculateShipping = async () => {
        const shippingCharge = await getShippingCharge(0)
        console.log('shipping', shippingCharge)
        setShippingCosts(shippingCharge)
    }

    useEffect(() => {
        const country = localStorage.getItem("country_code_iso3")

        setMyLocation(country)

        if (country === "KSA") {
            if (singleOrderDetails.totalPrice - singleOrderDetails.taxPrice > 200) {
                setFinalShippingCosts(0)
            } else {
                setFinalShippingCosts(shippingCosts)
            }
        } else if (country === "UAE") {
            if (singleOrderDetails.totalPrice - singleOrderDetails.taxPrice > 200) {
                setFinalShippingCosts(0)
            } else {
                setFinalShippingCosts(shippingCosts)
            }
        } else {
            if (singleOrderDetails.totalPrice - singleOrderDetails.taxPrice > 200) {
                setFinalShippingCosts(0)
            } else {
                setFinalShippingCosts(shippingCosts)
            }
        }
    }, [])

    return (
        <div>
            <h2 className="breadcrumb-css">
                {
                    defaultLanguage === "en"
                        ? 'ORDER DETAILS: ' + ordId
                        : 'تفاصيل الطلب: ' + ordId
                }
            </h2>
            <div className="my-account">
                {
                    singleOrderDetails === null || singleOrderDetails === undefined || Object.keys(singleOrderDetails).length === 0 ? (
                        <p className="breadcrumb-css" style={{ color: "white" }}>order details not found</p>
                    ) : (
                        <div
                            style={{
                                width: "100%",
                                height: "auto",
                                borderBottom: "2px solid #c69736",
                                paddingBottom: "5rem",
                            }}
                        >
                            <div className="order-top-section1">
                                <div className="order-top-logo">
                                    <img src={logo} alt="logo" />
                                </div>
                            </div>

                            <div className="seller_address" style={{ marginBottom: '10px' }}>
                                <div>
                                    <p>
                                        {
                                            myLocation === "KSA"
                                                ? <p>
                                                    {
                                                        defaultLanguage === 'en'
                                                            ? 'Sold By: AL MAJEED MODERN INDUSTRY FOR PERFUME MFG. & OUDH PROCESSING'
                                                            : 'البائع: شركة المجيد الحديثه لصناعة العطور ومعالجة والعود.'
                                                    }
                                                </p>
                                                : myLocation === "UAE"
                                                    ? <p>
                                                        {
                                                            defaultLanguage === 'en'
                                                                ? 'Sold by: AMAYRA PERFUMES TRADING'
                                                                : 'تم بيعها: أمايرا لتجارة العطور'
                                                        }
                                                    </p>
                                                    : <p>
                                                        {
                                                            defaultLanguage === 'en'
                                                                ? 'Sold by: AMAYRA PERFUMES TRADING'
                                                                : 'تم بيعها: أمايرا لتجارة العطور'
                                                        }
                                                    </p>
                                        }
                                    </p>

                                    <p>
                                        {
                                            myLocation === "KSA"
                                                ? <p>
                                                    {
                                                        defaultLanguage === 'en'
                                                            ? 'Ship From: AL MAJEED MODERN INDUSTRY FOR PERFUME MFG. & OUDH PROCESSING, AL UMRA INDUSTRIAL AREA, MAKKAH AL MUKARRAMAH, KINGDOM OF SAUDI ARABIA, TEL: +966 12 5203333 VAT # 300260999700003'
                                                            : 'شركة المجيد للصناعة الحديثه لصناعه العطور ومعالجة العود , منطقة العمرة الصناعية مكة المكرمة المملكه العربية السعودية تلفون 00966125203333 القيمة المضافة 300260999700003'
                                                    }
                                                </p>
                                                : myLocation === "UAE"
                                                    ? <p>
                                                        {
                                                            defaultLanguage === 'en'
                                                                ? 'AMAYRA PERFUMES TRADING OFFICE # 3, AL QUOZ INDUSTRIAL AREA, NO. 1, NEAR TO ARAMEX OFFICE, DUBAI, UAE. TEL: +9714 3383881, VAT # 100060275300003'
                                                                : '"أمايرا لتجارة العطور  مكتب# ٣، القوز المنطقة الصناعية رقم ١،قريب من مكتب ارامكس دبي ، الإمارات العربية المتحدة تلفون: ٩٧١٤٣٣٨٣٨٨١+ ضريبة القيمه المضافة ١٠٠٠٦٠٢٧٥٣٠٠٠٠٣'
                                                        }
                                                    </p>
                                                    : <p>
                                                        {
                                                            defaultLanguage === 'en'
                                                                ? 'AMAYRA PERFUMES TRADING OFFICE # 3, AL QUOZ INDUSTRIAL AREA, NO. 1, NEAR TO ARAMEX OFFICE, DUBAI, UAE. TEL: +9714 3383881, VAT # 100060275300003'
                                                                : '"أمايرا لتجارة العطور  مكتب# ٣، القوز المنطقة الصناعية رقم ١،قريب من مكتب ارامكس دبي ، الإمارات العربية المتحدة تلفون: ٩٧١٤٣٣٨٣٨٨١+ ضريبة القيمه المضافة ١٠٠٠٦٠٢٧٥٣٠٠٠٠٣'
                                                        }
                                                    </p>
                                        }
                                    </p>
                                </div>

                                {
                                    singleOrderDetails !== null && singleOrderDetails.order_invoice !== ''
                                        ? <QrCode invoice={singleOrderDetails.order_invoice} />
                                        : null
                                }
                            </div>

                            <div className="order-middle-section-new p-0">
                                <div className="" style={{ backgroundColor: "#1f1f1f" }}>
                                    <h4 style={{ color: "#c69736" }}>
                                        {
                                            defaultLanguage === 'en'
                                                ? 'ORDER SUMMARY:'
                                                : 'مجمل الطلب'
                                        }
                                    </h4>
                                    <table className="min-w-full divide-y divide-gray-300" style={{ color: "#ffffff" }}>
                                        <tbody className="divide-y divide-gray-200">
                                            <tr>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                                    {
                                                        defaultLanguage === 'en'
                                                            ? 'Order ID: '
                                                            : 'رقم الهوية الخاص بالطلب: '
                                                    }
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{singleOrderDetails._id}</td>
                                            </tr>

                                            <tr>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                                    {
                                                        defaultLanguage === 'en'
                                                            ? 'Order Date: '
                                                            : 'تاريخ الطلب: '
                                                    }
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {
                                                        formatDateTime(singleOrderDetails.createdAt)
                                                    }
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                                    {
                                                        defaultLanguage === 'en'
                                                            ? 'Order Total: '
                                                            : 'مجمع الطلب: '
                                                    }
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500" style={{ color: "#ffffff" }}>
                                                    {
                                                        localStorage.getItem("translate") === "en"
                                                            ? localStorage.getItem("currency_code")
                                                            : localStorage.getItem("currency_icon")
                                                    }
                                                    {" "}
                                                    {
                                                        singleOrderDetails.totalPrice + parseFloat(
                                                            myLocation === "KSA"
                                                                ? (singleOrderDetails.totalPrice - singleOrderDetails.taxPrice) > 200
                                                                    ? 0
                                                                    : shippingCosts
                                                                : myLocation === "UAE"
                                                                    ? (singleOrderDetails.totalPrice - singleOrderDetails.taxPrice) > 200
                                                                        ? 0
                                                                        : shippingCosts
                                                                    : myLocation === "IND"
                                                                        ? (singleOrderDetails.totalPrice - singleOrderDetails.taxPrice) > 4500
                                                                            ? 0
                                                                            : shippingCosts
                                                                        : (singleOrderDetails.totalPrice - singleOrderDetails.taxPrice) > 200
                                                                            ? 0
                                                                            : shippingCosts
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div>
                                    <h4 style={{ color: "#c69736" }}>
                                        {
                                            defaultLanguage === 'en'
                                                ? 'Shipping Address: '
                                                : 'عنوان الشحن: '
                                        }
                                    </h4>
                                    <table className="min-w-full divide-y divide-gray-300" style={{ color: "#ffffff" }}>
                                        <tbody className="divide-y divide-gray-200">
                                            <tr>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{singleOrderDetails?.shippingAddress?.firstname + " " + singleOrderDetails?.shippingAddress?.lastname}</td>
                                            </tr>

                                            <tr>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                                    {singleOrderDetails.shippingAddress.address} {singleOrderDetails.shippingAddress.appartment}{" "}
                                                    {singleOrderDetails.shippingAddress.city}
                                                    {singleOrderDetails.shippingAddress.state} {singleOrderDetails.shippingAddress.country}{" "}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{singleOrderDetails?.shippingAddress?.phone}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div>
                                    <h4 style={{ color: "#c69736" }}>
                                        {
                                            defaultLanguage === 'en'
                                                ? 'Billing Address: '
                                                : 'عنوان الشحن: '
                                        }
                                    </h4>
                                    <table className="min-w-full divide-y divide-gray-300" style={{ color: "#ffffff" }}>
                                        <tbody className="divide-y divide-gray-200">
                                            <tr>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{singleOrderDetails?.shippingAddress?.firstname + " " + singleOrderDetails?.shippingAddress?.lastname}</td>
                                            </tr>

                                            <tr>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                                    {singleOrderDetails.shippingAddress.address} {singleOrderDetails.shippingAddress.appartment}{" "}
                                                    {singleOrderDetails.shippingAddress.city}
                                                    {singleOrderDetails.shippingAddress.state} {singleOrderDetails.shippingAddress.country}{" "}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{singleOrderDetails?.shippingAddress?.phone}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="order-middle-section-new p-0" style={{ marginTop: '15px' }}>
                                <div>
                                    <h4 style={{ color: "#c69736" }}>
                                        {
                                            defaultLanguage === 'en'
                                                ? 'Payment Method: '
                                                : 'طريقة الدفع او السداد: '
                                        }
                                    </h4>
                                    <p style={{ color: "#ffffff" }}>
                                        {singleOrderDetails.paymentMethod}
                                    </p>
                                </div>

                                <div>
                                    <h4 style={{ color: "#c69736" }}>
                                        {
                                            defaultLanguage === 'en'
                                                ? 'Status: '
                                                : 'حالة: '
                                        }
                                    </h4>
                                    <p style={{ color: "#ffffff" }}>
                                        {
                                            singleOrderDetails.isCancelled === true
                                                ? 'Order cancelled'
                                                : singleOrderDetails.orderStatus === undefined || singleOrderDetails.orderStatus === null || Object.keys(singleOrderDetails.orderStatus).length === 0
                                                    ? 'Order Placed'
                                                    : singleOrderDetails.orderStatus.ScanDescription
                                        }
                                    </p>
                                </div>
                            </div>

                            <div className="order-table-container m-0">
                                <table className="order-table-new">
                                    <tr className="order-table-th">
                                        <th>
                                            {
                                                defaultLanguage === 'en'
                                                    ? 'Item'
                                                    : 'Item'
                                            }
                                        </th>
                                        <th>
                                            {
                                                defaultLanguage === 'en'
                                                    ? 'Price'
                                                    : 'السعر'
                                            }
                                        </th>
                                        <th>
                                            {
                                                defaultLanguage === 'en'
                                                    ? 'Qty'
                                                    : 'الكمية'
                                            }
                                        </th>
                                        <th>
                                            {
                                                defaultLanguage === 'en'
                                                    ? 'Status'
                                                    : 'الحالة'
                                            }
                                        </th>
                                    </tr>

                                    {singleOrderDetails.orderItems.map((item) => {
                                        return (
                                            <tr className="order-table-tr">
                                                <td className="order-table-img">
                                                    <Link to={`/details/${item._id}/${item.title}`}>
                                                        <img src={item.image} alt="perfume" />
                                                    </Link>
                                                    <h2 style={{ color: "#fff" }}>
                                                        {
                                                            defaultLanguage === 'en'
                                                                ? item.title
                                                                : item.arabictitle
                                                        }
                                                    </h2>
                                                </td>
                                                <td className="order-table-price">
                                                    <p>
                                                        {
                                                            localStorage.getItem("translate") === "en"
                                                                ? localStorage.getItem("currency_code")
                                                                : localStorage.getItem("currency_icon")
                                                        }
                                                        {" "}
                                                        {item.discountprice[0]}
                                                    </p>
                                                </td>
                                                <td className="order-table-qty">
                                                    <p>{item.count}</p>
                                                </td>
                                                <td>
                                                    {
                                                        singleOrderDetails.isCancelled === true
                                                            ? 'Order cancelled'
                                                            : singleOrderDetails.orderStatus === undefined || singleOrderDetails.orderStatus === null || Object.keys(singleOrderDetails.orderStatus).length === 0
                                                                ? defaultLanguage === 'en'
                                                                    ? <p>Processing</p>
                                                                    : <p>يعالج</p>
                                                                : singleOrderDetails.orderStatus.ScanDescription
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    })}

                                    <tr className="below-row">
                                        <td className="text-right" colSpan="3">
                                            <span className="row-heading">
                                                {
                                                    defaultLanguage === 'en'
                                                        ? 'SUBTOTAL: '
                                                        : 'مبلغ المجموع: '
                                                }
                                            </span>
                                        </td>
                                        <td className="text-right" colSpan="4">
                                            <span>
                                                {
                                                    localStorage.getItem("translate") === "en"
                                                        ? localStorage.getItem("currency_code")
                                                        : localStorage.getItem("currency_icon")
                                                } {" "}
                                                {singleOrderDetails.totalPrice - singleOrderDetails.taxPrice}
                                            </span>
                                        </td>
                                    </tr>

                                    <tr className="below-row">
                                        <td className="text-right" colSpan="3">
                                            <span className="row-heading">
                                                {
                                                    defaultLanguage === 'en'
                                                        ? 'VAT: '
                                                        : 'الضريبة المقدرة: '
                                                }
                                            </span>
                                        </td>
                                        <td className="text-right" colSpan="4">
                                            <span>
                                                {
                                                    localStorage.getItem("translate") === "en"
                                                        ? localStorage.getItem("currency_code")
                                                        : localStorage.getItem("currency_icon")
                                                } {" "}
                                                {
                                                    singleOrderDetails.taxPrice
                                                }
                                            </span>
                                        </td>
                                    </tr>

                                    <tr className="below-row">
                                        <td className="text-right" colSpan="3">
                                            <span className="row-heading">
                                                {
                                                    defaultLanguage === 'en'
                                                        ? 'SHIPPING CHARGE: '
                                                        : 'رسوم الشحن: '
                                                }
                                            </span>
                                        </td>

                                        <td className="text-right" colSpan="4">
                                            <span>
                                                {
                                                    localStorage.getItem("translate") === "en"
                                                        ? localStorage.getItem("currency_code")
                                                        : localStorage.getItem("currency_icon")
                                                } {" "}
                                            </span>
                                            <span>
                                                {
                                                    myLocation === "KSA"
                                                        ? (singleOrderDetails.totalPrice - singleOrderDetails.taxPrice) > 200
                                                            ? 0 + "(free)"
                                                            : shippingCosts
                                                        : myLocation === "UAE"
                                                            ? (singleOrderDetails.totalPrice - singleOrderDetails.taxPrice) > 200
                                                                ? 0 + "(free)"
                                                                : shippingCosts
                                                            : myLocation === "IND"
                                                                ? (singleOrderDetails.totalPrice - singleOrderDetails.taxPrice) > 4500
                                                                    ? 0 + "(free)"
                                                                    : shippingCosts
                                                                : (singleOrderDetails.totalPrice - singleOrderDetails.taxPrice) > 200
                                                                    ? 0 + "(free)"
                                                                    : shippingCosts
                                                }
                                            </span>
                                        </td>
                                    </tr>

                                    <tr className="below-row">
                                        <td className="text-right" colSpan="3">
                                            {
                                                defaultLanguage === 'en'
                                                    ? <span className="row-heading"> <span style={{ fontSize: '13px', color: 'red', verticalAlign: 'super' }}>*</span>TOTAL AMOUNT: </span>
                                                    : <span className="row-heading"> <span style={{ fontSize: '13px', color: 'red', verticalAlign: 'super' }}>*</span>المبلغ الاجمالي</span>
                                            }
                                        </td>
                                        <td className="text-right" colSpan="4">
                                            <span>
                                                {
                                                    localStorage.getItem("translate") === "en"
                                                        ? localStorage.getItem("currency_code")
                                                        : localStorage.getItem("currency_icon")
                                                } {" "}
                                                {
                                                    singleOrderDetails.totalPrice + parseFloat(
                                                        myLocation === "KSA"
                                                            ? (singleOrderDetails.totalPrice - singleOrderDetails.taxPrice) > 200
                                                                ? 0
                                                                : shippingCosts
                                                            : myLocation === "UAE"
                                                                ? (singleOrderDetails.totalPrice - singleOrderDetails.taxPrice) > 200
                                                                    ? 0
                                                                    : shippingCosts
                                                                : myLocation === "IND"
                                                                    ? (singleOrderDetails.totalPrice - singleOrderDetails.taxPrice) > 4500
                                                                        ? 0
                                                                        : shippingCosts
                                                                    : (singleOrderDetails.totalPrice - singleOrderDetails.taxPrice) > 200
                                                                        ? 0
                                                                        : shippingCosts
                                                    )
                                                }
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <div style={{
                                textAlign: 'center',
                                fontSize: '18px',
                                border: '0px',
                                paddingTop: '10px',
                                color: '#ffffff'
                            }}>
                                {
                                    myLocation === "KSA"
                                        ? defaultLanguage === 'en'
                                            ? <><span style={{ fontSize: '13px', color: 'red', verticalAlign: 'super' }}>*</span>An additional fee of 15 {getCurrencySymbol()} for the order amount of up to SAR 1000 , {getCurrencySymbol()} 20 up to SAR 3750, and 1% of order amount above {getCurrencySymbol()} 3750 will be applied for payments made using a card upon the time of delivery.</>
                                            : <><span style={{ fontSize: '13px', color: 'red', verticalAlign: 'super' }}>*</span>سيتم تطبيق رسوم إضافية قدرها 15 ريال سعودي على مبلغ الطلب حتى 1000 ريال سعودي، و20 ريال سعودي حتى 3750 ريال سعودي، و1% من مبلغ الطلب الذي يزيد عن 3750 ريال سعودي على عمليات الدفع التي تتم باستخدام البطاقة في وقت التسليم.</>
                                        : myLocation === "UAE"
                                            ? defaultLanguage === 'en'
                                                ? <><span style={{ fontSize: '13px', color: 'red', verticalAlign: 'super' }}>*</span>An additional fee of 1% of the total bill will be applied for payments made using a card upon the time of delivery.</>
                                                : <><span style={{ fontSize: '13px', color: 'red', verticalAlign: 'super' }}>*</span>سيتم تطبيق رسوم إضافية بنسبة 1% من إجمالي الفاتورة على الدفعات التي تتم باستخدام البطاقة عند وقت التسليم.</>
                                            : <></>
                                }
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
};
