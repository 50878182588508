/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import "./privacypolicy.css";
import axios from "axios";
import logo from "../../assets/images/crest-large.png";
import { useDispatch, useSelector } from "react-redux";
import { listPrice } from "../../actions/productaction";
import { Translate } from "react-auto-translate";
import { useLayoutEffect } from "react";

export const Privacypolicy = () => {
    const [datas, setdatas] = useState(null);
    const dispatch = useDispatch();

    useEffect(async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}privacy`);
        setdatas(data.user[0]);
    }, []);

    const prices = useSelector((state) => state.priceList);
    const { price } = prices;

    useEffect(() => {
        dispatch(listPrice());
    }, [dispatch]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    let contactPage = () => {
        window.location.replace("/contact")
    }

    const currentLocation = localStorage.getItem('country_code_iso3')

    return (
        localStorage.getItem("translate") === "en"
            ? currentLocation === "KSA"
                ? <div className="aboutus-container">
                    <div className="aboutus-logo">
                        <hr />
                        <img src={logo} alt="" />
                        <hr />
                    </div>
                    <div className="aboutus-head">
                        <h1>PRIVACY POLICY</h1>
                    </div>
                    <div className="aboutus-container-box">
                        <div className="about-para">
                            <p>
                                Al Majeed Modern Industry for Perfume Mfg. & Oudh Processing with brand name as Makkaj Perfumes an organization incorporated and located in Makkah, KSA and (who we refer to as "we", "us" and "our" below), take your privacy very seriously. This Privacy Statement explains what personal information we collect, how and when it is collected, what we use it for now and how we will use it in the FUTURE and details of the circumstances in which we may disclose it to third parties. If you have any questions about the way in which your information is being collected or used which are not answered by this Privacy Statement and/or any complaints please contact us on estore@makkaj.com
                                <br></br>
                                <br></br>
                                By visiting and using www.makkajperfumes.com (the "Website") or any other application or website ("Application") for the purchase or sampling of products from Makkaj Perfumes (as applicable) you acknowledge that you have read, understood and are consenting to the practices in relation to use and disclosure of your personal information described in this Privacy Statement and our Terms and Conditions. Please obtain your parent's or guardian's consent before providing us with any personal information if you are visiting and using the Site; Anywhere else in the world and you are under the age of 16.
                            </p>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5>WHAT INFORMATION DO WE COLLECT AND HOW WILL WE USE IT?</h5>
                                <p>
                                    When you REGISTER for an account with us, place an order with us or send us an enquiry, we will collect certain personal information from you, for example, your name, postal address, phone numbers, e-mail addresses. We may also obtain information about you as a result of authentication or identity checks. We use this information to identify you as a customer, to process your order, to deliver products, to process payments, to update our records, to enable your use of interactive features of our service and to generally manage your account with us and if you have consented to provide you with information by post, e-mail, mobile messaging, telephone communications and/or through any other electronic means including social network platforms about our products, events, promotions and services. We may also use this information to tailor how our website appears to you and to tailor the contents of our communications with you, so as to make the website and those communications more relevant to you.
                                    <br></br>
                                    <br></br>
                                    We may also at times ask you for other information, for example product and category preferences, age and any special dates (such as birthday and anniversary) which will be used to enhance our service to you. Providing us with this sort of information is entirely voluntary, however we may not be able to process your order if you do not provide us with all the requested information.
                                    <br></br>
                                    <br></br>
                                    We may also use your personal information for our internal MARKETING and demographic studies, together with non-personal data to analyze, profile and monitor customer patterns so we can consistently improve our products and services and understand what may be of interest to you and other customers.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor abooutus-images">
                            <div>
                                <h5>RETENTION AND DELETION OF PERSONAL INFORMATION</h5>
                                <p>
                                    We shall only keep your information as long as you remain an active customer.
                                    <br></br>
                                    <br></br>
                                    If you wish to request the deletion of your personal details, or wish to change your preferences at any time please contact our Customer Service Team.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5>WHAT YOU CAN EXPECT FROM MAKKAJ PERFUMES</h5>
                                <p>We will at all times seek to comply with the requirements of the Act to ensure that the personal information you give us is kept appropriately secure and processed fairly and lawfully.</p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5>OTHER PEOPLE WHO MIGHT USE YOUR INFORMATION</h5>
                                <p>
                                    “All credit/debit cards details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties”.
                                    <br></br>
                                    <br></br>
                                    You also acknowledge and agree that in certain circumstances we may disclose personal information relating to you to third parties, for example, in order to conform to any requirements of law, to comply with any legal process, for the purposes of obtaining legal advice, for the purposes of CREDIT RISK reduction, to prevent and detect fraud and/or to protect and defend the rights and property of MAKKAJ Perfumes. At all times where we disclose your information for the purposes of credit risk reduction and fraud prevention we will take all steps reasonably necessary to ensure that it remains secure.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5>UPDATING AND REVIEWING YOUR PERSONAL DETAILS</h5>
                                <p>
                                    You can amend or update your information by logging into My ACCOUNT on the Website and amending your details as appropriate.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5>CHANGES TO THIS PRIVACY STATEMENT</h5>
                                <p>
                                    We may update this Privacy Statement from time to time. The amended Privacy Statement will be posted on the Website. Please check this page regularly for changes to this Privacy Statement. If you continue to use the Website or if you submit information to us following such changes, you will be deemed to have read and agreed them.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5>THIRD PARTY LINKS</h5>
                                <p>
                                    The Website and/or Applications may from time to time contain links to other websites not controlled by us. We do not accept responsibility or LIABILITY for the privacy practices, data collected or the content of such other websites. The operators of these linked websites are not under a duty to abide by this Privacy Statement. If there are terms and conditions, other privacy statements or policies appearing on those websites, you should also review them carefully as your use of those sites may be subject to them.
                                </p>
                            </div>
                        </div>

                        <div className="about-us-decor">
                            <div>
                                <h5>SECURITY STATEMENT</h5>
                                <p>
                                    Unfortunately transmission of information via the Internet is not completely secure. Although we do our best to protect your personal data we cannot guarantee the security of your data submitted to us and any transmission is at your own risk.
                                    <br></br>
                                    <br></br>
                                    We do however use strict procedures and security features to try to prevent unauthorized access wherever possible. Personal information provided to MAKKAJ Perfumes via the Website or via any Applications and online CREDIT card transactions are transmitted through a secure server using Secure Socket Layering (SSL), encryption technology. When the letters "http" in the URL change to "https," the "s" indicates you are in a secure area EMPLOYING SSL; also, your browser may give you a pop-up message that you are about to enter a secure area or display a padlock image.
                                    <br></br>
                                    <br></br>
                                    The Website and the Applications use this encryption technology to protect your information during data transport. SSL encrypts ordering information such as your name, address and credit card number. Our customer service center and stores also operate over a private, secure network. Please note that e-mail is not encrypted and is not considered to be a secure means of transmitting credit card information.
                                </p>
                            </div>
                        </div>

                        <div style={{ textAlign: 'center', marginTop: '10vh' }}>
                            <p className="contact_us" onClick={() => window.location.href = '/contact'}>For any further queries, please contact us</p>
                        </div>
                    </div>
                </div>
                : <div className="aboutus-container">
                    <div className="aboutus-logo">
                        <hr />
                        <img src={logo} alt="" />
                        <hr />
                    </div>
                    <div className="aboutus-head">
                        <h1>PRIVACY POLICY</h1>
                    </div>
                    <div className="aboutus-container-box">
                        <div className="about-para">
                            <p>
                                Amayra Perfumes Trading LLC., with brand name as Makkaj Perfumes an organization incorporated and located in Dubai, UAE and (who we refer to as "we", "us" and "our" below), take your privacy very seriously. This Privacy Statement explains what personal information we collect, how and when it is collected, what we use it for now and how we will use it in the FUTURE and details of the circumstances in which we may disclose it to third parties. If you have any questions about the way in which your information is being collected or used which are not answered by this Privacy Statement and/or any complaints please contact us on estore@makkaj.com
                                <br></br>
                                <br></br>
                                By visiting and using www.makkajperfumes.com (the "Website") or any other application or website ("Application") for the purchase or sampling of products from Makkaj Perfumes (as applicable) you acknowledge that you have read, understood and are consenting to the practices in relation to use and disclosure of your personal information described in this Privacy Statement and our Terms and Conditions. Please obtain your parent's or guardian's consent before providing us with any personal information if you are visiting and using the Site; Anywhere else in the world and you are under the age of 16.
                            </p>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5>WHAT INFORMATION DO WE COLLECT AND HOW WILL WE USE IT?</h5>
                                <p>
                                    When you REGISTER for an account with us, place an order with us or send us an enquiry, we will collect certain personal information from you, for example, your name, postal address, phone numbers, e-mail addresses. We may also obtain information about you as a result of authentication or identity checks. We use this information to identify you as a customer, to process your order, to deliver products, to process payments, to update our records, to enable your use of interactive features of our service and to generally manage your account with us and if you have consented to provide you with information by post, e-mail, mobile messaging, telephone communications and/or through any other electronic means including social network platforms about our products, events, promotions and services. We may also use this information to tailor how our website appears to you and to tailor the contents of our communications with you, so as to make the website and those communications more relevant to you.
                                    <br></br>
                                    <br></br>
                                    We may also at times ask you for other information, for example product and category preferences, age and any special dates (such as birthday and anniversary) which will be used to enhance our service to you. Providing us with this sort of information is entirely voluntary, however we may not be able to process your order if you do not provide us with all the requested information.
                                    <br></br>
                                    <br></br>
                                    We may also use your personal information for our internal MARKETING and demographic studies, together with non-personal data to analyze, profile and monitor customer patterns so we can consistently improve our products and services and understand what may be of interest to you and other customers.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor abooutus-images">
                            <div>
                                <h5>RETENTION AND DELETION OF PERSONAL INFORMATION</h5>
                                <p>
                                    We shall only keep your information as long as you remain an active customer.
                                    <br></br>
                                    <br></br>
                                    If you wish to request the deletion of your personal details, or wish to change your preferences at any time please contact our Customer Service Team.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5>WHAT YOU CAN EXPECT FROM MAKKAJ PERFUMES</h5>
                                <p>
                                    We will at all times seek to comply with the requirements of the Act to ensure that the personal information you give us is kept appropriately secure and processed fairly and lawfully.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5>OTHER PEOPLE WHO MIGHT USE YOUR INFORMATION</h5>
                                <p>
                                    “All credit/debit cards details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties”.
                                    <br></br>
                                    <br></br>
                                    You also acknowledge and agree that in certain circumstances we may disclose personal information relating to you to third parties, for example, in order to conform to any requirements of law, to comply with any legal process, for the purposes of obtaining legal advice, for the purposes of CREDIT RISK reduction, to prevent and detect fraud and/or to protect and defend the rights and property of MAKKAJ Perfumes. At all times where we disclose your information for the purposes of credit risk reduction and fraud prevention we will take all steps reasonably necessary to ensure that it remains secure.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5>UPDATING AND REVIEWING YOUR PERSONAL DETAILS</h5>
                                <p>
                                    You can amend or update your information by logging into My ACCOUNT on the Website and amending your details as appropriate.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5>CHANGES TO THIS PRIVACY STATEMENT</h5>
                                <p>
                                    We may update this Privacy Statement from time to time. The amended Privacy Statement will be posted on the Website. Please check this page regularly for changes to this Privacy Statement. If you continue to use the Website or if you submit information to us following such changes, you will be deemed to have read and agreed them.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5>THIRD PARTY LINKS</h5>
                                <p>
                                    The Website and/or Applications may from time to time contain links to other websites not controlled by us. We do not accept responsibility or LIABILITY for the privacy practices, data collected or the content of such other websites. The operators of these linked websites are not under a duty to abide by this Privacy Statement. If there are terms and conditions, other privacy statements or policies appearing on those websites, you should also review them carefully as your use of those sites may be subject to them.
                                </p>
                            </div>
                        </div>

                        <div className="about-us-decor">
                            <div>
                                <h5>SECURITY STATEMENT</h5>
                                <p>
                                    Unfortunately transmission of information via the Internet is not completely secure. Although we do our best to protect your personal data we cannot guarantee the security of your data submitted to us and any transmission is at your own risk.
                                    <br></br>
                                    <br></br>
                                    We do however use strict procedures and security features to try to prevent unauthorized access wherever possible. Personal information provided to MAKKAJ Perfumes via the Website or via any Applications and online CREDIT card transactions are transmitted through a secure server using Secure Socket Layering (SSL), encryption technology. When the letters "http" in the URL change to "https," the "s" indicates you are in a secure area EMPLOYING SSL; also, your browser may give you a pop-up message that you are about to enter a secure area or display a padlock image.
                                    <br></br>
                                    <br></br>
                                    The Website and the Applications use this encryption technology to protect your information during data transport. SSL encrypts ordering information such as your name, address and credit card number. Our customer service center and stores also operate over a private, secure network. Please note that e-mail is not encrypted and is not considered to be a secure means of transmitting credit card information.
                                </p>
                            </div>
                        </div>

                        <div style={{ textAlign: 'center', marginTop: '10vh' }}>
                            <p className="contact_us" onClick={() => window.location.href = '/contact'}>For any further queries, please contact us</p>
                        </div>
                    </div>
                </div>
            : currentLocation === "KSA"
                ? <div className="aboutus-container">
                    <div className="aboutus-logo">
                        <hr />
                        <img src={logo} alt="" />
                        <hr />
                    </div>
                    <div className="aboutus-head">
                        <h1>الخصوصية</h1>
                    </div>
                    <div className="aboutus-container-box">
                        <div className="about-para">
                            <p className="text_rtl">
                                مصنع المجيد الحديث لتصنيع العطور و العود وهي شركة تم تأسيسها وتسجيلها في دبي-ا.ع.م.)ويشار إليها فيما يلي بعبارة “نحن”( تأخذ خصوصيتك على محمل الجد. بيان الخصوصية هذا يشرح ما هي المعلومات الشخصية التي نجمعها، كيف ومتى يتم جمعها، الأغراض التي نستخدمها من أجلها الآن وكيف سنستعملها في المستقبل وتفاصيل الظروف التي قد تجعلنا نفصح عنها لأطراف ثالثة. إن كانت لديك أية أسئلة حول الطريقة التي يتم بواسطتها جمع معلوماتك أو استخدامها ولم تجد الإجابة عنها في بيان الخصوصية هذا و/أو أية شكاوى يرجى الاتصال بنا على estore@makkaj.com
                                <br></br>
                                <br></br>
                                بزيارة واستخدام “www.makkajperfumes.com الموقع” أو أي تطبيق آخر أو موقع )“التطبيق”( لشراء أو تجربة المنتجات من مكاج للعطور)حسب الحالة( أنت تقر بأنك قد قرأت وفهمت وتوافق على الممارسات فيما يتعلق بالاستخدام والكشف عن معلوماتك الشخصية الموصوفة في بيان الخصوصية هذا والأحكام والشروط. يرجى الحصول على موافقة والدك/والدتك أو ولي أمرك قبل تزويدنا بأية معلومات شخصية إن كنت تزور وتستخدم هذا الموقع في أي مكان آخر في العالم وأنت دون ١٦ سنة.
                            </p>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>ما هي المعلومات التي نجمعها وكيف سنستعملها؟</p></h5>
                                <p className="text_rtl">
                                    عندما تسجل لفتح حساب معنا أو تطلب منتجات منا أو ترسل إلينا أي استفسار، نجمع معلومات شخصية معينة منك، مثلاً إسمك، عنوانك البريدي، أرقام هواتفك، عنوان بريدك الإلكتروني وأرقام بطاقتك الائتمانية. قد نحصل أيضاً على معلومات عنك كنتيجة للمصادقة أو التحقق من هويتك. نستخدم هذه المعلومات لتحديدك كعميل، لإنجاز طلبك، لتسليم المنتجات، لإكمال الدفعات، لتحديث سجلاتنا، لتمكينك من استخدام المزايا التفاعلية لخدمتنا ولإدارة حسابك معنا بشكل عام وإذا كنت قد وافقت على تزويدنا بالمعلومات بواسطة البريد، البريد الإلكتروني، رسائل الهاتف النقال، الإتصالات الهاتفية و/أو عبر أية وسيلة إلكترونية أخرى بما فيها مواقع التواصل الاجتماعي حول منتجاتنا، أحداثنا، حملاتنا التروجية وخدماتنا. وقد نستخدم هذه المعلومات أيضاً لتكييف كيفية ظهور موقعنا بالنسبة لك ومحتويات اتصالاتنا معك، وذلك لجعل الموقع وتلك الإتصالات ذات مغزى أفضل بالنسبة لك.
                                    <br></br>
                                    <br></br>
                                    وقد نطلب أحياناً معلومات أخرى، مثل تفضيلات المنتجات والفئات، العمر وأية تواريخ خاصة )مثل عيد الميلاد والذكرى السنوية(والتي سيتم استخدامها لتعزيز خدمتنا لك. تزويدنا بهذا النوع من المعلومات أمر اختياري كلياً ولكن قد لا نستطيع إنجاز طلبك إن لم تزودنا بجميع المعلومات المطلوبة.
                                    <br></br>
                                    <br></br>
                                    قد نجمع معلومات إحصائية حول حركة زوار الموقع والمبيعات ومعلومات أخرى مرتبطة بالاستخدام وقد ننقل هذه المعلومات إلى أطراف ثالثة. هذه الإحصائيات لا تشمل أية معلومات يمكنها أن تتيح التعرف عليك شخصياً.
                                    <br></br>
                                    <br></br>
                                    وقد نستعمل معلوماتك الشخصية أيضاً لدراساتنا الداخلية المرتبطة بالتسويق والسكان، مع معلومات غير شخصية لتحليل وتصنيف ومراقبة أنماط العملاء لكي نعمل باستمرار على تحسين منتجاتنا وخدماتنا وفهم ما قد يهمك ويهم العملاء الآخرين.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor abooutus-images">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>الاحتفاظ بالمعلومات الشخصية وحذفها</p></h5>
                                <p className="text_rtl">
                                    سنقوم بإبقاء معلوماتك طالما تبقى عميلاً نشطاً.
                                    <br></br>
                                    <br></br>
                                    إذا رغبت بطلب حذف معلوماتك الشخصية، أو رغبت بتغيير تفضيلاتك في أي وقت يرجى الاتصال بفريق خدمة العملاء.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>ما يمكنك أن تتوقعه من مكاج للعطور</p></h5>
                                <p className="text_rtl">نحن نسعى في جميع الأوقات للتقيد بمتطلبات القانون لضمان أن المعلومات الشخصية التي تقدمها لنا تبقى آمنة بشكل مناسب وتتم معالجتها بشكل عادل وقانوني.</p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>الأشخاص الآخرون الذين قد يستخدمون معلوماتك</p></h5>
                                <p className="text_rtl">
                                    ““لن يتم تخزين أو بيع أو مشاركة أو تأجير جميع تفاصيل البطاقات الائتمانية/المصرفية ومعلومات التعريف الشخصي إلى أي طرف ثالث”.”.
                                    <br></br>
                                    <br></br>
                                    أنت تقر وتوافق أيضاً أنه في ظروف معينة قد نفصح عن معلومات شخصية متعلقة بك إلى أطراف ثالثة، على سبيل المثال للإلتزام بأية متطلبات قانونية، للإمتثال لأية إجراءات قانونية، لأغراض الحصول على المشورة القانونية، لأغراض الحد من مخاطر الائتمان، لمنع واكتشاف الغش و/أو لحماية والدفاع عن حقوق وممتلكات مكاج للعطور. في جميع الأوقات عندما نفصح عن معلوماتك لأغراض الحد من مخاطر الائتمان ومنع الغش سنتخذ جميع الخطوات الضرورية بشكل منطقي لضمان بقاء المعلومات آمنة.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>تحديث ومراجعة تفاصيلك الشخصية</p></h5>
                                <p className="text_rtl">
                                    يمكنك تعديل أو تحديث معلوماتك بالدخول إلى حسابك على الموقع وتعديل تفاصيلك حسب الاقتضاء.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>التغييرات على بيان الخصوصية</p></h5>
                                <p className="text_rtl">
                                    قد نقوم بتحديث بيان الخصوصية هذا من وقت لآخر. سيتم نشر بيان الخصوصية المعدل على موقع الإنترنت. يرجى الرجوع إلى هذه الصفحة بانتظام للإطلاع على التغييرات في بيان الخصوصية. إذا استمريت في استخدام الموقع أو إذا قدمت معلومات بعد التغييرات، سنعتبر أنك قد قرأتها ووافقت عليها.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>روابط الطرف الثالث</p></h5>
                                <p className="text_rtl">
                                    قد يحتوي الموقع و/أو التطبيقات من وقت لآخر على روابط لمواقع أخرى لا نسيطر عليها. لا نتحمل أية مسؤولية تجاه ممارسات الخصوصية، المعلومات التي يتم جمعها أو محتوى هذه المواقع الأخرى. مشغلو هذه المواقع المرتبطة ليسوا مجبرين على التقيد ببيان الخصوصية هذا. إن كانت هناك أحكام وشروط، بيانات أو سياسات أخرى للخصوصية تظهر على هذه المواقع، يجب عليك استعراضها أيضاً بعناية لأن استخدامك لهذه المواقع يخضع لها.
                                </p>
                            </div>
                        </div>

                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>بيان الأمن</p></h5>
                                <p className="text_rtl">
                                    للأسف إرسال المعلومات بواسطة الإنترنت ليس آمناً بشكل كامل. بالرغم من أننا نبذل قصارى جهدنا لحماية معلوماتك الشخصية لا يمكننا أن نضمن سلامة معلوماتك المقدمة إلينا وأي إرسال لهذه المعلومات يكون على مسؤوليتك الخاصة.
                                    <br></br>
                                    <br></br>
                                    على أي حال، نحن نتبع إجراءات صارمة ومزايا أمنية لنحاول منع الدخول غير المصرح به أينما أمكن. المعلومات الشخصية المزودة إلى مكاج للعطور عن طريق الموقع أو بواسطة أية تطبيقات ومعاملات البطاقات الائتمانية عبر الإنترنت يتم إرسالها من خلال جهاز خادم آمن باستخدام تقنية التشفير إس إس إل. عندما تتحول الأحرف “http” في عنوان الموقع إلى “https”، فإن حرف “s” يعني أنك في منطقة آمنة تستخدم نظام إس إس إل. أيضاً، قد يعطيك متصفحك رسالة بارزة تفيد أنك على وشك الدخول إلى منطقة آمنة أو يعرض صورة قفل.
                                    <br></br>
                                    <br></br>
                                    يستخدم الموقع والتطبيقات هذه التقنية للتشفير من أجل حماية معلوماتك أثناء انتقالها. نظام إس إس إل يشفر معلومات الطلب مثل إسمك وعنوانك ورقم بطاقتك الائتمانية. كما يعمل مركزنا لخدمة العملاء ومتاجرنا على شبكة خاصة آمنة. يرجى أخذ العلم أن البريد الإلكتروني ليس مشفراً ولا يعتبر وسيلة آمنة لإرسال معلومات البطاقات الائتمانية.
                                </p>
                            </div>
                        </div>

                        <div style={{ textAlign: 'center', marginTop: '10vh' }}>
                            <p className="contact_us" onClick={() => window.location.href = '/contact'}>للحصول على أي استفسارات أخرى ، يرجى الاتصال بنا</p>
                        </div>
                    </div>
                </div>
                : <div className="aboutus-container">
                    <div className="aboutus-logo">
                        <hr />
                        <img src={logo} alt="" />
                        <hr />
                    </div>
                    <div className="aboutus-head">
                        <h1>الخصوصية</h1>
                    </div>
                    <div className="aboutus-container-box">
                        <div className="about-para">
                            <p className="text_rtl">
                                اميرة لتجارة العطور ذ.هي شركة تم تأسيسها وتسجيلها في دبي- الامارات العربية المتحدة .)ويشار إليها فيما يلي بعبارة “نحن”( تأخذ خصوصيتك على محمل الجد. بيان الخصوصية هذا يشرح ما هي المعلومات الشخصية التي نجمعها، كيف ومتى يتم جمعها، الأغراض التي نستخدمها من أجلها الآن وكيف سنستعملها في المستقبل وتفاصيل الظروف التي قد تجعلنا نفصح عنها لأطراف ثالثة. إن كانت لديك أية أسئلة حول الطريقة التي يتم بواسطتها جمع معلوماتك أو استخدامها ولم تجد الإجابة عنها في بيان الخصوصية هذا و/أو أية شكاوى يرجى الاتصال بنا على estore@makkaj.com
                                <br></br>
                                <br></br>
                                بزيارة واستخدام “www.makkajperfumes.com الموقع” أو أي تطبيق آخر أو موقع )“التطبيق”( لشراء أو تجربة المنتجات من مكاج للعطور)حسب الحالة( أنت تقر بأنك قد قرأت وفهمت وتوافق على الممارسات فيما يتعلق بالاستخدام والكشف عن معلوماتك الشخصية الموصوفة في بيان الخصوصية هذا والأحكام والشروط. يرجى الحصول على موافقة والدك/والدتك أو ولي أمرك قبل تزويدنا بأية معلومات شخصية إن كنت تزور وتستخدم هذا الموقع في أي مكان آخر في العالم وأنت دون ١٦ سنة.
                            </p>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>ما هي المعلومات التي نجمعها وكيف سنستعملها؟</p></h5>
                                <p className="text_rtl">
                                    عندما تسجل لفتح حساب معنا أو تطلب منتجات منا أو ترسل إلينا أي استفسار، نجمع معلومات شخصية معينة منك، مثلاً إسمك، عنوانك البريدي، أرقام هواتفك، عنوان بريدك الإلكتروني وأرقام بطاقتك الائتمانية. قد نحصل أيضاً على معلومات عنك كنتيجة للمصادقة أو التحقق من هويتك. نستخدم هذه المعلومات لتحديدك كعميل، لإنجاز طلبك، لتسليم المنتجات، لإكمال الدفعات، لتحديث سجلاتنا، لتمكينك من استخدام المزايا التفاعلية لخدمتنا ولإدارة حسابك معنا بشكل عام وإذا كنت قد وافقت على تزويدنا بالمعلومات بواسطة البريد، البريد الإلكتروني، رسائل الهاتف النقال، الإتصالات الهاتفية و/أو عبر أية وسيلة إلكترونية أخرى بما فيها مواقع التواصل الاجتماعي حول منتجاتنا، أحداثنا، حملاتنا التروجية وخدماتنا. وقد نستخدم هذه المعلومات أيضاً لتكييف كيفية ظهور موقعنا بالنسبة لك ومحتويات اتصالاتنا معك، وذلك لجعل الموقع وتلك الإتصالات ذات مغزى أفضل بالنسبة لك.
                                    <br></br>
                                    <br></br>
                                    وقد نطلب أحياناً معلومات أخرى، مثل تفضيلات المنتجات والفئات، العمر وأية تواريخ خاصة )مثل عيد الميلاد والذكرى السنوية(والتي سيتم استخدامها لتعزيز خدمتنا لك. تزويدنا بهذا النوع من المعلومات أمر اختياري كلياً ولكن قد لا نستطيع إنجاز طلبك إن لم تزودنا بجميع المعلومات المطلوبة.
                                    <br></br>
                                    <br></br>
                                    قد نجمع معلومات إحصائية حول حركة زوار الموقع والمبيعات ومعلومات أخرى مرتبطة بالاستخدام وقد ننقل هذه المعلومات إلى أطراف ثالثة. هذه الإحصائيات لا تشمل أية معلومات يمكنها أن تتيح التعرف عليك شخصياً.
                                    <br></br>
                                    <br></br>
                                    وقد نستعمل معلوماتك الشخصية أيضاً لدراساتنا الداخلية المرتبطة بالتسويق والسكان، مع معلومات غير شخصية لتحليل وتصنيف ومراقبة أنماط العملاء لكي نعمل باستمرار على تحسين منتجاتنا وخدماتنا وفهم ما قد يهمك ويهم العملاء الآخرين.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor abooutus-images">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>الاحتفاظ بالمعلومات الشخصية وحذفها</p></h5>
                                <p className="text_rtl">
                                    سنقوم بإبقاء معلوماتك طالما تبقى عميلاً نشطاً.
                                    <br></br>
                                    <br></br>
                                    إذا رغبت بطلب حذف معلوماتك الشخصية، أو رغبت بتغيير تفضيلاتك في أي وقت يرجى الاتصال بفريق خدمة العملاء.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>ما يمكنك أن تتوقعه من مكاج للعطور</p></h5>
                                <p className="text_rtl">
                                    نحن نسعى في جميع الأوقات للتقيد بمتطلبات القانون لضمان أن المعلومات الشخصية التي تقدمها لنا تبقى آمنة بشكل مناسب وتتم معالجتها بشكل عادل وقانوني.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>الأشخاص الآخرون الذين قد يستخدمون معلوماتك</p></h5>
                                <p className="text_rtl">
                                    ““لن يتم تخزين أو بيع أو مشاركة أو تأجير جميع تفاصيل البطاقات الائتمانية/المصرفية ومعلومات التعريف الشخصي إلى أي طرف ثالث”.”.
                                    <br></br>
                                    <br></br>
                                    أنت تقر وتوافق أيضاً أنه في ظروف معينة قد نفصح عن معلومات شخصية متعلقة بك إلى أطراف ثالثة، على سبيل المثال للإلتزام بأية متطلبات قانونية، للإمتثال لأية إجراءات قانونية، لأغراض الحصول على المشورة القانونية، لأغراض الحد من مخاطر الائتمان، لمنع واكتشاف الغش و/أو لحماية والدفاع عن حقوق وممتلكات مكاج للعطور. في جميع الأوقات عندما نفصح عن معلوماتك لأغراض الحد من مخاطر الائتمان ومنع الغش سنتخذ جميع الخطوات الضرورية بشكل منطقي لضمان بقاء المعلومات آمنة.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>تحديث ومراجعة تفاصيلك الشخصية</p></h5>
                                <p className="text_rtl">
                                    يمكنك تعديل أو تحديث معلوماتك بالدخول إلى حسابك على الموقع وتعديل تفاصيلك حسب الاقتضاء.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>التغييرات على بيان الخصوصية</p></h5>
                                <p className="text_rtl">
                                    قد نقوم بتحديث بيان الخصوصية هذا من وقت لآخر. سيتم نشر بيان الخصوصية المعدل على موقع الإنترنت. يرجى الرجوع إلى هذه الصفحة بانتظام للإطلاع على التغييرات في بيان الخصوصية. إذا استمريت في استخدام الموقع أو إذا قدمت معلومات بعد التغييرات، سنعتبر أنك قد قرأتها ووافقت عليها.
                                </p>
                            </div>
                        </div>
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>روابط الطرف الثالث</p></h5>
                                <p className="text_rtl">
                                    قد يحتوي الموقع و/أو التطبيقات من وقت لآخر على روابط لمواقع أخرى لا نسيطر عليها. لا نتحمل أية مسؤولية تجاه ممارسات الخصوصية، المعلومات التي يتم جمعها أو محتوى هذه المواقع الأخرى. مشغلو هذه المواقع المرتبطة ليسوا مجبرين على التقيد ببيان الخصوصية هذا. إن كانت هناك أحكام وشروط، بيانات أو سياسات أخرى للخصوصية تظهر على هذه المواقع، يجب عليك استعراضها أيضاً بعناية لأن استخدامك لهذه المواقع يخضع لها.
                                </p>
                            </div>
                        </div>

                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>بيان الأمن</p></h5>
                                <p className="text_rtl">
                                    للأسف إرسال المعلومات بواسطة الإنترنت ليس آمناً بشكل كامل. بالرغم من أننا نبذل قصارى جهدنا لحماية معلوماتك الشخصية لا يمكننا أن نضمن سلامة معلوماتك المقدمة إلينا وأي إرسال لهذه المعلومات يكون على مسؤوليتك الخاصة.
                                    <br></br>
                                    <br></br>
                                    على أي حال، نحن نتبع إجراءات صارمة ومزايا أمنية لنحاول منع الدخول غير المصرح به أينما أمكن. المعلومات الشخصية المزودة إلى مكاج للعطور عن طريق الموقع أو بواسطة أية تطبيقات ومعاملات البطاقات الائتمانية عبر الإنترنت يتم إرسالها من خلال جهاز خادم آمن باستخدام تقنية التشفير إس إس إل. عندما تتحول الأحرف “http” في عنوان الموقع إلى “https”، فإن حرف “s” يعني أنك في منطقة آمنة تستخدم نظام إس إس إل. أيضاً، قد يعطيك متصفحك رسالة بارزة تفيد أنك على وشك الدخول إلى منطقة آمنة أو يعرض صورة قفل.
                                    <br></br>
                                    <br></br>
                                    يستخدم الموقع والتطبيقات هذه التقنية للتشفير من أجل حماية معلوماتك أثناء انتقالها. نظام إس إس إل يشفر معلومات الطلب مثل إسمك وعنوانك ورقم بطاقتك الائتمانية. كما يعمل مركزنا لخدمة العملاء ومتاجرنا على شبكة خاصة آمنة. يرجى أخذ العلم أن البريد الإلكتروني ليس مشفراً ولا يعتبر وسيلة آمنة لإرسال معلومات البطاقات الائتمانية.
                                </p>
                            </div>
                        </div>

                        <div style={{ textAlign: 'center', marginTop: '10vh' }}>
                            <p className="contact_us" onClick={() => window.location.href = '/contact'}>للحصول على أي استفسارات أخرى ، يرجى الاتصال بنا</p>
                        </div>
                    </div>
                </div>
    )
}
