import React from "react";

import { Productdetails } from "../Productdetails/Productdetails";

export const Stores = () => {
  return (
    <div>
      <Productdetails />
    </div>
  );
};
