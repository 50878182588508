import React from "react";
import { Sidebar } from "../../components/sidebar/sidebar";
import "./myorders.css";

import { defaultLanguage } from "../../helpers"

export const OrderCancel = (props) => {
    return (
        <div>
            <h1 className="breadcrumb-css">
                {
                    defaultLanguage === "en"
                        ? 'MY ORDERS'
                        : 'طلباتي'
                }
            </h1>

            <div className="my-account">
                <div className="sidebar-left">
                    {" "}
                    <Sidebar />
                </div>
                <div className="myorder-right" style={{ color: 'white' }}>
                    <p>Your order has been cancelled</p>
                    <p>You will be refunded within 7 to 10 working days.</p>
                    <p style={{ color: '#c69736', cursor: 'pointer' }} onClick={() => window.location.href = '/'}>Home</p>
                </div>
            </div>
        </div>
    )
};