/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import "./Shippingpolicy.css";
import axios from "axios";
import logo from "../../assets/images/crest-large.png";
import { useDispatch, useSelector } from "react-redux";
import { listPrice } from "../../actions/productaction";
import { Translate } from "react-auto-translate";
import { useLayoutEffect } from "react";

export const Shippingpolicy = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    let contactPage = () => {
        window.location.replace("/contact")
    }

    const currentLocation = localStorage.getItem('country_code_iso3')

    return (
        localStorage.getItem("translate") === "en"
            ? currentLocation === "KSA"
                ? <div className="aboutus-container">
                    <div className="aboutus-logo">
                        <hr />
                        <img src={logo} alt="" />
                        <hr />
                    </div>
                    <div className="aboutus-head">
                        <h1>SHIPPING POLICY</h1>
                    </div>
                    <div className="aboutus-container-box">
                        <div className="about-us-decor">
                            <div>
                                <h5>TRACK MY ORDER</h5>
                                <p>
                                    Upon shipping your order, we will send confirmation email containing your tracking number to verify the acceptance of your order. You can monitor the shipment status by entering the provided tracking number on the SMSA website.
                                </p>
                            </div>
                        </div>

                        <div className="about-us-decor">
                            <div>
                                <h5>SHIPPING, DESTINATIONS, COSTS AND DELIVERY TIMES</h5>
                                <p>
                                    Estimated delivery times begin from the shipping date, not the order date. These times are provided as a reference and are contingent upon the acceptance and approval of your order. Choose your desired shipping destination to view shipping options, delivery times, associated costs, and additional details. Keep in mind that shipping restrictions may vary based on the selected destination.
                                </p>
                            </div>
                        </div>

                        <div className="about-us-decor">
                            <div>
                                <h5>SHIPPING DESTINATONS</h5>
                                <p>Kingdom of Saudi Arabia</p>
                                <table className="shipping-policy-table">
                                    <tr className="order-table-th">
                                        <th style={{ width: '30%' }}>SHIPPING OPTIONS FOR KSA</th>
                                        <th style={{ width: '20%' }}>COST</th>
                                        <th style={{ width: '50%' }}>SHIPPING INFORMATION</th>
                                    </tr>
                                    <tr>
                                        <td>Below Cart Value</td>
                                        <td>SAR. 25.00</td>
                                        <td>For order value below SAR. 200. Receive your order within 2-3 business days</td>
                                    </tr>

                                    <tr>
                                        <td>Above Cart Value</td>
                                        <td>Free</td>
                                        <td>For order value above SAR. 200. Receive your order within 2-3 business days</td>
                                    </tr>
                                </table>
                                <p>Business days are Sunday to Thursday, excluding public holidays in the KSA.</p>
                                <p>All orders are shipped domestically from KSA.</p>
                                <p>Please note: Orders made after the specified cut-off times or during weekends and public holidays will be processed on the next business day. Please note that we do not deliver to freight forwarding addresses or PO Boxes.</p>
                            </div>
                        </div>

                        <div className="about-us-decor">
                            <div>
                                <h5>SHIPPING RESTRICTION</h5>
                                <p>Presently, Makkaj Perfumes online store is restricted to purchases and shipments within KSA & UAE. To explore the available offers and products in your respective country, kindly choose your preferred country.</p>
                                <p>Please note that if you intend to place an order in the KSA and request delivery to other countries, we regret to inform you that we cannot process such requests.</p>
                                <p>Due to stringent international trading agreements and regulations, fragrance shipments are limited to the KSA at the moment.</p>
                                <p>For orders in KSA, please ensure you select the country before placing the order.</p>
                            </div>
                        </div>

                        <div style={{ textAlign: 'center', marginTop: '10vh' }}>
                            <p className="contact_us" onClick={() => window.location.href = '/contact'}>For any further queries, please contact us</p>
                        </div>
                    </div>
                </div>
                : <div className="aboutus-container">
                    <div className="aboutus-logo">
                        <hr />
                        <img src={logo} alt="" />
                        <hr />
                    </div>
                    <div className="aboutus-head">
                        <h1>SHIPPING POLICY</h1>
                    </div>
                    <div className="aboutus-container-box">
                        <div className="about-us-decor">
                            <div>
                                <h5>TRACK MY ORDER</h5>
                                <p>
                                    Upon shipping your order, we will send confirmation email containing your tracking number to verify the acceptance of your order. You can monitor the shipment status by entering the provided tracking number on the SMSA website.
                                </p>
                            </div>
                        </div>

                        <div className="about-us-decor">
                            <div>
                                <h5>SHIPPING, DESTINATIONS, COSTS AND DELIVERY TIMES</h5>
                                <p>
                                    Estimated delivery times begin from the shipping date, not the order date. These times are provided as a reference and are contingent upon the acceptance and approval of your order. Choose your desired shipping destination to view shipping options, delivery times, associated costs, and additional details. Keep in mind that shipping restrictions may vary based on the selected destination.
                                </p>
                            </div>
                        </div>

                        <div className="about-us-decor">
                            <div>
                                <h5>SHIPPING DESTINATONS</h5>
                                <p>United Arab Emirates</p>
                                <table className="shipping-policy-table">
                                    <tr className="order-table-th">
                                        <th style={{ width: '30%' }}>SHIPPING OPTIONS FOR UAE</th>
                                        <th style={{ width: '20%' }}>COST</th>
                                        <th style={{ width: '50%' }}>SHIPPING INFORMATION</th>
                                    </tr>
                                    <tr>
                                        <td>Below Cart Value</td>
                                        <td>AED. 25.00</td>
                                        <td>For order value below AED. 200. Receive your order within 2-3 business days</td>
                                    </tr>

                                    <tr>
                                        <td>Above Cart Value</td>
                                        <td>Free</td>
                                        <td>For order value above AED. 200. Receive your order within 2-3 business days</td>
                                    </tr>
                                </table>
                                <p>Business days are Monday to Friday, excluding public holidays in the UAE.</p>
                                <p>All orders are shipped domestically from UAE.</p>
                                <p>Please note: Orders made after the specified cut-off times or during weekends and public holidays will be processed on the next business day. Please note that we do not deliver to freight forwarding addresses or PO Boxes.</p>
                            </div>
                        </div>

                        <div className="about-us-decor">
                            <div>
                                <h5>SHIPPING RESTRICTION</h5>
                                <p>Presently, Makkaj Perfumes online store is restricted to purchases and shipments within KSA and UAE. To explore the available offers and products in your respective country, kindly choose your preferred country.</p>
                                <p>Please note that if you intend to place an order in the UAE and request delivery to other countries, we regret to inform you that we cannot process such requests.</p>
                                <p>Due to stringent international trading agreements and regulations, fragrance shipments are limited to the UAE at the moment.</p>
                                <p>For orders in UAE, please ensure you select the country before placing the order.</p>
                            </div>
                        </div>

                        <div style={{ textAlign: 'center', marginTop: '10vh' }}>
                            <p className="contact_us" onClick={() => window.location.href = '/contact'}>For any further queries, please contact us</p>
                        </div>
                    </div>
                </div>
            : currentLocation === "KSA"
                ? <div className="aboutus-container">
                    <div className="aboutus-logo">
                        <hr />
                        <img src={logo} alt="" />
                        <hr />
                    </div>
                    <div className="aboutus-head">
                        <h1>الشحن</h1>
                    </div>
                    <div className="aboutus-container-box">
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text-rtl" style={{ color: '#c69736' }}>الشحن، الوجهات، الوزن، التكاليف وأوقات التسليم</p></h5>
                                <p className="text-rtl">
                                    أوقات التسليم تقديرية وتبدأ من تاريخ الشحن، وليس من تاريخ الطلب. يتم استخدام أوقات التسليم كدليل فقط وهي تخضع لقبول طلبك والموافقة عليه.
                                </p>
                                <p className="text-rtl">
                                    يرجى اختيار وجهة الشحن المفضلة بالنسبة لك لمعرفة خيارات الشحن، أوقات التسليم، تكاليف الشحن وجميع المعلومات الأخرى. يرجى أخذ العلم أن قيود الشحن المطبقة تعتمد على وجهة الشحن المختارة.
                                </p>
                            </div>
                        </div>

                        <div className="about-us-decor">
                            <div>
                                <h5>جهات الشحن</h5>
                                <p>المملكة العربية السعودية</p>
                                <table className="shipping-policy-table">
                                    <tr className="order-table-th">
                                        <th style={{ width: '30%' }}>خيارات الشحن لدولة المملكة العربية السعودية</th>
                                        <th style={{ width: '20%' }}>التكلفة</th>
                                        <th style={{ width: '50%' }}>معلومات الشحن</th>
                                    </tr>
                                    <tr>
                                        <td>أسفل قيمة سلة التسوق</td>
                                        <td>SAR. ٢٠٠.٠٠</td>
                                        <td>لقيمة الطلب أقل من ٢٠٠ درهم  استلم طلبك خلال  ٢-٣ أيام عمل</td>
                                    </tr>
                                    <tr>
                                        <td>Above Cart Value</td>
                                        <td>Free</td>
                                        <td>للسلات بقيمة أكثر من ٢٠٠ درهم. سوف يصل طلبك خلال ٢-٣ أيام عمل.</td>
                                    </tr>
                                </table>
                                <p>أيام العمل هي من الأحد إلى الخميس ، أيام العطل الرسمية في دولة المملكة العربية السعودية. يتم طلبها محلياً من دولة المملكة العربية السعودية.</p>
                                <p>برجاء ملاحظة: ستتم الاتصال بالطلب بعد ساعات العمل، في عطلة نهاية الأسبوع أو المصطافين الرسميين، في يوم العمل التالي ولاتسليم الطلبات إلى عناوين الشحن أو البريد.</p>
                            </div>
                        </div>

                        <div className="about-us-decor">
                            <div>
                                <h5>شروط الشحن</h5>
                                <p>- حاليًا متجر مكاج الإلكتروني متاح فقط للشراء والشحن داخل دولةالمملكة العربية السعودية والإمارات العربية المتحدة. يجب عليك اختيار الدولة المفضلة حتى تتمكن من الاستفادة من العروض والمنتجات المتوفرة في بلدك</p>
                                <p>يؤسفنا إبلاغك أنه إذا كنت ترغب في تقديم طلب في دولة المملكة العربية السعودية وطلب التوصيل في بلد آخر أو العكس، فلا يمكننا تلبية الطلب.</p>
                                <p>- نظرًا لاتفاقيات وأنظمة التجارة الدولية، لا يمكننا شحن العطور إلى دول أخرى باستثناء دولة المملكة العربية السعودية في الوقت الحالي، يمكننا شحن البضائع إلى دولة المملكة العربية السعودية فقط</p>
                            </div>
                        </div>

                        <div style={{ textAlign: 'center', marginTop: '10vh' }}>
                            <p className="contact_us" onClick={() => window.location.href = '/contact'}>لأية استفسارات أخرى، يرجى الاتصال بنا</p>
                        </div>
                    </div>
                </div>
                : <div className="aboutus-container">
                    <div className="aboutus-logo">
                        <hr />
                        <img src={logo} alt="" />
                        <hr />
                    </div>
                    <div className="aboutus-head">
                        <h1>الشحن</h1>
                    </div>
                    <div className="aboutus-container-box">
                        <div className="about-us-decor">
                            <div>
                                <h5>الشحن، الوجهات، الوزن، التكاليف وأوقات التسليم</h5>
                                <p>أوقات التسليم تقديرية وتبدأ من تاريخ الشحن، وليس من تاريخ الطلب. يتم استخدام أوقات التسليم كدليل فقط وهي تخضع لقبول طلبك والموافقة عليه.</p>
                                <p>يرجى اختيار وجهة الشحن المفضلة بالنسبة لك لمعرفة خيارات الشحن، أوقات التسليم، تكاليف الشحن وجميع المعلومات الأخرى. يرجى أخذ العلم أن قيود الشحن المطبقة تعتمد على وجهة الشحن المختارة.</p>
                            </div>
                        </div>

                        <div className="about-us-decor">
                            <div>
                                <h5>جهات الشحن</h5>
                                <p>الإمارات العربية المتحدة</p>
                                <table className="shipping-policy-table">
                                    <tr className="order-table-th">
                                        <th style={{ width: '30%' }}>خيارات الشحن لدولة الإمارات العربية المتحدة</th>
                                        <th style={{ width: '20%' }}>التكلفة</th>
                                        <th style={{ width: '50%' }}>معلومات الشحن</th>
                                    </tr>
                                    <tr>
                                        <td>أسفل قيمة سلة التسوق</td>
                                        <td>AED. ٢٠٠.٠٠</td>
                                        <td>لقيمة الطلب أقل من ٢٠٠ درهم  استلم طلبك خلال  ٢-٣ أيام عمل</td>
                                    </tr>

                                    <tr>
                                        <td>Above Cart Value</td>
                                        <td>Free</td>
                                        <td>للسلات بقيمة أكثر من ٢٠٠ درهم. سوف يصل طلبك خلال ٢-٣ أيام عمل.</td>
                                    </tr>
                                </table>
                                <p>أيام العمل هي من الاثنين إلى الجمعة، أيام العطل الرسمية في دولة الإمارات العربية المتحدة. يتم طلبها محلياً من دولة الإمارات العربية المتحدة.</p>
                                <p>برجاء ملاحظة: ستتم الاتصال بالطلب بعد ساعات العمل، في عطلة نهاية الأسبوع أو المصطافين الرسميين، في يوم العمل التالي ولاتسليم الطلبات إلى عناوين الشحن أو البريد.</p>
                            </div>
                        </div>

                        <div className="about-us-decor">
                            <div>
                                <h5>شروط الشحن</h5>
                                <p>- حاليًا متجر مكاج الإلكتروني متاح فقط للشراء والشحن داخل دولة المملكة العربية السعودية والإمارات العربية المتحدة. يجب عليك اختيار الدولة المفضلة حتى تتمكن من الاستفادة من العروض والمنتجات المتوفرة في بلدك</p>
                                <p>يؤسفنا إبلاغك أنه إذا كنت ترغب في تقديم طلب في دولة الإمارات العربية المتحدة وطلب التوصيل في بلد آخر أو العكس، فلا يمكننا تلبية الطلب.</p>
                                <p>- نظرًا لاتفاقيات وأنظمة التجارة الدولية، لا يمكننا شحن العطور إلى دول أخرى باستثناء دولة الإمارات العربية المتحدة في الوقت الحالي، يمكننا شحن البضائع إلى دولة الإمارات العربية المتحدة فقط</p>
                            </div>
                        </div>

                        <div style={{ textAlign: 'center', marginTop: '10vh' }}>
                            <p className="contact_us" onClick={() => window.location.href = '/contact'}>لأية استفسارات أخرى، يرجى الاتصال بنا</p>
                        </div>
                    </div>
                </div>
    )
}
