export const SHIPPING_ADDRESS_FAIL = "SHIPPING_ADDRESS_FAIL";
export const SHIPPING_ADDRESS_SUCCESS = "SHIPPING_ADDRESS_SUCCESS";
export const SHIPPING_ADDRESS_REQUEST = "SHIPPING_ADDRESS_REQUEST";

export const SHIPPING_ADDRESS_POST_SUCCESS = "SHIPPING_ADDRESS_POST_SUCCESS";
export const SHIPPING_ADDRESS_POST_FAIL = "SHIPPING_ADDRESS_POST_FAIL";
export const SHIPPING_ADDRESS_POST_REQUEST = "SHIPPING_ADDRESS_POST_REQUEST";

export const ORDER_ADDRESS_POST_REQUEST = "ORDER_ADDRESS_POST_REQUEST";
export const ORDER_ADDRESS_POST_SUCCESS = "ORDER_ADDRESS_POST_SUCCESS";
export const ORDER_ADDRESS_POST_FAIL = "ORDER_ADDRESS_POST_FAIL";


export const ORDER_ADDRESS_REQUEST = "ORDER_ADDRESS_REQUEST";
export const ORDER_ADDRESS_SUCCESS = "ORDER_ADDRESS_SUCCESS";
export const ORDER_ADDRESS_FAIL = "ORDER_ADDRESS_FAIL";

export const ORDER_SHIPPING_METHOD_REQUEST = "ORDER_SHIPPING_METHOD_REQUEST";
export const ORDER_SHIPPING_METHOD_SUCCESS = "ORDER_SHIPPING_METHOD_SUCCESS";
export const ORDER_SHIPPING_METHOD_FAIL = "ORDER_SHIPPING_METHOD_FAIL";


export const ORDER_POST_SUCCESS = "ORDER_POST_SUCCESS";
export const ORDER_POST_FAIL = "ORDER_POST_FAIL";

export const ORDER_GET_REQUEST = "ORDER_GET_REQUEST";
export const ORDER_GET_SUCCESS = "ORDER_GET_SUCCESS";
export const ORDER_GET_FAIL = "ORDER_GET_FAIL";

export const ORDER_CANCEL_REQUEST = "ORDER_CANCEL_REQUEST";
export const ORDER_CANCEL_SUCCESS = "ORDER_CANCEL_SUCCESS";
export const ORDER_CANCEL_FAIL = "ORDER_CANCEL_FAIL";

export const ALL_ORDER_REQUEST = "ALL_ORDER_REQUEST";
export const ALL_ORDER_SUCCESS = "ALL_ORDER_SUCCESS";
export const ALL_ORDER_FAIL = "ALL_ORDER_FAIL";

export const REVIEW_GET_REQUEST = "REVIEW_GET_REQUEST";
export const REVIEW_GET_SUCCESS = "REVIEW_GET_SUCCESS";
export const REVIEW_GET_FAIL = "REVIEW_GET_FAIL";


export const REVIEW_SET_REQUEST = "REVIEW_SET_REQUEST";
export const REVIEW_SET_SUCCESS = "REVIEW_SET_SUCCESS";
export const REVIEW_SET_FAIL = "REVIEW_SET_FAIL";


export const PRODUCT_STOCK = "PRODUCT_STOCK";

export const SINGLE_ORDER_DETAILS = "SINGLE_ORDER_DETAILS";
export const SINGLE_ORDER_DETAILS_FAIL = "SINGLE_ORDER_DETAILS_FAIL";