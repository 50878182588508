/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./Forhim.css";
import { useDispatch, useSelector } from "react-redux";
import { listProducts, listPrice } from "../../actions/productaction";
import Product from "../../components/product/product";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Translate } from "react-auto-translate";
import Loader from "../../components/loader/loader";

export const Forhim = () => {
    const { id } = useParams();
    let dispatch = useDispatch();
    const productList = useSelector((state) => state.productList);
    const prices = useSelector((state) => state.priceList);
    const [pricefiter, setpricefilter] = useState(1000000000000);
    const { products, loading } = productList;
    const { price } = prices;
    const [display, setdisplay] = useState("none");
    const [display1, setdisplay1] = useState("none");
    const [index, setindex] = useState(1000000000000);
    const [category, setcategory] = useState({});

    let filterProduct = products.filter(
        (item) => item.category.split(',').includes(id) && item.price[price] < pricefiter
    );

    useEffect(() => {
        if (window.wpwl !== undefined && window.wpwl.unload !== undefined) {
            window.wpwl.unload();
        }
        dispatch(listProducts());
        dispatch(listPrice());
    }, [dispatch]);
    const changeHandler = (e) => {
        setpricefilter(e.target.value);
    };

    useEffect(async () => {
        if (window.wpwl !== undefined && window.wpwl.unload !== undefined) {
            window.wpwl.unload();
        }
        const y = await axios.get(`${process.env.REACT_APP_API_URL}procategory/${id}`);
        setcategory(y.data[0]);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [dispatch, id])

    let defaultLanguage = localStorage.getItem('translate')

    return (
        <div>
            <div style={{ background: `url('${category.bannerImg}')`, backgroundRepeat: "no-repeat", width: "100%", backgroundSize: "100% 100%" }} className="forhim-top-section">
                <div className="forhim-inner-section">
                    <h1>{defaultLanguage === 'en' ? category.title : category.title_ar}</h1>
                    <p>
                        {
                            defaultLanguage === 'en'
                                ? category.des !== undefined && category.des
                                : category.des_ar !== undefined && category.des_ar
                        }
                    </p>
                </div>
            </div>

            {/* <div className="lower-menu" style={{ display: display }}>
                <ul >
                    <li >
                        <span class="dropdown">
                            <button class="dropbtn">
                                 Price

                            </button>
                            <span class="dropdown-content">
                                <a onClick={() => {
                                    setpricefilter(1000)
                                }}>&lt;1000</a>
                                <a onClick={() => {
                                    setpricefilter(2000)
                                }}>&lt;2000</a>

                            </span>
                        </span>
                    </li>
                </ul>
            </div>

            <div className="lower-menu" id="sort" style={{ display: display1 }}>
                <ul>
                    <li>
                        <input
                            type="radio"
                            name="price"
                            id="price1"
                            onClick={() => {
                                setpricefilter(1000);
                            }}
                            onChange={changeHandler}
                            value={1000}
                        />
                        <label for="price1">LESS THAN ₹1000 </label>{" "}
                    </li>
                    <li>
                        <input
                            type="radio"
                            name="price"
                            id="price2"
                            onClick={() => {
                                setpricefilter(1500);
                            }}
                            onChange={changeHandler}
                            value={1500}
                        />
                        <label for="price2">LESS THAN ₹1500 </label>{" "}
                    </li>
                    <li>
                        <input
                            type="radio"
                            name="price"
                            onClick={() => {
                                setpricefilter(2000);
                            }}
                            id="price3"
                            onChange={changeHandler}
                            value={2000}
                        />
                        <label for="price3">LESS THAN ₹2000 </label>{" "}
                    </li>
                </ul>
            </div> */}

            {loading ? (
                <Loader text={id} />
            ) : (
                <div class="products-category">
                    {
                        filterProduct.length < 1
                            ? <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                width: '100%',
                                marginTop: '5%',
                                padding: '5%'
                            }}>
                                <p>No product found at the moment</p>
                            </div>
                            : filterProduct.map((product, indexes) => {
                                if (indexes < index) {
                                    let prod_currency = localStorage.getItem("currency")

                                    if (product.price[prod_currency] === 0 || product.price[prod_currency] === null || product.price[prod_currency] === undefined || product.price[prod_currency] === '') {
                                        console.log('un-available')
                                    } else {
                                        return (
                                            <Product
                                                price={price}
                                                key={product._id}
                                                product={product}
                                            ></Product>
                                        );
                                    }
                                }
                            })
                    }
                </div>
            )}
        </div>
    );
};
