/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./checkout.css";
import { Link } from "react-router-dom";
import { Translate } from "react-auto-translate";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";
import { subtotal, subtotalget } from "../../actions/cartactions";

import { getCurrencySymbol, defaultLanguage, getShippingCharge } from '../../helpers';

const Checkoutbox = (props) => {
    const dispatch = useDispatch();

    const [refresh, setRefresh] = useState(0)

    const prices = useSelector((state) => state.priceList);
    const { price } = prices;
    const subtotals = useSelector((state) => state.subtotal);
    const { sub } = subtotals;
    const taxes = useSelector((state) => state.gettax);
    const { tax } = taxes;

    const [shippingCosts, setShippingCosts] = useState('calculating shipping...')

    useEffect(async () => {
        console.log(sub)
        const shippingCharge = await getShippingCharge(0)
        console.log('shipping', shippingCharge)
        setShippingCosts(shippingCharge)
    }, [refresh]);

    let defaultLanguage = localStorage.getItem('translate')

    return (
        <>
            <div className="checkout-box">
                <div className="checkout-box1">
                    <div className="checkout-summary-head">
                        <h1>{defaultLanguage === 'en' ? 'Summary' : 'الملخص'}</h1>
                    </div>
                    <div className="checkout-summary">
                        <summary>{defaultLanguage === 'en' ? 'Estimate Shipping and Tax' : 'الشحن والضرائب المقدرة'}</summary>
                    </div>
                </div>

                <div className="checkout-box2">
                    <div className="subtotal">
                        <div>{defaultLanguage === 'en' ? 'Subtotal' : 'مبلغ المجموع '}</div>
                        <div>
                            {
                                localStorage.getItem("translate") === "en"
                                    ? localStorage.getItem("currency_code")
                                    : localStorage.getItem("currency_icon")
                            }
                            {" "} {sub}
                        </div>
                    </div>

                    <div className="subtotal">
                        <div>{defaultLanguage === 'en' ? 'VAT' : 'الضريبة المقدرة'}</div>
                        <div>
                            {
                                localStorage.getItem("translate") === "en"
                                    ? localStorage.getItem("currency_code")
                                    : localStorage.getItem("currency_icon")
                            }
                            {" "}
                            {(sub * tax[price]) / 100}
                        </div>
                    </div>

                    <div className="shipping">
                        <div>{defaultLanguage === 'en' ? 'Shipping Charges' : 'رسوم الشحن'}</div>
                        <div>
                            {
                                localStorage.getItem("translate") === "en"
                                    ? localStorage.getItem("currency_code")
                                    : localStorage.getItem("currency_icon")
                            }
                            {" "}
                            {
                                localStorage.getItem("country_code_iso3") === "KSA"
                                    ? sub <= 0 ? '0' : sub > 200
                                        ? "0 (Free)"
                                        : shippingCosts
                                    : localStorage.getItem("country_code_iso3") === "UAE"
                                        ? sub <= 0 ? '0' : sub > 200
                                            ? "0 (Free)"
                                            : shippingCosts
                                        : localStorage.getItem("country_code_iso3") === "IND"
                                            ? sub <= 0 ? '0' : sub > 4500
                                                ? "0 (Free)"
                                                : shippingCosts
                                            : ""
                            }
                        </div>
                    </div>


                    <div className="ordertotal">
                        <div>{defaultLanguage === 'en' ? 'Order Total' : 'الطلب الاجمالي'}</div>
                        <div>
                            {
                                sub > 0
                                    ? <>
                                        {
                                            localStorage.getItem("translate") === "en"
                                                ? localStorage.getItem("currency_code")
                                                : localStorage.getItem("currency_icon")
                                        }
                                        {" "}
                                        {
                                            parseFloat((sub + (sub * tax[price]) / 100)) +
                                            parseFloat(
                                                localStorage.getItem("country_code_iso3") === "KSA"
                                                    ? sub <= 0 ? '0' : sub > 200
                                                        ? 0
                                                        : shippingCosts
                                                    : localStorage.getItem("country_code_iso3") === "UAE"
                                                        ? sub <= 0 ? '0' : sub > 200
                                                            ? 0
                                                            : shippingCosts
                                                        : localStorage.getItem("country_code_iso3") === "IND"
                                                            ? sub <= 0 ? '0' : sub > 4500
                                                                ? 0
                                                                : shippingCosts
                                                            : 0
                                            )
                                        }
                                    </>
                                    : 0
                            }

                        </div>
                    </div>
                </div>

                <div className="checkout-box3">
                    {
                        sub > 0
                            ? <Link to={props.item <= 0 ? '/cart' : "/checkout"}>
                                <button
                                    onClick={() => {
                                        localStorage.setItem("total", sub + (sub * tax[price]) / 100);
                                        localStorage.setItem("tax", (sub * tax[price]) / 100)
                                        localStorage.setItem("shipping", sub > 200 ? 0 : shippingCosts !== undefined ? shippingCosts : 0)
                                    }}
                                >
                                    {defaultLanguage === 'en' ? 'Proceed to Checkout' : 'التحقق من ذلك'}
                                </button>
                            </Link>
                            : null
                    }
                </div>
            </div>
        </>
    );
};

export default Checkoutbox;
