import axios from 'axios';

export let defaultLanguage = localStorage.getItem('translate')

export const getCurrencySymbol = () => defaultLanguage === "en"
    ? localStorage.getItem("currency_code")
    : localStorage.getItem("currency_icon")

export const getCurrency = (curr) => {
    if (curr === "SAR") {
        if (defaultLanguage === "en") {
            return 'SAR'
        } else {
            return 'ر.س'
        }
    } else if (curr === "AED") {
        if (defaultLanguage === "en") {
            return 'AED'
        } else {
            return 'د.إ'
        }
    }
}

export const formatDateTime = (date) => {
    let dts = new Date(date)

    let country = localStorage.getItem('country_code_iso3')

    const options = {
        timeZone: country === 'KSA' ? 'Asia/Riyadh' : 'Asia/Dubai',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
    };

    const formattedDate = dts.toLocaleString('en-US', options);

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    let day = formattedDate.split(', ')[0].split('/')[1]
    let mon = formattedDate.split(', ')[0].split('/')[0]
    let year = formattedDate.split(', ')[0].split('/')[2]
    let time = formattedDate.split(', ')[1]

    return `${day}/${mon}/${year} ${time}`
}

export const getShippingCharge = (codAmt) => new Promise(resolve => {
    let myLocation = localStorage.getItem('country_code_iso3')

    let shipCity = myLocation === "KSA" ? 'KSA' : myLocation === "UAE" ? 'UAE' : ''

    let url = `${process.env.REACT_APP_API_URL}shipping`

    let reqData = {
        "shipCity": shipCity,
        "shipCntry": "",
        "destCity": shipCity,
        "destCntry": "",
        "shipType": "DLV",
        "codAmt": codAmt,
        "weight": "1"
    }

    console.log({ reqData })

    axios.post(url, reqData)
        .then(response => {
            localStorage.setItem('shipping', response.data.ShipCharges)
            resolve(response.data.ShipCharges)
        })
        .catch(error => {
            console.error('Error:', error);
        });
})

export const clearCart = () => new Promise(resolve => {
    let uid = JSON.parse(localStorage.getItem("login")) !== true
        ? { userId: localStorage.getItem("guestid") }
        : { userId: JSON.parse(localStorage.getItem("userInfo")).user }

    let resp = axios.post(`${process.env.REACT_APP_API_URL}clearcart`, uid)

    let cn = 0

    while (resp.status === 200 || cn < 100) {
        cn = cn + 1
        resolve(resp)
        break;
    }
})