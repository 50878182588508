import { useDispatch, useSelector } from "react-redux";
import Product from "../../components/product/product";
import { listProducts, listPrice } from "../../actions/productaction";
import { useEffect } from "react";
import Loader from "../../components/loader/loader";
import "./Products.css";
import { Link } from "react-router-dom";
import { getwishlist } from "../../actions/wishlistaction";

export default function Products() {
    let dispatch = useDispatch();
    const productList = useSelector((state) => state.productList);
    const prices = useSelector((state) => state.priceList);

    const { products, loading } = productList;
    const { price } = prices;

    const wishlists = useSelector((state) => state.wishlist);
    const { wishlist } = wishlists;

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("login")) === true) {
            dispatch(getwishlist(JSON.parse(localStorage.getItem("userInfo")).user));
        }

        if (window.wpwl !== undefined && window.wpwl.unload !== undefined) {
            window.wpwl.unload();
        }

        dispatch(listProducts());
        dispatch(listPrice());
    }, [dispatch]);

    console.log({ products: products.length })

    return (
        <div className="row-center" style={{
            fontFamily:
                localStorage.getItem("translate") === "ar" &&
                localStorage.getItem("translate") === null &&
                "Times New Roman",
        }}>
            {loading ? (
                <Loader text='products' />
            ) : (
                <>
                    {
                        products.map((product) => {
                            let prod_currency = localStorage.getItem("currency")

                            if (product.price[prod_currency] === 0 || product.price[prod_currency] === null || product.price[prod_currency] === undefined || product.price[prod_currency] === '') {
                                console.log('un available')
                            } else {
                                const productIdToFind = product._id;
                                const isProductAvailable = wishlist.some(wishlist => wishlist.productId === productIdToFind);

                                console.log({ isProductAvailable })

                                return (
                                    <Product
                                        price={price}
                                        key={product._id}
                                        product={product}
                                        inWishlist={isProductAvailable !== false ? true : false}
                                    ></Product>
                                )
                            }
                        })
                    }
                </>
            )}

            {/* */}
        </div>
    );
}
