import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import './test.css'
import { clearCart } from '../../../helpers';

class CheckoutPage extends Component {
    state = {
        checkoutId: null,
        loading: true
    }

    componentDidMount() {
        const { location } = this.props;
        const type = new URLSearchParams(location.search).get('type');

        let amt = localStorage.getItem("country_code_iso3") === "KSA"
            ? parseFloat(localStorage.getItem('total')) - parseFloat(localStorage.getItem('tax')) <= 0
                ? 0
                : parseFloat(localStorage.getItem('total')) - parseFloat(localStorage.getItem('tax')) > 200
                    ? parseFloat(localStorage.getItem('total'))
                    : parseFloat(localStorage.getItem('shipping')) + parseFloat(localStorage.getItem('total'))
            : localStorage.getItem("country_code_iso3") === "UAE"
                ? parseFloat(localStorage.getItem('total')) - parseFloat(localStorage.getItem('tax')) <= 0
                    ? 0
                    : parseFloat(localStorage.getItem('total')) - parseFloat(localStorage.getItem('tax')) > 200
                        ? parseFloat(localStorage.getItem('total'))
                        : parseFloat(localStorage.getItem('shipping')) + parseFloat(localStorage.getItem('total'))
                : parseFloat(localStorage.getItem('total')) - parseFloat(localStorage.getItem('tax')) <= 0
                    ? 0
                    : parseFloat(localStorage.getItem('total')) - parseFloat(localStorage.getItem('tax')) > 200
                        ? parseFloat(localStorage.getItem('total'))
                        : parseFloat(localStorage.getItem('shipping')) + parseFloat(localStorage.getItem('total'))

        console.log({
            amt: amt,
            crr: localStorage.getItem('curr'),
            address: JSON.parse(localStorage.getItem("address")),
        })

        axios.post(`${process.env.REACT_APP_API_URL}checkout`, {
            type: type,
            amt: amt,
            crr: localStorage.getItem('curr'),
            address: JSON.parse(localStorage.getItem("address")),
        }).then(res => {
            console.log(res.data)
            this.setState({
                checkoutId: res.data.id,
                loading: false
            })
        })
    }

    renderPaymentform = () => {
        const { location } = this.props;
        const type = new URLSearchParams(location.search).get('type');

        const script = document.createElement("script");

        script.src = `${process.env.REACT_APP_HYPERPAY_PAYMENT_WIDGET}?checkoutId=${this.state.checkoutId}`;
        script.async = true;

        document.body.appendChild(script);

        const form = document.createElement("form")
        form.action = process.env.REACT_APP_WEBSITE + "result?type=" + type;
        form.setAttribute("class", "paymentWidgets");
        form.setAttribute("data-brands", type.replace('_', ' '))
        form.setAttribute("labels", "cancel")
        document.getElementById('root').insertBefore(form, document.getElementById('foot'));
    }

    unloadWidget = () => {
        if (window.wpwl !== undefined && window.wpwl.unload !== undefined) {
            window.wpwl.unload();
        }
    };

    clearCart = async () => {
        // const resData = await clearCart()
        // console.log(resData)
        window.location.href = '/cart'
    }

    defaultLanguage = localStorage.getItem('translate')

    render() {
        if (this.state.loading === false) {
            return (
                <>
                    <div className="oopwa-ctr">
                        {this.renderPaymentform()}
                    </div>

                    <div className='cancel_ctr'>
                        <button
                            type="submit"
                            className='cancel-btn-oopwa'
                            style={{ background: '#c69736' }}
                            onClick={() => {
                                this.clearCart();
                            }}
                        >
                            {this.defaultLanguage === 'en' ? 'Cancel Payment' : 'الغاء الدفع'}
                        </button>
                    </div>
                </>
            );
        } else {
            return (
                <div> Still Loading</div>
            )
        }
    }
}

export default CheckoutPage;
