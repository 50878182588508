import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { details, listProducts } from "../../actions/productaction";
import "./Productdetails.css";
import { listPrice } from "../../actions/productaction";
import getSymbolFromCurrency from "currency-symbol-map";
import { addproductcart } from "../../actions/cartactions";
import Product from "../../components/product/product";
import { Relatedproducts } from "../../components/Relatedproducts/Relatedproducts";
import $, { map } from "jquery";
import Review from "../../components/review/review";
import { LOGIN_DISPLAY_FLEX } from "../../constant/user";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Translate } from "react-auto-translate";
import { createRef } from "react";
import { useHistory } from "react-router";


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


export const Productdetails = () => {
    const history = useHistory();
    const { productid } = useParams();
    const dispatch = useDispatch();
    const prices = useSelector((state) => state.priceList);
    const { price } = prices;
    const detailes = useSelector((state) => state.detail);
    const [innerhtml, setinnerhtml] = useState("");
    const productList = useSelector((state) => state.productList);
    const { products, loading } = productList;
    const [method, setmethod] = useState("");
    const [active, setactive] = useState(1);
    const [size, setsize] = useState("");
    const [quant, setquant] = useState("1");

    let [count, setCount] = useState(0)

    const y = {
        background: "#c69736",
        color: "#fff",
        margin: "0",
        fontWeight: "500",
    };
    const { detail } = detailes;

    useEffect(() => {
        dispatch(listPrice());
        dispatch(details(productid));
        dispatch(listProducts());
        console.log(detail)
    }, [count]);

    useEffect(() => {
        const handleUrlChange = () => {
            setCount(count + 1)
            window.location.reload();
        };

        const unlisten = history.listen(handleUrlChange);

        return () => {
            unlisten();
        };
    }, [history]);

    const myref = createRef();

    const changSelect = (e) => {
        setsize(e.target.value);
    };
    const changSelect2 = (e) => {
        setquant(e.target.value);
    };
    // get the value of checked input radio and display as dropp title

    let defaultLanguage = localStorage.getItem('translate')

    return detail != null ? (
        <div style={{
            fontFamily:
                localStorage.getItem("translate") == "ar" &&
                localStorage.getItem("translate") == null &&
                "Times New Roman",
        }}>
            {/* <div className="breadcrumbs"></div> */}
            {console.log(detail)}
            <div className="detail-section">
                <div style={{ flexDirection: "column" }} className="detail-img-left">
                    {detail.image.map((item, index) => {
                        return <img onClick={() => {
                            document.getElementById('detail-cr').scrollTo(0, ((getWindowDimensions().height * 75) / 100) * index);
                        }} src={item} alt="perfume" style={{ height: "100px", width: "100px", border: "1px solid #c69736", cursor: "pointer" }}></img>;
                    })}
                </div>
                <div id="detail-cr" className="detail-section-image detail-img1">
                    {detail.image.map((item) => {
                        return <img src={item} alt="perfume"></img>;
                    })}
                </div>
                <div className="detail-section-image detail-img2" style={{ height: '280px' }}>
                    <OwlCarousel
                        style={{ width: "100%", zIndex: "auto" }}
                        className="owl-theme"
                        dots={true}
                        navText={[
                            "<div className='nav-btn prev-slides'><i class='fa fa-chevron-left'></i></div>",
                            "<div className='nav-btn next-slides'><i class='fa fa-chevron-right'></i></div>",
                        ]}
                        lazyLoad={true}
                        autoplay={true}
                        items={1}
                        loop
                        margin={0}
                        nav
                    >
                        {detail.image.map((item) => {
                            return <img src={item} alt="perfume"></img>;
                        })}
                    </OwlCarousel>
                </div>
                <div className="detail-section-text">
                    <div className="detail-section-inner">
                        <h3 style={{ margin: "0" }}> {localStorage.getItem("translate") == "en" ? detail.title : detail.arabictitle}</h3>
                        {/* <h4 style={{ color: "#c69736" }}>{localStorage.getItem("translate") == "en" ? detail.short : detail.shortaed}</h4> */}

                        <div style={{ color: "#c69736" }}>
                            {
                                detail.sd_type === "short_description"
                                    ? <div className="text-start" style={{ color: "#c69736" }}>{localStorage.getItem("translate") == "en" ? detail.shortNote : detail.shortNoteAed}</div>
                                    : <table className="min-w-full divide-y divide-gray-300">
                                        <tbody className="divide-y divide-gray-200">
                                            <tr>
                                                <td className="whitespace-nowrap text-sm font-medium text-gray-100">{localStorage.getItem("translate") == "en" ? "Top Note" : "الرائحة المبدئية"}:</td>
                                                <td className="relative whitespace-nowrap text-sm font-medium">
                                                    <div className="text-start" style={{ color: "#c69736" }}>{localStorage.getItem("translate") == "en" ? detail.topNote : detail.topNoteAed}</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="whitespace-nowrap text-sm font-medium text-gray-900">{localStorage.getItem("translate") == "en" ? "Base Note" : "الرائحة المركزية"}:</td>
                                                <td className="relative whitespace-nowrap text-sm font-medium">
                                                    <div className="text-start" style={{ color: "#c69736" }}>{localStorage.getItem("translate") == "en" ? detail.baseNote : detail.baseNoteAed}</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="whitespace-nowrap text-sm font-medium text-gray-900">{localStorage.getItem("translate") == "en" ? "Heart Note" : "الرائحة الأساسية"}:</td>
                                                <td className="relative whitespace-nowrap text-sm font-medium">
                                                    <div className="text-start" style={{ color: "#c69736" }}>{localStorage.getItem("translate") == "en" ? detail.heartNote : detail.heartNoteAed}</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                            }
                        </div>

                        <p>{localStorage.getItem("translate") == "en" ? detail.description : detail.arabicdescription}</p>
                        <p className="main-price">
                            {detail.discountprice != undefined ||
                                detail.discountprice != null ? (
                                <><span className="mrp-text">Price:  </span><span
                                    className="mrp"
                                    style={{
                                        textDecoration: "line-through",
                                        padding: "0.5rem",
                                        paddingLeft: "0",
                                        display: detail.discountprice[price] == detail.price[price]
                                            ? "none"
                                            : "inline",
                                    }}
                                >
                                    {
                                        localStorage.getItem("translate") === "en"
                                            ? localStorage.getItem("currency_code")
                                            : localStorage.getItem("currency_icon")
                                    }
                                    {" "}
                                    {detail.price[price]}{" "}
                                </span></>
                            ) : (
                                <span></span>
                            )}
                            <span className="offer-price-text">
                                {
                                    defaultLanguage === 'en'
                                        ? "Price "
                                        : "السعر "
                                }
                            </span>
                            <span className="offer-price">
                                {
                                    localStorage.getItem("translate") === "en"
                                        ? localStorage.getItem("currency_code")
                                        : localStorage.getItem("currency_icon")
                                }
                                {" "}
                                {detail.discountprice != undefined ||
                                    detail.discountprice != null
                                    ? detail.discountprice[price]
                                    : ""}
                            </span>

                            {/* (<span className="tax-text">
                                {
                                    defaultLanguage === 'en'
                                        ? 'Incl. of all taxes'
                                        : 'بما في ذلك. من جميع الضرائب'
                                }
                            </span>) */}
                        </p>
                        <div class="detail-page-customize">
                            <div className="qty-div">
                                <p className="qty-text">
                                    {
                                        defaultLanguage === 'en'
                                            ? "QTY"
                                            : "الكمية"
                                    }
                                </p>
                                <select
                                    className="select-qty"
                                    name="size"
                                    onChange={changSelect2}
                                    value={quant}
                                >
                                    <option name="size" value="1">
                                        1
                                    </option>
                                    <option name="size" value="2">
                                        2
                                    </option>
                                    <option name="size" value="3">
                                        3
                                    </option>
                                    <option name="size" value="4">
                                        4
                                    </option>
                                    <option name="size" value="5">
                                        5
                                    </option>
                                    <option name="size" value="6">
                                        6
                                    </option>
                                    <option name="size" value="7">
                                        7
                                    </option>
                                    <option name="size" value="8">
                                        8
                                    </option>
                                    <option name="size" value="9">
                                        9
                                    </option>
                                    <option name="size" value="10">
                                        10
                                    </option>
                                </select>
                            </div>
                            <div className="size-div">
                                <p style={{ color: "#c69736", fontSize: "1.4em" }}>
                                    {
                                        defaultLanguage === 'en'
                                            ? "Size"
                                            : "المقاس"
                                    } : {detail.size[0].value}  ML
                                </p>
                            </div>
                        </div>
                        <div className="detail-page-button">
                            {
                                detail.stock[localStorage.getItem('currency')] <= 0
                                    ? <div>Out of stock</div>
                                    : JSON.parse(localStorage.getItem("login")) === true
                                        ? <button type="submit" onClick={() => {
                                            if (quant !== "") {
                                                dispatch(
                                                    addproductcart(
                                                        detail.title,
                                                        detail.arabictitle,
                                                        detail.image[0],
                                                        detail.description,
                                                        detail.price[price],
                                                        detail.size[0].value,
                                                        detail.dimensions,
                                                        Number(quant) > Number(detail.stock[localStorage.getItem('currency')]) ? detail.stock[localStorage.getItem('currency')] : quant,
                                                        detail._id,
                                                        JSON.parse(localStorage.getItem("userInfo")).user,
                                                        detail.discountprice[price],
                                                        price
                                                    )
                                                );

                                                // window.alert(defaultLanguage === 'en' ? "Product is added to cart" : 'يضاف المنتج الى سلة التسويق')
                                                setTimeout(() => {
                                                    window.location.href = "/cart";
                                                }, 2000)
                                            } else {
                                                window.alert("please add quantity");
                                            }
                                        }}
                                        >
                                            {defaultLanguage === 'en' ? 'ADD TO CART' : 'اضف الى السلة'}
                                        </button>
                                        : JSON.parse(localStorage.getItem("login")) !== true
                                            ? <button
                                                type="submit"
                                                onClick={() => {
                                                    if (!localStorage.getItem("guestid")) {
                                                        let y = makeid(24);
                                                        localStorage.setItem("guestid", y);
                                                    }

                                                    if (quant !== "") {
                                                        dispatch(
                                                            addproductcart(
                                                                detail.title,
                                                                detail.arabictitle,
                                                                detail.image[0],
                                                                detail.description,
                                                                detail.price[price],
                                                                detail.size[0].value,
                                                                detail.dimensions,
                                                                Number(quant) > Number(detail.stock[localStorage.getItem('currency')]) ? detail.stock[localStorage.getItem('currency')] : quant,
                                                                detail._id,
                                                                localStorage.getItem("guestid"),
                                                                detail.discountprice[price],
                                                                price
                                                            )
                                                        );

                                                        // window.alert(defaultLanguage === 'en' ? "Product is added to cart" : 'يضاف المنتج الى سلة التسويق')
                                                        setTimeout(() => {
                                                            window.location.href = "/cart";
                                                        }, 2000)
                                                    } else {
                                                        window.alert("please add quantity");
                                                    }
                                                }}
                                            >
                                                {defaultLanguage === 'en' ? 'ADD TO CART' : 'اضف الى السلة'}
                                            </button>
                                            : <div>Out of stock</div>
                            }
                        </div>
                        {/* <Review id={productid} /> */}
                    </div>
                </div>
            </div>

            <div className="detail-info">
                <h2
                    style={active == 1 ? y : {}}
                    onClick={() => {
                        setactive(1);
                    }}
                >
                    {
                        defaultLanguage === 'en'
                            ? 'DESCRIPTION'
                            : 'الوصف'
                    }
                </h2>
                <h2
                    style={active == 2 ? y : {}}
                    onClick={() => {
                        setactive(2);
                    }}
                >
                    {
                        defaultLanguage === 'en'
                            ? 'SHIPPING POLICY'
                            : 'سياسة الشحن'
                    }
                </h2>
                <h2
                    style={active == 3 ? y : {}}
                    onClick={() => {
                        setactive(3);
                    }}
                >
                    {
                        defaultLanguage === 'en'
                            ? 'CUSTOMER REVIEWS'
                            : 'رأي العميل'
                    }
                </h2>
                <h2
                    style={active == 4 ? y : {}}
                    onClick={() => {
                        setactive(4);
                    }}
                >
                    FAQ's
                </h2>
            </div>
            <div className="details-desc" id="details">
                {active === 1 ? (
                    defaultLanguage === 'en' ? detail.description : detail.arabicdescription
                ) : active === 2 ? (
                    defaultLanguage === 'en'
                        ? <p>To know more about our shipping policy <span className="highlight-text" style={{ cursor: 'pointer' }} onClick={() => window.location.href = '/shipping-policy'}>click here </span></p>
                        : <p className="highlight-text" style={{ cursor: 'pointer' }} onClick={() => window.location.href = '/shipping-policy'}>لمعرفة المزيد عن سياسة الشحن لدينا انقر هنا.</p>
                ) : active === 3 ? (
                    <Review id={detail._id} />
                ) : (
                    ""
                )}
            </div>
            <div className="related-products">
                <div className="just-border-2"></div>
                <div>
                    {
                        defaultLanguage === 'en'
                            ? <h1>RELATED PRODUCTS</h1>
                            : <h1>المنتجات ذات الصلة</h1>
                    }

                </div>
                <div className="just-border-3"></div>
            </div>
            <div
                style={{ height: "auto", justifyContent: "center", paddingTop: "1rem" }}
            >
                <Relatedproducts />
            </div>
        </div>
    ) : (
        <div></div>
    );
};


function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    result.toString();
    return result;
}