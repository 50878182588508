/* eslint-disable react-hooks/exhaustive-deps */

import getSymbolFromCurrency from 'currency-symbol-map';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cartlist, gettax, subtotalget } from '../../actions/cartactions';
import { listPrice } from '../../actions/productaction';
import { getcoupon, subtotal } from '../../actions/cartactions';
import { Translate } from "react-auto-translate";
import { getCurrencySymbol, defaultLanguage } from '../../helpers';
import axios from 'axios';

export const Rightinfo = ({ shippingCost }) => {
    const dispatch = useDispatch();

    const cartList = useSelector(state => state.addCart);
    const prices = useSelector(state => state.priceList);
    const subtotals = useSelector(state => state.subtotal);
    const [code, setcode] = useState('');
    const [disable, setdisable] = useState(false);
    const coupons = useSelector(state => state.getcoupon);
    const { coupon } = coupons;

    const { sub } = subtotals;
    const { price } = prices;

    const { cart, loading } = cartList;

    const [shippingCosts, setShippingCosts] = useState()

    const [codMessage, setCodMessage] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('shipping') === null) {
            setShippingCosts(shippingCost)
        } else {
            setShippingCosts(localStorage.getItem('shipping'));
        }
    }, [shippingCost]);

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("login")) == true) {
            dispatch(cartlist(JSON.parse(localStorage.getItem("userInfo")).user));
        }
        else {

            dispatch(cartlist(localStorage.getItem("guestid")));
        }

        dispatch(listPrice());
    }, [dispatch]);

    useEffect(() => {
        if (coupon != null) {
            localStorage.setItem('coupon', JSON.stringify(coupon));
        }
    }, [coupon]);

    const changeHandler = e => {
        setcode(e.target.value);
    };

    useEffect(() => {
        if (localStorage.getItem('apply') == 'false') {
            setdisable(false);
        } else {
            setdisable(true);
        }

        const currentUrl = window.location.href;
        const urlObject = new URL(currentUrl);

        console.log(urlObject.pathname)

        if (urlObject.pathname === '/payment') {
            setCodMessage(true)
        }
    }, []);


    useEffect(() => {
        if (coupon != null && disable !== true) {
            if (coupon.type === 'PERCENTAGE') {
                dispatch(subtotal(0, (parseInt(localStorage.getItem('total')) * coupon.weightage) / 100));
            } else {
                dispatch(subtotal(0, parseInt(localStorage.getItem('total')) - coupon.weightage));
            }
            localStorage.setItem('apply', true);
            setdisable(localStorage.getItem('apply'));
        }
    }, [coupon]);

    useEffect(() => {
        if (loading === false) {
            if (cart.length <= 0) {
                window.location.href = '/'
            }
        }
    }, [cart])


    return (
        <div className="right-info-2">
            <div className="inner-section-info">
                <div className="first-right-div">
                    {cart.map(item => {
                        return (
                            <div className="rightsideinfo-div">
                                <img className="item-img" src={item.image} alt="perfume" />
                                <span className="item-number">{item.count}</span>
                                <p className="item-name">
                                    {
                                        defaultLanguage === "en"
                                            ? item.title
                                            : item.arabictitle
                                    }
                                </p>
                                <p className="item-price">
                                    {
                                        defaultLanguage === "en"
                                            ? localStorage.getItem("currency_code")
                                            : localStorage.getItem("currency_icon")
                                    }
                                    {" "}
                                    {item.discountprice}
                                </p>
                            </div>
                        );
                    })}
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap'
                    }}
                >
                    <p style={{ width: '60%' }}>{defaultLanguage === 'en' ? 'Subtotal' : 'مبلغ المجموع '}</p>
                    <p style={{}}>
                        {
                            defaultLanguage === "en"
                                ? localStorage.getItem("currency_code")
                                : localStorage.getItem("currency_icon")
                        } {" "}
                        {parseFloat(localStorage.getItem('total')) - parseFloat(localStorage.getItem('tax'))}
                    </p>

                    <p style={{ width: '60%' }}>{defaultLanguage === 'en' ? 'VAT' : 'الضريبة المقدرة'}</p>
                    <p style={{}}>
                        {
                            defaultLanguage === "en"
                                ? localStorage.getItem("currency_code")
                                : localStorage.getItem("currency_icon")
                        } {" "}
                        {parseFloat(localStorage.getItem('tax'))}
                    </p>

                    <p style={{ width: '60%' }}>{defaultLanguage === 'en' ? 'Shipping' : 'رسوم الشحن'}</p>
                    <p style={{}}>
                        {
                            defaultLanguage === "en"
                                ? localStorage.getItem("currency_code")
                                : localStorage.getItem("currency_icon")
                        } {" "}
                        {
                            localStorage.getItem("country_code_iso3") === "KSA"
                                ? localStorage.getItem('total') - localStorage.getItem('tax') <= 0 ? '0' : localStorage.getItem('total') - localStorage.getItem('tax') > 200
                                    ? "0 (Free)"
                                    : shippingCosts
                                : localStorage.getItem("country_code_iso3") === "UAE"
                                    ? localStorage.getItem('total') - localStorage.getItem('tax') <= 0 ? '0' : localStorage.getItem('total') - localStorage.getItem('tax') > 200
                                        ? "0 (Free)"
                                        : shippingCosts
                                    : localStorage.getItem("country_code_iso3") === "IND"
                                        ? localStorage.getItem('total') - localStorage.getItem('tax') <= 0 ? '0' : localStorage.getItem('total') - localStorage.getItem('tax') > 4500
                                            ? "0 (Free)"
                                            : shippingCosts
                                        : ""
                        }
                    </p>
                </div>

                <div className="total-box">
                    <p className="rightsideinfo-total">
                        {
                            codMessage === true
                                ? <><span style={{ fontSize: '13px', color: 'red', verticalAlign: 'super' }}>*</span>{defaultLanguage === 'en' ? 'Total' : 'المبلغ الاجمالي'}</>
                                : <>{defaultLanguage === 'en' ? 'Total' : 'المبلغ الاجمالي'}</>
                        }
                    </p>
                    <p className="rightsideinfo-totalprice" style={{ fontWeight: 'bolder' }}>
                        {
                            defaultLanguage === "en"
                                ? localStorage.getItem("currency_code")
                                : localStorage.getItem("currency_icon")
                        }
                        {" "}
                        {
                            parseFloat(localStorage.getItem('total')) + parseFloat(
                                localStorage.getItem("country_code_iso3") === "KSA"
                                    ? localStorage.getItem('total') - localStorage.getItem('tax') <= 0 ? '0' : localStorage.getItem('total') - localStorage.getItem('tax') > 200
                                        ? 0
                                        : shippingCosts
                                    : localStorage.getItem("country_code_iso3") === "UAE"
                                        ? localStorage.getItem('total') - localStorage.getItem('tax') <= 0 ? '0' : localStorage.getItem('total') - localStorage.getItem('tax') > 200
                                            ? 0
                                            : shippingCosts
                                        : localStorage.getItem("country_code_iso3") === "IND"
                                            ? localStorage.getItem('total') - localStorage.getItem('tax') <= 0 ? '0' : localStorage.getItem('total') - localStorage.getItem('tax') > 4500
                                                ? 0
                                                : shippingCosts
                                            : ""
                            )
                        }
                    </p>
                </div>

                {
                    codMessage === true
                        ? <div style={{
                            textAlign: 'left',
                            fontSize: '16px',
                            border: '0px'
                        }}>
                            {
                                defaultLanguage === 'en'
                                    ? localStorage.getItem("country_code_iso3") === "KSA"
                                        ? <><span style={{ fontSize: '13px', color: 'red', verticalAlign: 'super' }}>*</span>An additional fee of 15 {getCurrencySymbol()} for the order amount of up to SAR 1000 , {getCurrencySymbol()} 20 up to SAR 3750, and 1% of order amount above {getCurrencySymbol()} 3750 will be applied for payments made using a card upon the time of delivery.</>
                                        : localStorage.getItem("country_code_iso3") === "UAE"
                                            ? <><span style={{ fontSize: '13px', color: 'red', verticalAlign: 'super' }}>*</span>An additional fee of 1% of the total bill will be applied for payments made using a card upon the time of delivery.</>
                                            : <></>
                                    : localStorage.getItem("country_code_iso3") === "KSA"
                                        ? <><span style={{ fontSize: '13px', color: 'red', verticalAlign: 'super' }}>*</span>سيتم تطبيق رسوم إضافية قدرها 15 ريال سعودي على مبلغ الطلب حتى 1000 ريال سعودي، و20 ريال سعودي حتى 3750 ريال سعودي، و1% من مبلغ الطلب الذي يزيد عن 3750 ريال سعودي على عمليات الدفع التي تتم باستخدام البطاقة في وقت التسليم.</>
                                        : localStorage.getItem("country_code_iso3") === "UAE"
                                            ? <><span style={{ fontSize: '13px', color: 'red', verticalAlign: 'super' }}>*</span>سيتم تطبيق رسوم إضافية بنسبة 1% من إجمالي الفاتورة على الدفعات التي تتم باستخدام البطاقة عند وقت التسليم.</>
                                            : <></>
                            }
                        </div>
                        : null
                }
            </div>
        </div>
    );
};