import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";

import './reset.css'

export const ResetPassword = () => {
    const { userId, token } = useParams();

    const [password1, set_password1] = useState()
    const [password2, set_password2] = useState()

    const updatePassword = () => {
        if (password1 === password2) {
            let password_reset_url = process.env.REACT_APP_API_URL + 'password-reset'
            let reqBody = { new_password: password1, userId, token }

            axios.post(password_reset_url, reqBody)
                .then((response) => {
                    console.log(response.data.success)
                    if (response.data.success === true) {
                        window.alert(response.data.message)
                        window.location.href = '/';
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
            console.log({ reqBody, password2, userId, token, password_reset_url })
        } else {
            window.alert('both password must be same')
        }
    }

    return (
        <div className='reset-body'>
            <div className='pass-change-form-ctr'>
                <div style={{ marginBottom: '45px' }}>
                    <h2 style={{ textAlign: 'center', padding: '0', margin: '0', color: '#c69736', }}>Reset Password</h2>
                </div>

                <form method="POST" className="reset-pass-form" id="reset-pass-form">
                    <div className="form-group">
                        <label for="your_pass1"><i className="zmdi zmdi-lock"></i></label>
                        <input
                            className="reset-pass-form-input"
                            type="password"
                            name="your_pass1"
                            id="your_pass1"
                            placeholder="Enter New Password"
                            onChange={(e) => set_password1(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label for="your_pass2"><i className="zmdi zmdi-lock"></i></label>
                        <input
                            className="reset-pass-form-input"
                            type="password"
                            name="your_pass2"
                            id="your_pass2"
                            placeholder="Re-Enter New Password"
                            onChange={(e) => set_password2(e.target.value)}
                        />
                    </div>
                </form>

                <div className="form-group form-button">
                    <input type="button" name="update" id="update" className="reset-form-submit-btn" value="Change Password" onClick={() => updatePassword()} />
                </div>
            </div>
        </div>
    )
}
