export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const PRICE_LIST_REQUEST = "PRICE_LIST_REQUEST";
export const PRICE_LIST_SUCCESS = "PRICE_LIST_SUCCESS";
export const PRICE_LIST_FAIL = "PRICE_LIST_FAIL";

export const DETAIL="DETAIL";
export const REQUEST_DETAILS="REQUEST_DETAILS";
export const FAIL_DETAILS="FAIL_DETAILS"