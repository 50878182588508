/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderPost, shippingaddressget, orderlist } from "../../actions/orderaction";
import { listPrice } from "../../actions/productaction";
import { Rightinfo } from "../../components/rightinfo/rightinfo";
import "./paymentscreen.css";
import store from "../../store";
import { Link } from "react-router-dom";
import $, { get } from "jquery";
import { setcoupon, cartlist } from "../../actions/cartactions";
import queryString from "query-string";
import { useHistory } from "react-router";
import { Translate } from "react-auto-translate";

import { getCurrencySymbol, defaultLanguage, getShippingCharge } from '../../helpers';

export const Paymentscreen = (props) => {
    const dispatch = useDispatch();

    const addresses = useSelector((state) => state.shipping);
    const { address } = addresses;

    const prices = useSelector((state) => state.priceList);
    const { price } = prices;
    const [r, setr] = useState(null);

    const coupons = useSelector((state) => state.getcoupon);
    const { coupon } = coupons;

    const ordersList = useSelector((state) => state.orderlist);
    const { orders } = ordersList;

    const [paymentType, setPaymentType] = useState()
    const [paymentTypeUae, setPaymentTypeUae] = useState()

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("login")) == true) {
            dispatch(
                shippingaddressget(JSON.parse(localStorage.getItem("userInfo")).user)
            );
        } else {
            dispatch(shippingaddressget(localStorage.getItem("guestid")));
            localStorage.setItem('email', address?.shippingAddress?.email)
        }

        dispatch(listPrice());

        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [dispatch]);

    useEffect(() => {
        if (coupon != null) {
            localStorage.setItem("coupon", JSON.stringify(coupon));
        }
    }, [coupon]);

    useEffect(() => {
        // Default dropdown action to show/hide dropdown content
        $(".js-dropp-action").on("click", function (e) {
            e.preventDefault();
            $(this).toggleClass("js-open");
            $(this).parent().next(".dropp-body").toggleClass("js-open");
        });

        // Using as fake input select dropdown
        $("label").on("click", function () {
            $(this).addClass("js-open").siblings().removeClass("js-open");
            $(".dropp-body,.js-dropp-action").removeClass("js-open");
        });
        // get the value of checked input radio and display as dropp title
        $('input[name="dropp"]').on("change", function () {
            var value = $("input[name='dropp']:checked").val();
            $(".js-value").text(value);
        });
    }, []);

    const history = useHistory();
    const paytabsHandler = () => {

    }

    localStorage.setItem("curr", price);

    const [shippingCosts, setShippingCosts] = useState()

    const getShipping = async (codAmt) => {
        const shippingCharge = await getShippingCharge(codAmt)
        console.log('shipping', shippingCharge)
        setShippingCosts(shippingCharge)
    }

    useEffect(async () => {
        if (localStorage.getItem('shipping') === null) {
            getShipping(0)
        }
    }, []);

    let defaultLanguage = localStorage.getItem('translate')

    let currUserId = JSON.parse(localStorage.getItem("login")) === true
        ? JSON.parse(localStorage.getItem("userInfo")).user
        : localStorage.getItem("guestid")

    let updatedShipping = localStorage.getItem("country_code_iso3") === "KSA"
        ? localStorage.getItem('total') - localStorage.getItem('tax') <= 0
            ? 0
            : localStorage.getItem('total') - localStorage.getItem('tax') > 200
                ? 0
                : localStorage.getItem('shipping')
        : localStorage.getItem("country_code_iso3") === "UAE"
            ? localStorage.getItem('total') - localStorage.getItem('tax') <= 0
                ? 0
                : localStorage.getItem('total') - localStorage.getItem('tax') > 200
                    ? 0
                    : localStorage.getItem('shipping')
            : localStorage.getItem('total') - localStorage.getItem('tax') <= 0
                ? 0
                : localStorage.getItem('total') - localStorage.getItem('tax') > 200
                    ? 0
                    : localStorage.getItem('shipping')

    let updatedTax = localStorage.getItem("tax")

    const handleCod = async () => {
        let shipping = parseFloat(localStorage.getItem('shipping'))
        let total = parseFloat(localStorage.getItem('total'))
        let tax = parseFloat(localStorage.getItem('tax'))

        if (JSON.parse(localStorage.getItem("login")) === true) {
            dispatch(
                orderPost(
                    JSON.parse(localStorage.getItem("userInfo")).user,
                    "cash on delivery",
                    {
                        id: "",
                        status: "",
                        update_time: "",
                        email_address: "",
                    },
                    tax,
                    total - tax > 200 ? 0 : shipping,
                    total,
                    "",
                    false,
                    Date.now()
                )
            );
        } else {
            dispatch(
                orderPost(
                    localStorage.getItem("guestid"),
                    "cash on delivery",
                    {
                        id: "",
                        status: "",
                        update_time: "",
                        email_address: "",
                    },
                    tax,
                    total - tax > 200 ? 0 : shipping,
                    total,
                    "",
                    false,
                    Date.now()
                )
            );
        }

        if (localStorage.getItem("coupon") !== "") {
            store.dispatch(
                setcoupon(JSON.parse(localStorage.getItem("coupon")))
            );
            localStorage.setItem("coupon", "");
        }
        localStorage.setItem("apply", false);

        setTimeout(() => {
            axios.post(`${process.env.REACT_APP_API_URL}postmail`, {
                email: address?.shippingAddress?.email,
                user: JSON.parse(localStorage.getItem("login")) === true ? JSON.parse(localStorage.getItem("userInfo")).user : localStorage.getItem("guestid")
            });
            window.location.replace("/orders");
        }, 3000);
    }

    return (
        <div className="payment-page">
            <div className="shipping-top-info">
                <h3>
                    {defaultLanguage === 'en' ? 'Payment' : 'الدفع'}
                </h3>
            </div>
            <div className="payment-left-info">
                <div className="top-logo">
                    <img src="/images/logo.png" alt="logo" />
                </div>
                <div className="bottom-section">
                    <section className="inner-section">
                        <p className="shipping-heading">
                            {defaultLanguage === 'en' ? 'Contact' : 'الاتصال '}
                        </p>
                        <p className="payment-email">{address?.shippingAddress?.email}</p>
                    </section>
                    <section className="inner-section">
                        <p className="shipping-heading">
                            {defaultLanguage === 'en' ? 'Ship To' : 'الشحنة الى'}
                        </p>
                        <p>
                            {address?.shippingAddress?.address}{" "}
                            {address?.shippingAddress?.appartment}
                            {","}
                            {address.shippingAddress.city}
                            {","}
                            {address.shippingAddress.state}
                            {","} {address.shippingAddress.country}{" "}
                        </p>
                    </section>
                </div>
                <div className="last-section">
                    <h2 className="saved-address">
                        {defaultLanguage === 'en' ? 'Payment' : 'الدفع'}
                    </h2>
                    <p
                        style={{
                            color: "#c69736",
                        }}
                    >
                        {defaultLanguage === 'en' ? 'All transactions are end-to-end encrypted.' : 'جميع المعاملات مشفرة من كلاا الطرفين'}
                    </p>

                    {
                        localStorage.getItem('country_code_iso3') === "KSA"
                            ? <>
                                {
                                    defaultLanguage === "en"
                                        ? <p style={{ color: '#c69736' }}>Choose a payment method to pay</p>
                                        : <p style={{ color: '#c69736' }}>اختر طريقة الدفع للدفع</p>
                                }
                                <div style={{ display: 'flex', border: "1px solid #c69736" }}>
                                    <div
                                        style={{
                                            border: "1px solid #c69736",
                                            color: "white",
                                            padding: "2rem",
                                            width: '50%'
                                        }}
                                    >
                                        <span
                                            className="select-dot"
                                            onChange={() => {
                                                getShipping(localStorage.getItem('total'))
                                                setPaymentType('COD')
                                            }}
                                        >
                                            <input
                                                type="radio"
                                                id='payment_cod'
                                                name="address"
                                                value='COD'
                                                style={{ float: "right" }}
                                            />
                                        </span>
                                        <label
                                            style={{ cursor: "pointer" }}
                                            htmlFor='payment_cod'
                                        >
                                            COD
                                        </label>
                                    </div>
                                    <div
                                        style={{
                                            border: "1px solid #c69736",
                                            color: "white",
                                            padding: "2rem",
                                            width: '50%'
                                        }}
                                    >
                                        <span
                                            className="select-dot"
                                            onChange={() => {
                                                getShipping(0)
                                                setPaymentType('VISA_MASTER')
                                            }}
                                        >
                                            <input
                                                type="radio"
                                                id='payment_visamaster'
                                                name="address"
                                                value='VISA_MASTER'
                                                style={{ float: "right" }}
                                            />
                                        </span>
                                        <label
                                            style={{ cursor: "pointer" }}
                                            htmlFor='payment_visamaster'
                                        >
                                            VISA | MASTER
                                        </label>
                                    </div>
                                    <div
                                        style={{
                                            border: "1px solid #c69736",
                                            color: "white",
                                            padding: "2rem",
                                            width: '50%'
                                        }}
                                    >
                                        <span
                                            className="select-dot"
                                            onChange={() => {
                                                getShipping(0)
                                                setPaymentType('MADA')
                                            }}
                                        >
                                            <input
                                                type="radio"
                                                id='payment_mada'
                                                name="address"
                                                value='MADA'
                                                style={{ float: "right" }}
                                            />
                                        </span>
                                        <label
                                            style={{ cursor: "pointer" }}
                                            htmlFor='payment_mada'
                                        >
                                            MADA
                                        </label>
                                    </div>
                                </div>
                                {
                                    paymentType !== undefined && paymentType !== 'COD'
                                        ? <button
                                            className="continue-btn"
                                            type="submit"
                                            onClick={() => {
                                                localStorage.setItem("address", JSON.stringify(address));
                                                props.history.push(`/checkouts?type=${paymentType}`);
                                            }}
                                        >

                                            {defaultLanguage === 'en' ? `PAY ONLINE` : 'الدفع عن طريق الانترنت'}
                                        </button>
                                        : paymentType === 'COD'
                                            ? <button
                                                style={{ display: "block" }}
                                                className="continue-btn"
                                                type="submit"
                                                onClick={() => handleCod()}
                                            >
                                                {defaultLanguage === 'en' ? 'Cash on delivery' : 'الدفع عند الاستلام'}
                                            </button>
                                            : ''
                                }
                            </>
                            : localStorage.getItem('country_code_iso3') === "UAE"
                                ? <>
                                    {
                                        defaultLanguage === "en"
                                            ? <p style={{ color: '#c69736' }}>Choose a payment method to pay</p>
                                            : <p style={{ color: '#c69736' }}>اختر طريقة الدفع للدفع</p>
                                    }

                                    <div style={{ display: 'flex', border: "1px solid #c69736" }}>
                                        <div
                                            style={{
                                                border: "1px solid #c69736",
                                                color: "white",
                                                padding: "2rem",
                                                width: '50%'
                                            }}
                                        >
                                            <span
                                                className="select-dot"
                                                onChange={() => {
                                                    getShipping(localStorage.getItem('total'))
                                                    setPaymentTypeUae('COD')
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    id='payment_cod_uae'
                                                    name="address"
                                                    value='COD'
                                                    style={{ float: "right" }}
                                                />
                                            </span>
                                            <label
                                                style={{ cursor: "pointer" }}
                                                htmlFor='payment_cod_uae'
                                            >
                                                COD
                                            </label>
                                        </div>
                                        <div
                                            style={{
                                                border: "1px solid #c69736",
                                                color: "white",
                                                padding: "2rem",
                                                width: '50%'
                                            }}
                                        >
                                            <span
                                                className="select-dot"
                                                onChange={() => {
                                                    getShipping(0)
                                                    setPaymentTypeUae('ONLINE')
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    id='online_uae'
                                                    name="address"
                                                    value='VISA_MASTER'
                                                    style={{ float: "right" }}
                                                />
                                            </span>
                                            <label
                                                style={{ cursor: "pointer" }}
                                                htmlFor='online_uae'
                                            >
                                                Online
                                            </label>
                                        </div>
                                    </div>
                                    {
                                        paymentTypeUae === 'COD'
                                            ? <button
                                                style={{ display: "block" }}
                                                className="continue-btn"
                                                type="submit"
                                                onClick={() => handleCod()}
                                            >
                                                {defaultLanguage === 'en' ? 'Cash on delivery' : 'الدفع عند الاستلام'}
                                            </button>
                                            : paymentTypeUae === 'ONLINE'
                                                ? <a href={`${process.env.REACT_APP_API_URL}paytabs/${currUserId}/${updatedTax}/${parseFloat(updatedShipping)}`}>
                                                    <button className="continue-btn" type="submit">
                                                        {defaultLanguage === 'en' ? 'PAY ONLINE' : 'الدفع عن طريق الانترنت'}
                                                    </button>
                                                </a>
                                                : ''
                                    }
                                </>
                                : null
                    }

                    <a href="/shipping">
                        {defaultLanguage === 'en' ? 'Return to shipping' : 'العودة الى الشحن'}
                    </a>
                </div>
            </div>
            <Rightinfo shippingCost={shippingCosts} />
        </div>
    );
};

const paymentHandler = async (e) => {
    e.preventDefault();

    const API_URL = process.env.REACT_APP_API_URL;
    const orderUrl = `${API_URL}payment/${localStorage.getItem(
        "curr"
    )}/${localStorage.getItem("total")}`;
    const response = await axios.get(orderUrl);
    const { data } = response;
    const options = {
        key: "rzp_test_V9McTPCQ0fi6X7",
        name: "Your App Name",
        description: "Some Description",
        order_id: data.id,
        handler: async (response) => {
            try {
                const paymentId = response.razorpay_payment_id;
                const url = `${API_URL}capture/${paymentId}`;
                const captureResponse = await axios.post(url, {
                    amt: localStorage.getItem("total"),
                    crr: localStorage.getItem("curr"),
                });
                if (JSON.parse(captureResponse.data.body).status === "captured") {
                    if (JSON.parse(localStorage.getItem("login")) === true) {
                        store.dispatch(
                            orderPost(
                                JSON.parse(localStorage.getItem("userInfo")).user,
                                JSON.parse(captureResponse.data.body).method,
                                {
                                    id: JSON.parse(captureResponse.data.body).id,
                                    status: JSON.parse(captureResponse.data.body).method,
                                    update_time: Date.now(),
                                    email_address: JSON.parse(captureResponse.data.body).email,
                                },
                                localStorage.getItem("tax"),
                                0,
                                localStorage.getItem("total"),
                                JSON.parse(captureResponse.data.body).acquirer_data.bank_transaction_id,
                                true,
                                JSON.parse(captureResponse.data.body).created_at
                            )
                        );
                    } else {
                        store.dispatch(
                            orderPost(
                                localStorage.getItem("guestid"),
                                JSON.parse(captureResponse.data.body).method,
                                {
                                    id: JSON.parse(captureResponse.data.body).id,
                                    status: JSON.parse(captureResponse.data.body).method,
                                    update_time: Date.now(),
                                    email_address: JSON.parse(captureResponse.data.body).email,
                                },
                                localStorage.getItem("tax"),
                                0,
                                localStorage.getItem("total"),
                                JSON.parse(captureResponse.data.body).acquirer_data.bank_transaction_id,
                                true,
                                JSON.parse(captureResponse.data.body).created_at
                            )
                        );
                    }
                    if (localStorage.getItem("coupon") !== "") {
                        store.dispatch(
                            setcoupon(JSON.parse(localStorage.getItem("coupon")))
                        );
                        localStorage.setItem("coupon", "");
                    }
                    localStorage.setItem("apply", false);
                    if (JSON.parse(localStorage.getItem("login")) === true) {

                        axios.post(`${process.env.REACT_APP_API_URL}postmail`, {
                            email: JSON.parse(localStorage.getItem("userInfo")).email,
                        });
                    }
                    else {
                        axios.post(`${process.env.REACT_APP_API_URL}postmail`, {
                            email: localStorage.getItem("email"),
                        });
                    }
                    window.location.replace("/orders");
                }
            } catch (err) {
                console.log(err);
            }
        },
        theme: {
            color: "#686CFD",
        },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
};