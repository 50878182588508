/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./Termsandconditions.css";
import logo from "../../assets/images/crest-large.png";
import axios from "axios";
import { Translate } from "react-auto-translate";
import { useLayoutEffect } from "react";


export const Termsandconditions = () => {
    const [datas, setdatas] = useState(null);

    useEffect(async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}termcond`);
        setdatas(data.user[0].html);
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const currentLocation = localStorage.getItem('country_code_iso3')

    return (
        localStorage.getItem("translate") === "en"
            ? currentLocation === "KSA"
                ? <div className="aboutus-container">
                    <div className="aboutus-logo">
                        <hr />
                        <img src={logo} alt="" />
                        <hr />
                    </div>
                    <div className="aboutus-head">
                        <h1>TERMS & CONDITIONS</h1>
                    </div>
                    <div className="aboutus-container-box">
                        <div className="about-us-decor">
                            <div>
                                <h5>A. INTRODUCTION</h5>
                                <p>
                                    <ol>
                                        <li>www.makkajperfumes.com (the "Website") is owned by and/or operated by or on behalf of Al Majeed Modern Industry for Perfume Mfg. & Oudh Processing ("Makkaj Perfumes/we/us"), with delivery services provided by SMSA. Kingdom of Saudi Arabia is Makkaj Perfumes country of domicile.  If you have any questions regarding the Website or these terms and conditions, or in the unlikely event that you have any complaints about any products purchased by you from the Website or through any Applications (as defined below), you can contact us at estore@makkaj.com. In case of any disputes, the law applicable would be based on the KSA Governing Law for orders within KSA.</li>
                                        <li>If you choose a country in the home page of shop.Makkajperfume.com, then please go through the Terms and Conditions thoroughly which shall apply to your purchase and use of the Website with respect to the country selected.</li>
                                    </ol>
                                </p>

                                <h5>B. USE OF WEBSITE</h5>
                                <p>
                                    <ol>
                                        <li>These terms and conditions and any other policies referred to in these terms and conditions (including any policies or documents to which a link is provided from these terms and conditions) (together the "Terms") apply to your use of and access to the Website and any other website or application permitting you to place an order with Makkaj Perfumes for any products and services (such websites and applications being the “Applications” for the purpose of these Terms) including all orders submitted by you for any products or services made available by us for purchase over the Website and/or Applications. As the context requires, references to “Website” in these Terms shall also include Applications as applicable. By accessing this Website and/or the Applications you agree to these Terms and Conditions, we therefore advise you to read these Terms carefully and to save or print a copy of these Terms and Conditions for FUTURE reference. If you do not agree to these Terms, you must cease using and accessing this Website and all Applications immediately. The Terms may be changed and updated from time to time and any changes will be effective from the publication of the new terms on the Website or the relevant Application. Please note that all options available on our Website may not be available on any Application or the Website accessed using a mobile device.</li>
                                        <li>Please note that these Terms do not affect your statutory rights as a consumer.</li>
                                        <li>Makkaj Perfume.com will not deal or provide any services or products to any of OFAC sanctions countries in compliance with the law of KSA.</li>
                                        <li>You agree that the information you provide when you REGISTER on the Website is not misleading, and is true and accurate in all respects and you will notify our customer service team of any changes to that information.</li>
                                        <li>We may change, withdraw, or suspend access to the Website (in whole or part and permanently or temporarily) with or without notice and with no LIABILITY to you.</li>
                                        <li>We accept payments online using Visa and MasterCard credit/debit card in the currency required, AED/SAR. If “Credit Card Online” is selected as the payment methods than the details on the Customers ID (Card holders ID) should exactly match that of the credit card used to make the purchase.</li>
                                        <li>The Website may include links to other websites or resources ("Linked Websites"). Makkaj Perfumes has no control over the content of Linked Websites and you agree that, should you access a Linked Website using a link from the Website, Makkaj Perfumes is not responsible for the availability of the Linked Websites, and is not liable in any way for the content of Linked Websites, including (without limitation) any goods or services available from such Linked Websites, other advertising or content on such Linked Websites or the use that such Linked Websites make of your personal information. Furthermore Makkaj Perfumes will not be responsible for any offence, damage or loss caused by or connected to the use or reliance on such Linked Websites or the content thereon.</li>
                                        <li>Makkaj Perfumes may deny you access to the Website at any time in its sole discretion. Examples of when we may so deny you access include but is not limited to where we believe that your use of the Website is in violation of any of these Terms, any law or the rights of any third party or was not respectful to others.</li>
                                        <li>Any material that you upload to the Website for publication will be considered non-confidential and non-proprietary and we have the right to use, copy, distribute, reproduce, exploit, modify, alter and/or disclose to third parties any such material for any purpose. We also have the right to disclose your identity to any third party who is claiming that any material posted or uploaded by you to our site constitutes a violation of their INTELLECTUAL PROPERTY rights or of their right to privacy.</li>
                                        <li>Makkaj Perfumes will not be responsible, or liable to you or any third party, for the content or accuracy of any materials posted by you or any other user of the Website and you hereby agree to be responsible to Makkaj Perfumes for and indemnify Makkaj Perfumes and keep Makkaj Perfumes indemnified against all COSTS, damages, expenses, losses and liabilities incurred and/or suffered by Makkaj Perfumes as a result of any claim in respect of your use of the Website.</li>
                                        <li>Makkaj Perfumes has the right to remove any material or posting you make on the Website in Makkaj Perfume's sole discretion.</li>
                                    </ol>
                                </p>
                                <h5>C. PURCHASE OF PRODUCTS</h5>
                                <ol>
                                    <h6><li>ACCEPTANCE OF ORDERS</li></h6>
                                    <p>
                                        <ol>
                                            <li>All information on the Website is an invitation to treat only and is not an offer or unilateral CONTRACT. You agree that your order is an offer to purchase the products listed in your order ("Products") from us on the Terms. All orders submitted by you are subject to acceptance by us. We may choose not to accept your order in our discretion for any reason whatsoever without LIABILITY to you. Examples of when we may not accept your order are as follows:</li>
                                            <ol>
                                                <li style={{ listStyleType: 'lower-alpha' }}>If products are shown on the Website but are not available or are incorrectly priced or otherwise incorrectly described;</li>
                                                <li style={{ listStyleType: 'lower-alpha' }}>If we are unable to obtain authorization of your payment;</li>
                                                <li style={{ listStyleType: 'lower-alpha' }}>If you order multiple quantities of an individual Product where such Products are to be shipped to different customer or delivery address;</li>
                                                <li style={{ listStyleType: 'lower-alpha' }}>If shipping restrictions may apply to a Product; or</li>
                                                <li style={{ listStyleType: 'lower-alpha' }}>If the delivery address you give is the address of an entity or individual providing freight forwarding services.</li>
                                            </ol>
                                            <li>After submitting an order to us, we will send you an order acknowledgement email with your order number and details of the Products you have ordered from us and details of any delivery services (the "Delivery Services") you have ordered from Makkaj Perfumes. Please note that this email is an acknowledgement that we have received your order and is not an acceptance of your order. You will be receiving a shipment confirmation email from Makkaj Perfumes which is an acknowledgement that we have accepted your order. Acceptance of your order and the formation of</li>
                                            <ol>
                                                <li style={{ listStyleType: 'lower-alpha' }}>an order of sale of the Products between us and you and</li>
                                                <li style={{ listStyleType: 'lower-alpha' }}>an order for Delivery Services between us and you. In the event Makkaj Perfume partnered with shipping service company SMSA to dispatch the products to end customer and in the meantime, we have sent you an email confirming that the products have been dispatched through SMSA (“Dispatch Confirmation"). Further information is available at Shipping Destinations, Weight, Costs and Delivery Times.</li>
                                            </ol>
                                            <li>When placing an order for the first time, you may be required to or may be offered the option to REGISTER with us and complete certain required fields on an order form. We may provide you with and/or ask you to use identifications and passwords and other means for you to be able to access certain areas of the Website, such as the My Account section of the Website. You shall comply with all security directions and/or recommendations given by us and inform us immediately if you become aware of or suspect any unauthorised use of the Secure Access or if the Secure Access becomes available to an unauthorised party. Without prejudice to our other rights and remedies, we may suspend your access to the Website without LIABILITY to you, if in our reasonable opinion, such action is necessary for safeguarding the Website.</li>
                                            <li>Before you submit your order, you will be given the opportunity to review your selection, check the total price of your order and correct any input errors.</li>
                                            <li>We do not accept orders where the corresponding delivery address you give is that of an inappropriate. In the event that we do accept any order and we subsequently become aware that the delivery address is inappropriate for such order is that of no services, we shall be entitled to cancel such order upon notice to you by email or telephone.</li>
                                        </ol>
                                    </p>

                                    <h6><li>PRICES</li></h6>
                                    <p>
                                        <ol>
                                            <li>All prices of Products on the Website are the price for the Products only. Find out more about Shipping Destinations, Weight, Costs and Delivery Times.</li>
                                            <li>Makkaj Perfumes may vary the prices of Products listed on the Website at any time and without any notice but such changes will not apply to Products in respect of which you have been sent a Dispatch Confirmation.</li>
                                        </ol>
                                    </p>

                                    <h6><li>PAYMENT TERMS</li></h6>
                                    <p>
                                        <ol>
                                            <li>The total cost of your order will be the purchase price for the Products (which you pay to us) plus any delivery charge. Find out more about Shipping Destinations, Weight, Costs and Delivery Times.</li>
                                            <li>Please note that we accept payment in the payment CURRENCY specified for the country of your selected shipping destination in our Payment section.</li>
                                            <li>TRACK MY ORDER</li>
                                            <ol>
                                                <li style={{ listStyleType: 'lower-alpha' }}>On shipping your order we will send a confirmation email with your tracking number to confirm that your order has been accepted.</li>
                                                <li style={{ listStyleType: 'lower-alpha' }}>You can check the shipment status of your order on SMSA website by entering the sent tracking number.</li>
                                            </ol>
                                            <li>You confirm that the credit/debit card or payment method that is being used is yours and that all details you provide to us in respect thereof including, without limitation, name and address details are complete, correct and accurate . You further confirm that the credit/debit card is valid and the inputted payment details are correct. All credit/debit cardholders and payment ACCOUNT holders are subject to validation checks and authorization by the card issuer or payment method provider. If the issuer of your card or payment method refuses to authorize payment we will not accept your order and we will not be liable for any delay or non-delivery and we are not obliged to inform you of the reason for the refusal.</li>
                                            <li>We are not responsible for any charges or other amounts which may be applied by your card issuer or bank or payment method provider as a result of our processing of your credit/debit card payment or other method of payment in accordance with your order.</li>
                                            <li>If your credit/debit card or payment method is not denominated in the currency of your PURCHASE INDICATED on the Website, the final price may be charged in the currency of your card or account. Such final price is calculated and charged by your card issuer or bank or payment method provider and therefore we shall not be responsible for any cost, expense, charge or other liability which may be incurred or suffered by you as a result of your card issuer or payment method provider charging you in a different currency other than the currency of your purchase as displayed on the Website.</li>
                                        </ol>
                                    </p>

                                    <h6><li>INVOICING</li></h6>
                                    <p>
                                        <ol>
                                            <li>Where we elect, or are required by applicable law, to issue or make available an invoice, we reserve the right to issue or make available electronic invoices and you agree to such form of INVOICING.</li>
                                        </ol>
                                    </p>

                                    <h6><li>DELIVERY AND RISK</li></h6>
                                    <p>
                                        <ol>
                                            <li>We currently deliver the products only within Kingdom of Saudi Arabia.</li>
                                            <li>When you have selected your preferred delivery method from those offered for your selected shipping destination and provided your order has been accepted by us, your order shall be processed by us to dispatch your order in accordance with the estimated delivery times set out at Shipping Destinations, Weight, Costs and Delivery Times. Please refer shipping restrictions before placing an order. Orders received after any specified “cut off” or “last order” time or received on a day which is not a working day (that is any day on which the banks are open for business in KSA which is not a Friday or a Saturday, will be processed on the next working day). Estimated delivery times will be CALCULATED from the date on which the order is processed.</li>
                                            <li>As part of the ordering process, you will be contacted for supply of goods by SMSA. Upon placing an order you will receive various email confirmations in relation to your order. In respect of each of these and any other similar email confirmation, please note that references to shipping; express courier; shipping and handling and any other similar terms relate to the services performed by SMSA.</li>
                                            <li>Delivery information such as Customer Name, Location Address and Contact Number will be stored for delivery purposes. Other personal identifiable information will not be stored.</li>
                                        </ol>
                                    </p>

                                    <h6><li>CANCELLATION AND RETURNS</li></h6>
                                    <p>
                                        <ol>
                                            <li>Should you wish to cancel or return any Products, you may only do so in accordance with our Returns & Cancellation policy. This Returns & Cancellation Policy does not affect your statutory rights as a consumer distance selling legislation or e-commerce regulations in the territory to which the product is shipped (“Regulations”).</li>
                                            <li>Where you return a Product under the Regulations we will issue you with a full refund but you will need to return the Product at your own cost (if you have already received the Product), unless otherwise specified in our Returns & Cancellation policy.</li>
                                        </ol>
                                    </p>

                                    <h6><li>AGE REQUIREMENTS</li></h6>
                                    <p>
                                        <ol>
                                            <li>If you order a Product to which a minimum age requirement applies, by ordering that Product you confirm that you are of the required age. If we reasonably believe that you are not legally entitled to order a Product, we reserve the right to cancel your order.</li>
                                        </ol>
                                    </p>
                                </ol>
                            </div>
                        </div>

                        <div style={{ textAlign: 'center', marginTop: '10vh' }}>
                            <p className="contact_us" onClick={() => window.location.href = '/contact'}>For any further queries, please contact us</p>
                            <p>SUBSCRIBE TO OUR NEWSLETTER & RECEIVE EXCLUSIVE OFFERS</p>
                        </div>
                    </div>
                </div>
                : <div className="aboutus-container">
                    <div className="aboutus-logo">
                        <hr />
                        <img src={logo} alt="" />
                        <hr />
                    </div>
                    <div className="aboutus-head">
                        <h1>TERMS & CONDITIONS</h1>
                    </div>
                    <div className="aboutus-container-box">
                        <div className="about-us-decor">
                            <div>
                                <h5>INTRODUCTION</h5>
                                <p>
                                    <ol>
                                        <li>www.makkajperfumes.com (the "Website") is owned by and/or operated by or on behalf of AMAYRA PERFUMES TRADING LLC., ("Makkaj Perfumes/we/us"), with delivery services provided by SMSA. United Arab of Emirates is Makkaj Perfumes country of domicile.  If you have any questions regarding. the Website or these terms and conditions, or in the unlikely event that you have any complaints about any products purchased by you from the Website or through any Applications (as defined below), you can contact us at estore@makkaj.com. In case of any disputes, the law applicable would be based on the UAE Governing Law for orders within UAE.</li>
                                        <li>If you choose a country in the home page of shop.Makkajperfume.com, then please go through the Terms and Conditions thoroughly which shall apply to your purchase and use of the Website with respect to the country selected.</li>
                                    </ol>
                                </p>

                                <h5>B. USE OF WEBSITE</h5>
                                <p>
                                    <ol>
                                        <li>These terms and conditions and any other policies referred to in these terms and conditions (including any policies or documents to which a link is provided from these terms and conditions) (together the "Terms") apply to your use of and access to the Website and any other website or application permitting you to place an order with Makkaj Perfumes for any products and services (such websites and applications being the “Applications” for the purpose of these Terms) including all orders submitted by you for any products or services made available by us for purchase over the Website and/or Applications. As the context requires, references to “Website” in these Terms shall also include Applications as applicable. By accessing this Website and/or the Applications you agree to these Terms and Conditions, we therefore advise you to read these Terms carefully and to save or print a copy of these Terms and Conditions for FUTURE reference. If you do not agree to these Terms, you must cease using and accessing this Website and all Applications immediately. The Terms may be changed and updated from time to time and any changes will be effective from the publication of the new terms on the Website or the relevant Application. Please note that all options available on our Website may not be available on any Application or the Website accessed using a mobile device.</li>
                                        <li>Please note that these Terms do not affect your statutory rights as a consumer.</li>
                                        <li>Makkaj Perfume.com will not deal or provide any services or products to any of OFAC sanctions countries in compliance with the law of UAE</li>
                                        <li>You agree that the information you provide when you REGISTER on the Website is not misleading, and is true and accurate in all respects and you will notify our customer service team of any changes to that information.</li>
                                        <li>We may change, withdraw, or suspend access to the Website (in whole or part and permanently or temporarily) with or without notice and with no LIABILITY to you.</li>
                                        <li>We accept payments online using Visa and MasterCard credit/debit card in the currency required, AED/SAR. If “Credit Card Online” is selected as the payment methods than the details on the Customers ID (Card holders ID) should exactly match that of the credit card used to make the purchase.</li>
                                        <li>The Website may include links to other websites or resources ("Linked Websites"). Makkaj Perfumes has no control over the content of Linked Websites and you agree that, should you access a Linked Website using a link from the Website, Makkaj Perfumes is not responsible for the availability of the Linked Websites, and is not liable in any way for the content of Linked Websites, including (without limitation) any goods or services available from such Linked Websites, other advertising or content on such Linked Websites or the use that such Linked Websites make of your personal information. Furthermore Makkaj Perfumes will not be responsible for any offence, damage or loss caused by or connected to the use or reliance on such Linked Websites or the content thereon.</li>
                                        <li>Makkaj Perfumes may deny you access to the Website at any time in its sole discretion. Examples of when we may so deny you access include but is not limited to where we believe that your use of the Website is in violation of any of these Terms, any law or the rights of any third party or was not respectful to others.</li>
                                        <li>Any material that you upload to the Website for publication will be considered non-confidential and non-proprietary and we have the right to use, copy, distribute, reproduce, exploit, modify, alter and/or disclose to third parties any such material for any purpose. We also have the right to disclose your identity to any third party who is claiming that any material posted or uploaded by you to our site constitutes a violation of their INTELLECTUAL PROPERTY rights or of their right to privacy.</li>
                                        <li>Makkaj Perfumes will not be responsible, or liable to you or any third party, for the content or accuracy of any materials posted by you or any other user of the Website and you hereby agree to be responsible to Makkaj Perfumes for and indemnify Makkaj Perfumes and keep Makkaj Perfumes indemnified against all COSTS, damages, expenses, losses and liabilities incurred and/or suffered by Makkaj Perfumes as a result of any claim in respect of your use of the Website.</li>
                                        <li>Makkaj Perfumes has the right to remove any material or posting you make on the Website in Makkaj Perfume's sole discretion.</li>
                                    </ol>
                                </p>

                                <h5>C. PURCHASE OF PRODUCTS</h5>
                                <ol>
                                    <h6><li>ACCEPTANCE OF ORDERS</li></h6>
                                    <p>
                                        <ol>
                                            <li>All information on the Website is an invitation to treat only and is not an offer or unilateral CONTRACT. You agree that your order is an offer to purchase the products listed in your order ("Products") from us on the Terms. All orders submitted by you are subject to acceptance by us. We may choose not to accept your order in our discretion for any reason whatsoever without LIABILITY to you. Examples of when we may not accept your order are as follows:</li>
                                            <ol>
                                                <li style={{ listStyleType: 'lower-alpha' }}>If products are shown on the Website but are not available or are incorrectly priced or otherwise incorrectly described;</li>
                                                <li style={{ listStyleType: 'lower-alpha' }}>If we are unable to obtain authorization of your payment;</li>
                                                <li style={{ listStyleType: 'lower-alpha' }}>If you order multiple quantities of an individual Product where such Products are to be shipped to different customer or delivery address;</li>
                                                <li style={{ listStyleType: 'lower-alpha' }}>If shipping restrictions may apply to a Product; or</li>
                                                <li style={{ listStyleType: 'lower-alpha' }}>If the delivery address you give is the address of an entity or individual providing freight forwarding services.</li>
                                            </ol>
                                            <li>After submitting an order to us, we will send you an order acknowledgement email with your order number and details of the Products you have ordered from us and details of any delivery services (the "Delivery Services") you have ordered from Makkaj Perfumes. Please note that this email is an acknowledgement that we have received your order and is not an acceptance of your order. You will be receiving a shipment confirmation email from Makkaj Perfumes which is an acknowledgement that we have accepted your order. Acceptance of your order and the formation of</li>
                                            <ol>
                                                <li style={{ listStyleType: 'lower-alpha' }}>an order of sale of the Products between us and you and</li>
                                                <li style={{ listStyleType: 'lower-alpha' }}>an order for Delivery Services between us and you. In the event Makkaj Perfume partnered with shipping service company SMSA to dispatch the products to end customer and in the meantime, we have sent you an email confirming that the products have been dispatched through SMSA (“Dispatch Confirmation"). Further information is available at Shipping Destinations, Weight, Costs and Delivery Times.</li>
                                            </ol>
                                            <li>When placing an order for the first time, you may be required to or may be offered the option to REGISTER with us and complete certain required fields on an order form. We may provide you with and/or ask you to use identifications and passwords and other means for you to be able to access certain areas of the Website, such as the My Account section of the Website. You shall comply with all security directions and/or recommendations given by us and inform us immediately if you become aware of or suspect any unauthorised use of the Secure Access or if the Secure Access becomes available to an unauthorised party. Without prejudice to our other rights and remedies, we may suspend your access to the Website without LIABILITY to you, if in our reasonable opinion, such action is necessary for safeguarding the Website.</li>
                                            <li>Before you submit your order, you will be given the opportunity to review your selection, check the total price of your order and correct any input errors.</li>
                                            <li>We do not accept orders where the corresponding delivery address you give is that of an inappropriate. In the event that we do accept any order and we subsequently become aware that the delivery address is inappropriate for such order is that of no services, we shall be entitled to cancel such order upon notice to you by email or telephone.</li>
                                        </ol>
                                    </p>

                                    <h6><li>PRICES</li></h6>
                                    <p>
                                        <ol>
                                            <li>All prices of Products on the Website are the price for the Products only. Find out more about Shipping Destinations, Weight, Costs and Delivery Times.</li>
                                            <li>Makkaj Perfumes may vary the prices of Products listed on the Website at any time and without any notice but such changes will not apply to Products in respect of which you have been sent a Dispatch Confirmation.</li>
                                        </ol>
                                    </p>

                                    <h6><li>PAYMENT TERMS</li></h6>
                                    <p>
                                        <ol>
                                            <li>The total cost of your order will be the purchase price for the Products (which you pay to us) plus any delivery charge. Find out more about Shipping Destinations, Weight, Costs and Delivery Times.</li>
                                            <li>Please note that we accept payment in the payment CURRENCY specified for the country of your selected shipping destination in our Payment section.</li>
                                            <li>TRACK MY ORDER</li>
                                            <ol>
                                                <li style={{ listStyleType: 'lower-alpha' }}>On shipping your order we will send a confirmation email with your tracking number to confirm that your order has been accepted.</li>
                                                <li style={{ listStyleType: 'lower-alpha' }}>You can check the shipment status of your order on SMSA website by entering the sent tracking number.</li>
                                            </ol>
                                            <li>You confirm that the credit/debit card or payment method that is being used is yours and that all details you provide to us in respect thereof including, without limitation, name and address details are complete, correct and accurate . You further confirm that the credit/debit card is valid and the inputted payment details are correct. All credit/debit cardholders and payment ACCOUNT holders are subject to validation checks and authorization by the card issuer or payment method provider. If the issuer of your card or payment method refuses to authorize payment we will not accept your order and we will not be liable for any delay or non-delivery and we are not obliged to inform you of the reason for the refusal.</li>
                                            <li>We are not responsible for any charges or other amounts which may be applied by your card issuer or bank or payment method provider as a result of our processing of your credit/debit card payment or other method of payment in accordance with your order.</li>
                                            <li>If your credit/debit card or payment method is not denominated in the currency of your PURCHASE INDICATED on the Website, the final price may be charged in the currency of your card or account. Such final price is calculated and charged by your card issuer or bank or payment method provider and therefore we shall not be responsible for any cost, expense, charge or other liability which may be incurred or suffered by you as a result of your card issuer or payment method provider charging you in a different currency other than the currency of your purchase as displayed on the Website.</li>
                                        </ol>
                                    </p>

                                    <h6><li>INVOICING</li></h6>
                                    <p>
                                        <ol>
                                            <li>Where we elect, or are required by applicable law, to issue or make available an invoice, we reserve the right to issue or make available electronic invoices and you agree to such form of INVOICING.</li>
                                        </ol>
                                    </p>

                                    <h6><li>DELIVERY AND RISK</li></h6>
                                    <p>
                                        <ol>
                                            <li>We currently deliver the products only within United Arab Emirates.</li>
                                            <li>When you have selected your preferred delivery method from those offered for your selected shipping destination and provided your order has been accepted by us, your order shall be processed by us to dispatch your order in accordance with the estimated delivery times set out at Shipping Destinations, Weight, Costs and Delivery Times. Please refer shipping restrictions before placing an order. Orders received after any specified “cut off” or “last order” time or received on a day which is not a working day (that is any day on which the banks are open for business in UAE which is not a Friday or a Saturday, will be processed on the next working day). Estimated delivery times will be CALCULATED from the date on which the order is processed.</li>
                                            <li>As part of the ordering process, you will be contacted for supply of goods by SMSA. Upon placing an order you will receive various email confirmations in relation to your order. In respect of each of these and any other similar email confirmation, please note that references to shipping; express courier; shipping and handling and any other similar terms relate to the services performed by SMSA.</li>
                                            <li>Delivery information such as Customer Name, Location Address and Contact Number will be stored for delivery purposes. Other personal identifiable information will not be stored.</li>
                                        </ol>
                                    </p>

                                    <h6><li>CANCELLATION AND RETURNS</li></h6>
                                    <p>
                                        <ol>
                                            <li>Should you wish to cancel or return any Products, you may only do so in accordance with our Returns & Cancellation policy. This Returns & Cancellation Policy does not affect your statutory rights as a consumer distance selling legislation or e-commerce regulations in the territory to which the product is shipped (“Regulations”).</li>
                                            <li>Where you return a Product under the Regulations we will issue you with a full refund but you will need to return the Product at your own cost (if you have already received the Product), unless otherwise specified in our Returns & Cancellation policy.</li>
                                        </ol>
                                    </p>

                                    <h6><li>AGE REQUIREMENTS</li></h6>
                                    <p>
                                        <ol>
                                            <li>If you order a Product to which a minimum age requirement applies, by ordering that Product you confirm that you are of the required age. If we reasonably believe that you are not legally entitled to order a Product, we reserve the right to cancel your order.</li>
                                        </ol>
                                    </p>
                                </ol>
                            </div>
                        </div>

                        <div style={{ textAlign: 'center', marginTop: '10vh' }}>
                            <p className="contact_us" onClick={() => window.location.href = '/contact'}>For any further queries, please contact us</p>
                            <p>SUBSCRIBE TO OUR NEWSLETTER & RECEIVE EXCLUSIVE OFFERS</p>
                        </div>
                    </div>
                </div>
            : currentLocation === "KSA"
                ? <div className="aboutus-container">
                    <div className="aboutus-logo">
                        <hr />
                        <img src={logo} alt="" />
                        <hr />
                    </div>
                    <div className="aboutus-head">
                        <h1>الأحكام والشروط</h1>
                    </div>
                    <div className="aboutus-container-box">
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>أ- مقدمة</p></h5>
                                <p className="text_rtl">
                                    <ol>
                                        <li>١- موقع (”موقع الإنترنت“) تملكه /أو يتم تشغيله بواسطة أو بالنيابة عن مصنع المجيد الحديث لتصنيع العطور و العود) (ويشار إليها فيما يلي بعبارة “عطور مكاج (www.makkajperfumes.com) /نحن)، مع تزويد خدمات التسليم بواسطة سمسا. المملكة العربية السعوديةّ هي بلد موطن عطور أجمل. إن كانت لديك أية أسئلة حول الموقع أو هذه الأحكام والشروط، أو في حال كان لديك أية شكاوى حول أية منتجات تم شراؤها من الموقع أو عبر أية تطبيقات )كما هي محددة أدناه(، يمكنك الاتصال بنا على estore@makkaj.com</li>
                                        <li>٢- إذا اخترت بلد معين من خلال الصفحة الرئيسية  www.makkajperfumes.com، يرجى الإطلاع على الأحكام والشروط الخاصة بشكل كامل والتي تطبق على مشترياتك واستخدامك لموقع الإنترنت الخاصة ببلد الإختيار.</li>
                                    </ol>
                                </p>

                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>ب- استخدام موقع الإنترنت</p></h5>
                                <p className="text_rtl">
                                    <ol>
                                        <li>١- تطبق هذه الأحكام والشروط وأية سياسات أخرى مشار إليها في هذه الأحكام والشروط  وما فيها أية سياسات أو وثائق يتم تزويد رابط لها من هذه الأحكام والشروط (ويشار إليها جميعاً بعبارة “الشروط",على استخدامك ودخولك إلى موقع الإنترنت وأي موقع أو تطبيق آخر يسمح لك بتقديم طلب مع عطور مكاج لأية منتجات وخدمات ) مثل المواقع والتطبيقات كونها “التطبيقات” لغرض هذه الشروط(بما فيها جميع الطلبات المقدمة من قبلك لأية منتجات أو خدمات نوفرها لك للشراء عبر الإنترنت و/أو التطبيقات. كما يقتضي السياق، فإن الإشارة إلى “الموقع” في هذه الشروط تشمل أيضاً التطبيقات حسب مقتضى الحال. بالدخول إلى هذا الموقع و/أو التطبيقات فإنك توافق على هذه الأحكام والشروط ولذلك ننصحك بقراءة هذه الشروط بعناية وحفظها أو طباعة نسخة من هذه الأحكام والشروط للرجوع إليها في المستقبل. إن كنت لا توافق على هذه الشروط، يجب عليك التوقف عن استعمال ودخول الموقع أو التطبيق المعني. يرجى أخذ العلم أن جميع الخيارات المتوفرة على موقعنا قد لا تكون متوفرة على أي تطبيق أو الموقع الذي يتم الدخول إليه باستخدام جهاز نقال.</li>
                                        <li>٢- يرجى أخذ العلم أن هذه الشروط لا تؤثر على حقوقك القانونية كمستهلك.</li>
                                        <li>www.makkajperfume.com  لن تتعامل أو تزود أية خدمات أو منتجات إلى أي دول تطبق عليها عقوبات مكتب مراقبة الأصول الأجنبية وفقاً لقانون الإمارات العربية المتحده      .</li>
                                        <li>٤- أنت توافق أن المعلومات التي تزودها عند التسجيل في الموقع ليست خاطئة وهي صحيحة ودقيقة من جميع النواحي وسوف تخبرفريق خدمة العملاء بأية تغييرات في تلك المعلومات.</li>
                                        <li>٥- يحق لنا تغيير أو سحب أو وقف الدخول إلى الموقع كلياً أو جزئياً وبشكل مؤقت أو دائم مع أو بدون إشعار مسبق وبدون تحمل أية مسؤولية تجاهك.</li>
                                        <li>٦- نقبل الدفعات عبر الإنترنت باستخدام بطاقات فيزا وماستركارد الائتمانية/البطاقات المصرفية في العملة المطلوبة، الدرهم الإماراتي/الريال السعودي. إذا تم اختيار “البطاقة الائتمانية عبر الإنترنت” كوسيلة الدفع فإن التفاصيل في هوية العميل (هوية حامل البطاقة) يجب أن تطابق تماماً معلومات البطاقة الائتمانية المستخدمة لدفع قيمة المشتريات.</li>
                                        <li>٧- قد يشمل الموقع روابط لمواقع أو مصادر أخرى المواقع المرتبطة (عطور مكاج لا تملك السيطرة على محتوى المواقع المرتبطة وأنت توافق أنه إذا دخلت إلى موقع مترابط باستخدام رابط من موقعنا، فإن عطور مكاج ليست مسؤولة عن توفر المواقع المرتبطة وليست مسؤولة بأي شكل من الأشكال عن محتوى المواقع المرتبطة، بما في ذلك دون تحديد( أية بضائع أو خدمات متوفرة من هذه المواقع المرتبطة، إعلانات أخرى أو محتوى آخر على هذه المواقع المرتبطة أو استخدام هذه المواقع المرتبطة لمعلوماتك الشخصية. علاوة على ذلك، لن تكون عطور مكاج مسؤولة عن أي جرم أو ضرر أو خسارة ناتجة عن أو مرتبطة باستخدام أو الاعتماد على هذه المواقع المرتبطة أو المحتوى الموجود فيها.</li>
                                        <li>٨- يحق عطور مكاج رفض دخولك إلى الموقع في أي وقت حسب تقديرها المطلق. من الأمثلة التي قد نرفض دخولك فيها مثلا عندما نعتقد أن استخدامك للموقع فيه مخالفة لهذه الشروط أو أي قانون أو حقوق أي طرف ثالث أو لا يحترم الآخرين.</li>
                                        <li>٩- أي مواد تقوم بتحميلها على الموقع للنشر تعتبر غير سرية وغير مملوكة ولدينا الحق في استخدام، نسخ، توزيع، إعادة إنتاج، استغلال، تعديل و/أو إفصاح هذه المواد لأطراف ثالثة لأي غرض. لدينا أيضاً الحق في الكشف عن هويتك لأي طرف ثالث يدعي أن أية مادة تم نشرها أو تحميلها من قبلك على موقعنا تشكل انتهاكاً لحقوقه في الملكية الفكرية أو حقه في الخصوصية.</li>
                                        <li>١٠- لن تكون عطور مكاج  مسؤولة تجاهك أو تجاه أي طرف ثالث عن محتوى أو دقة أية مواد يتم نشرها من قبلك أو من قبل أي مستخدم آخر للموقع وأنت توافق بموجب ذلك أن تكون مسؤولاً تجاه عطور مكاج وأن تعوض عطور مكاج وتعمل على بقاء عطور مكاج معوضة عن جميع التكاليف، الأضرار، النفقات، الخسائر والالتزامات التي تتحملها عطور مكاج نتيجة لأية مطالبة تتعلق باستخدامك للموقع.</li>
                                        <li>١١- يحق عطور مكاج إزالة أية مواد تنشرها على الموقع حسب التقدير المطلق لعطور أجمل.</li>
                                    </ol>
                                </p>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>ج- شراء المنتجات</p></h5>
                                <ol>
                                    <h6><p className="text_rtl" style={{ color: '#c69736' }}><li>١- قبول الطلبات</li></p></h6>
                                    <p className="text_rtl">
                                        <ol>
                                            <li>١-١ جميع المعلومات على الموقع هي دعوة للتعامل فقط وليست عرضاً أو عقداً من طرف واحد. أنت توافق أن طلبك هو عرض لشراء المنتجات المدرجة في طلبك المنتجاتمنا حسب الشروط. جميع الطلبات المقدمة من طرفك تخضع للقبول من طرفنا. قد نقرر عدم قبول طلبك حسب تقديرنا لأي سبب كان بدون أية مسؤولية تجاهك. هذه بعض الأمثلة للحالات التي قد لا نقبل فيها طلبك:</li>
                                            <ol>
                                                <li style={{ listStyleType: 'lower-alpha' }}>ا- ( إذا كانت المنتجات ظاهرة في الموقع ولكنها غير متوفرة أو تم تسعيرها بشكل خاطئ أو وصفها بشكل خاطئ) </li>
                                                <li style={{ listStyleType: 'lower-alpha' }}>ب- ( إن لم نستطع الحصول على موافقة على دفعتك؛)</li>
                                                <li style={{ listStyleType: 'lower-alpha' }}>ج- ( إذا طلبت كميات متعددة من منتج واحد حيث كان من المفروض أن يتم شحن هذه المنتجات إلى عميل أو عنوان مختلف للتسليم؛)</li>
                                                <li style={{ listStyleType: 'lower-alpha' }}>د- ( إذا كانت قيود الشحن مطبقة على المنتج؛ أو إذا كان عنوان التسليم الذي أعطيته هو عنوان كيان أو فرد يزود خدمات الشحن.</li>
                                            </ol>
                                            <li>١-٢ بعد تقديم طلبك إلينا، سنرسل إليك رسالة تأكيد بالبريد الإكتروني مع رقم طلبك وتفاصيل المنتجات التي طلبتها منا وتفاصيل أية خدمات للتسليم )خدمات التسليم( طلبتها من عطور أجمل. يرجى أخذ العلم أن هذه الرسالة هي تأكيد أننا استلمنا طلبك وليست قبول لطلبك. )أ( طلب بيع منتجات بيننا وبينك و)ب( طلب لخدمات التسليم بيننا وبينك. في حال تشاركت عطور مكاج مع شركة خدمات الشحن سمسا  لإرسال المنتجات إلى العميل النهائي وفي تلك الأثناء أرسلنا إليك رسالة بالبريد الإلكتروني تؤكد أن المنتجات قد تم إرسالها إليك عن طريق سمسا  , تأكيد الإرسال
                                                تتوفر معلومات إضافية في بنود وجهات الشحن، الوزن، التكاليف وأوقات التسليم.</li>
                                            <li>١-٣ عند تقديم الطلب لأول مرة، قد نطلب منك أو قد نعرض عليك خيار التسجيل معنا وإكمال حقول مطلوبة معينة على استمارة الطلب. قد نزودك و/أو نطلب منك استخدام تحديدات وكلمات سر ووسائل أخرى لكي تكون قادراً على الدخول إلى مناطق معينة من الموقع، مثل قسم My Account يجب عليك الإلتزام بجميع التعليمات الأمنية و/ التوصيات المعطاة من قبلنا وتخبرنا فوراً إذا علمت أو شككت بأي استخدام غير مصرح به للدخول الآمن أو إذا أصبح الدخول الآمن متوفراً لطرف غير مصرح به. دون المساس بحقوقنا ووسائل الانتصاف الأخرى، يحق لنا تعليق دخولك إلى الموقع دون أية مسؤولية تجاهك، إذا وجدنا حسب رأينا المنطقي أن مثل هذا الإجراء ضروري لحماية الموقع.</li>
                                            <li>١-٤ قبل تقديم طلبك، ستتاح لك الفرصة لمراجعة اختيارك، التأكد من السعر الإجمالي لطلبك وتصحيح أية أخطاء.</li>
                                            <li>١-٥ نحن لا نقبل الطلبات حيث يكون عنوان التسليم المعطى غير لائق. في حال قبلنا أي طلب وأصبحنا على علم في وقت لاحق أن عنوان التسليم غير لائق لمثل هذا الطلب، يحق لنا إلغاء ذلك الطلب مع إخطارك بواسطة البريد الإلكتروني أو الهاتف.</li>
                                        </ol>
                                    </p>

                                    <h6><p className="text_rtl" style={{ color: '#c69736' }}><li>٢- الأسعار</li></p></h6>
                                    <p className="text_rtl">
                                        <ol>
                                            <li>٢-١ جميع أسعار المنتجات على الموقع هي أسعار المنتجات فقط. إعرف المزيد حول وجهات الشحن، التكاليف وأوقات التسليم.</li>
                                            <li>٢-٢ عطور مكاج قد تغير أسعار المنتجات المدرجة في الموقع في أي وقت وبدون أي إشعار ولكن مثل هذه التغييرات لا تنطبق على المنتجات التي وصلك تأكيد الإرسال لها.</li>
                                        </ol>
                                    </p>

                                    <h6><p className="text_rtl" style={{ color: '#c69736' }}><li>٣- شروط الدفع</li></p></h6>
                                    <p className="text_rtl">
                                        <ol>
                                            <li>٣-١ ستكون التكلفة الإجمالية لطلبك سعر شراء المنتجات )الذي تدفعه إلينا(إضافة إلى أية رسوم للتسليم. إعرف المزيد حول وجهات الشحن، التكاليف وأوقات التسليم.</li>
                                            <li>٣-٢ يمكنك إيجاد المزيد من المعلومات حول وسائل الدفع التي نقبلها وتفاصيل متى سيتم خصم قيمة الطلب من حسابك في قسم الدفع.</li>
                                            <li>٣-٤ أنت تؤكد أن البطاقة الائتمانية/المصرفية أو وسيلة الدفع المستخدمة هي ملكك وأن جميع التفاصيل التي زودتنا بها في هذا الشأن وتشمل ولكنها لا تقتصر على الإسم وتفاصيل العنوان هي كاملة وصحيحة ودقيقة. أنت تؤكد أيضاً أن البطاقة الائتمانية/المصرفية سارية المفعول وتفاصيل الدفع المعطاة صحيحة. يخضع جميع حاملي البطاقات الائتمانية/المصرفية وأصحاب حسابات الدفع لعمليات التحقق من الصحة والحصول على إذن من الجهة المصدرة للبطاقة أو المزودة لوسيلة الدفع. إذا رفضت الجهة المصدرة للبطاقة أو المزودة لوسيلة الدفع إعطاء أذن بالدفع، لن نقبل طلبك ولسنا مسؤولين عن أي تأخير أو عدم تسليم ولن نكون ملزمين بإبلاغك عن سبب الرفض.</li>
                                            <li>٣-٥ نحن لسنا مسؤولين عن أية رسوم أو مبالغ أخرى قد تطبقها الجهة المصدرة للبطاقة أو البنك أو الجهة المزودة لوسيلة الدفع نتيجة لقيامنا بإجراءات الدفع بالبطاقة الائتمانية/المصرفية أو وسائل الدفع الأخرى وفقاً لطلبك.</li>
                                            <li>٣-٦ إن لم تكن بطاقتك الائتمانية/المصرفية أو وسيلة الدفع بعملة شرائك الموضحة على الموقع، يمكن أن يتم قيد السعر النهائي بعملة بطاقتك أو حسابك. يتم احتساب هذا السعر النهائي وخصمه من قبل الجهة المصدرة للبطاقة أو البنك أو الجهة المزودة لوسيلة الدفع وبالتالي لن نكون مسؤولين عن أية تكاليف، مصاريف، رسوم أو إلتزامات أخرى قد تتحملها نتيجة لقيام الجهة المصدرة لبطاقتك أو المزودة لوسيلة الدفع بخصم المبلغ بعملة مختلفة عن عملة شرائك الموضحة على الموقع.</li>
                                        </ol>
                                    </p>

                                    <h6><p className="text_rtl" style={{ color: '#c69736' }}><li>٤- الفواتير</li></p></h6>
                                    <p className="text_rtl">
                                        <ol>
                                            <li>٤-١ حيث نختار، أو يقتضي القانون المطبق، أن نصدر أو نوفر فاتورة، نحتفظ بالحق لإصدار أو توفير فواتير إلكترونية وأنت توافق على هذا الشكل من الفواتير.</li>
                                        </ol>
                                    </p>

                                    <h6><p className="text_rtl" style={{ color: '#c69736' }}><li>٥-التسليم والمخاطر</li></p></h6>
                                    <p className="text_rtl">
                                        <ol>
                                            <li>٥-١ نقوم حالياً بتسليم المنتجات فقط ضمن المملكة العربية السعوديةّ.</li>
                                            <li>٥-٢ بعد أن تختار طريقة التسليم المفضلة بالنسبة لك من الطرق المتوفرة لوجهة الشحن المختارة وفي حال قبولنا لطلبك، سيتم تجهيز طلبك لإرساله وفقاً لأوقات التسليم المحددة في وجهات الشحن، الوزن، التكاليف وأوقات التسليم. يرجى مشاهدة منطقة الخدمات وقيودها. الطلبات التي يتم استلامها بعد أوقات التوقف المحددة أو مواعيد “آخر طلب” أو في يوم ليس من أيام العمل )الذي هو أي يوم تفتح فيه البنوك في المملكة العربية السعوديةّ ولا يكون يوم جمعة أو سبت( يتم تجهيزها في يوم العمل التالي. يتم حساب أوقات التسليم التقديرية من تاريخ تجهيز الطلب.</li>
                                            <li>٥-٣ كجزء من عملية إجراء الطلب، سوف تتلقى إتصال لتسليم البضائع بواسطة سمسا . عند القيام بالطلب، سوف تتلقى عدة تأكيدات بالبريد الإلكتروني فيما يتعلق بطلبك. بالنسبة لكل هذه التأكيدات وأية تأكيدات مماثلة أخرى بالبريد الإلكتروني، يرجى أخذ العلم أن المراجع المتعلقة بالشحن، خدمات التسليم السريع وأية مصطلحات مشابهة أخرى تكون متعلقة بالخدمات التي تقدمها سمسا .</li>
                                            <li>٥-٤ سيتم تخزين معلومات التسليم مثل إسم العميل، العنوان ورقم الاتصال لأغراض التسليم. وسيتم تخزين معلومات شخصية تعريفية أخرى.</li>
                                        </ol>
                                    </p>

                                    <h6><p className="text_rtl" style={{ color: '#c69736' }}><li>٦- الإلغاء والإعادة</li></p></h6>
                                    <p className="text_rtl">
                                        <ol>
                                            <li>٦-١ في حال رغبت بإلغاء أو إعادة أية منتجات، يمكنك القيام بذلك فقط بموجب سياستنا للإعادة والإلغاء. هذه السياسة للإعادة والإلغاء لا تؤثر على حقوقك القانونية كمستهلك في التشريع المعمول به للبيع عن بعد أو أنظمة التجارة     الإلكترونية في المنطقة التي يتم شحن المنتج إليها )الأنظمة</li>
                                            <li>٦-٢ حيث يمكنك إرجاع منتج بموجب الأنظمة، سنصدر لك إعادة القيمة الكاملة ولكن يجب عليك إعادة المنتج على نفقتك الخاصة )إذا كنت قد استلمت المنتج(، ما لم تنص سياستنا للإعادة والإلغاء على خلاف ذلك.</li>
                                        </ol>
                                    </p>

                                    <h6><p className="text_rtl" style={{ color: '#c69736' }}><li>٧- متطلبات العمر</li></p></h6>
                                    <p className="text_rtl">
                                        <ol>
                                            <li>٧-١ إذا طلبت منتجاً يطبق عليه شرط الحد الأدنى للعمر، فأنت تؤكد من خلال طلب المنتج أنك تبلغ العمر القانوني المطلوب. إذا اعتقدنا بشكل منطقي أنك غير مؤهل قانونياً بطلب منتج ما، فإننا نحتفظ بالحق لإلغاء طلبك.</li>
                                        </ol>
                                    </p>
                                </ol>
                            </div>
                        </div>

                        <div style={{ textAlign: 'center', marginTop: '10vh' }}>
                            <p className="contact_us" onClick={() => window.location.href = '/contact'}>للحصول على أي استفسارات أخرى ، يرجى الاتصال بنا</p>
                            <p>اشترك في نشرتنا الإخبارية واحصل على العروض الحصرية</p>
                        </div>
                    </div>
                </div>
                : <div className="aboutus-container">
                    <div className="aboutus-logo">
                        <hr />
                        <img src={logo} alt="" />
                        <hr />
                    </div>
                    <div className="aboutus-head">
                        <h1>الأحكام والشروط</h1>
                    </div>
                    <div className="aboutus-container-box">
                        <div className="about-us-decor">
                            <div>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>أ- مقدمة</p></h5>
                                <p className="text_rtl">
                                    <ol>
                                        <li>الإمارات العربية المتحدة هي بلد موطن عطور أجمل. إن كانت لديك أية أسئلة حول الموقع أو هذه الأحكام والشروط، أو في حال كان لديك أية شكاوى حول أية منتجات تم شراؤها من الموقع أو عبر أية تطبيقات )كما هي محددة أدناه(، يمكنك الاتصال بنا على estore@makkaj.com</li>
                                        <li>٢- إذا اخترت بلد معين من خلال الصفحة الرئيسية  www.makkajperfumes.com، يرجى الإطلاع على الأحكام والشروط الخاصة بشكل كامل والتي تطبق على مشترياتك واستخدامك لموقع الإنترنت الخاصة ببلد الإختيار.</li>
                                    </ol>
                                </p>

                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>ب- استخدام موقع الإنترنت</p></h5>
                                <p className="text_rtl">
                                    <ol>
                                        <li>١- تطبق هذه الأحكام والشروط وأية سياسات أخرى مشار إليها في هذه الأحكام والشروط  وما فيها أية سياسات أو وثائق يتم تزويد رابط لها من هذه الأحكام والشروط (ويشار إليها جميعاً بعبارة “الشروط",على استخدامك ودخولك إلى موقع الإنترنت وأي موقع أو تطبيق آخر يسمح لك بتقديم طلب مع عطور مكاج لأية منتجات وخدمات ) مثل المواقع والتطبيقات كونها “التطبيقات” لغرض هذه الشروط(بما فيها جميع الطلبات المقدمة من قبلك لأية منتجات أو خدمات نوفرها لك للشراء عبر الإنترنت و/أو التطبيقات. كما يقتضي السياق، فإن الإشارة إلى “الموقع” في هذه الشروط تشمل أيضاً التطبيقات حسب مقتضى الحال. بالدخول إلى هذا الموقع و/أو التطبيقات فإنك توافق على هذه الأحكام والشروط ولذلك ننصحك بقراءة هذه الشروط بعناية وحفظها أو طباعة نسخة من هذه الأحكام والشروط للرجوع إليها في المستقبل. إن كنت لا توافق على هذه الشروط، يجب عليك التوقف عن استعمال ودخول الموقع أو التطبيق المعني. يرجى أخذ العلم أن جميع الخيارات المتوفرة على موقعنا قد لا تكون متوفرة على أي تطبيق أو الموقع الذي يتم الدخول إليه باستخدام جهاز نقال.</li>
                                        <li>٢- يرجى أخذ العلم أن هذه الشروط لا تؤثر على حقوقك القانونية كمستهلك.</li>
                                        <li>www.makkajperfume.com  لن تتعامل أو تزود أية خدمات أو منتجات إلى أي دول تطبق عليها عقوبات مكتب مراقبة الأصول الأجنبية وفقاً لقانون الإمارات العربية المتحده.</li>
                                        <li>٤- أنت توافق أن المعلومات التي تزودها عند التسجيل في الموقع ليست خاطئة وهي صحيحة ودقيقة من جميع النواحي وسوف تخبرفريق خدمة العملاء بأية تغييرات في تلك المعلومات.</li>
                                        <li>٥- يحق لنا تغيير أو سحب أو وقف الدخول إلى الموقع كلياً أو جزئياً وبشكل مؤقت أو دائم مع أو بدون إشعار مسبق وبدون تحمل أية مسؤولية تجاهك.</li>
                                        <li>٦- نقبل الدفعات عبر الإنترنت باستخدام بطاقات فيزا وماستركارد الائتمانية/البطاقات المصرفية في العملة المطلوبة، الدرهم الإماراتي/الريال السعودي. إذا تم اختيار “البطاقة الائتمانية عبر الإنترنت” كوسيلة الدفع فإن التفاصيل في هوية العميل (هوية حامل البطاقة) يجب أن تطابق تماماً معلومات البطاقة الائتمانية المستخدمة لدفع قيمة المشتريات.</li>
                                        <li>٧- قد يشمل الموقع روابط لمواقع أو مصادر أخرى المواقع المرتبطة (عطور مكاج لا تملك السيطرة على محتوى المواقع المرتبطة وأنت توافق أنه إذا دخلت إلى موقع مترابط باستخدام رابط من موقعنا، فإن عطور مكاج ليست مسؤولة عن توفر المواقع المرتبطة وليست مسؤولة بأي شكل من الأشكال عن محتوى المواقع المرتبطة، بما في ذلك دون تحديد( أية بضائع أو خدمات متوفرة من هذه المواقع المرتبطة، إعلانات أخرى أو محتوى آخر على هذه المواقع المرتبطة أو استخدام هذه المواقع المرتبطة لمعلوماتك الشخصية. علاوة على ذلك، لن تكون عطور مكاج مسؤولة عن أي جرم أو ضرر أو خسارة ناتجة عن أو مرتبطة باستخدام أو الاعتماد على هذه المواقع المرتبطة أو المحتوى الموجود فيها.</li>
                                        <li>٨- يحق عطور مكاج رفض دخولك إلى الموقع في أي وقت حسب تقديرها المطلق. من الأمثلة التي قد نرفض دخولك فيها مثلا عندما نعتقد أن استخدامك للموقع فيه مخالفة لهذه الشروط أو أي قانون أو حقوق أي طرف ثالث أو لا يحترم الآخرين.</li>
                                        <li>٩- أي مواد تقوم بتحميلها على الموقع للنشر تعتبر غير سرية وغير مملوكة ولدينا الحق في استخدام، نسخ، توزيع، إعادة إنتاج، استغلال، تعديل و/أو إفصاح هذه المواد لأطراف ثالثة لأي غرض. لدينا أيضاً الحق في الكشف عن هويتك لأي طرف ثالث يدعي أن أية مادة تم نشرها أو تحميلها من قبلك على موقعنا تشكل انتهاكاً لحقوقه في الملكية الفكرية أو حقه في الخصوصية.</li>
                                        <li>١٠- لن تكون عطور مكاج  مسؤولة تجاهك أو تجاه أي طرف ثالث عن محتوى أو دقة أية مواد يتم نشرها من قبلك أو من قبل أي مستخدم آخر للموقع وأنت توافق بموجب ذلك أن تكون مسؤولاً تجاه عطور مكاج وأن تعوض عطور مكاج وتعمل على بقاء عطور مكاج معوضة عن جميع التكاليف، الأضرار، النفقات، الخسائر والالتزامات التي تتحملها عطور مكاج نتيجة لأية مطالبة تتعلق باستخدامك للموقع.</li>
                                        <li>١١- يحق عطور مكاج إزالة أية مواد تنشرها على الموقع حسب التقدير المطلق لعطور أجمل.</li>
                                    </ol>
                                </p>
                                <h5><p className="text_rtl" style={{ color: '#c69736' }}>ج- شراء المنتجات</p></h5>
                                <ol>
                                    <h6><p className="text_rtl" style={{ color: '#c69736' }}><li>١- قبول الطلبات</li></p></h6>
                                    <p className="text_rtl">
                                        <ol>
                                            <li>١-١ جميع المعلومات على الموقع هي دعوة للتعامل فقط وليست عرضاً أو عقداً من طرف واحد. أنت توافق أن طلبك هو عرض لشراء المنتجات المدرجة في طلبك المنتجاتمنا حسب الشروط. جميع الطلبات المقدمة من طرفك تخضع للقبول من طرفنا. قد نقرر عدم قبول طلبك حسب تقديرنا لأي سبب كان بدون أية مسؤولية تجاهك. هذه بعض الأمثلة للحالات التي قد لا نقبل فيها طلبك:</li>
                                            <ol>
                                                <li style={{ listStyleType: 'lower-alpha' }}>ا- ( إذا كانت المنتجات ظاهرة في الموقع ولكنها غير متوفرة أو تم تسعيرها بشكل خاطئ أو وصفها بشكل خاطئ) </li>
                                                <li style={{ listStyleType: 'lower-alpha' }}>ب- ( إن لم نستطع الحصول على موافقة على دفعتك؛)</li>
                                                <li style={{ listStyleType: 'lower-alpha' }}>ج- ( إذا طلبت كميات متعددة من منتج واحد حيث كان من المفروض أن يتم شحن هذه المنتجات إلى عميل أو عنوان مختلف للتسليم؛)</li>
                                                <li style={{ listStyleType: 'lower-alpha' }}>د- ( إذا كانت قيود الشحن مطبقة على المنتج؛ أو إذا كان عنوان التسليم الذي أعطيته هو عنوان كيان أو فرد يزود خدمات الشحن.</li>
                                            </ol>
                                            <li>تتوفر معلومات إضافية في بنود وجهات الشحن، الوزن، التكاليف وأوقات التسليم.</li>
                                            <li>١-٣ عند تقديم الطلب لأول مرة، قد نطلب منك أو قد نعرض عليك خيار التسجيل معنا وإكمال حقول مطلوبة معينة على استمارة الطلب. قد نزودك و/أو نطلب منك استخدام تحديدات وكلمات سر ووسائل أخرى لكي تكون قادراً على الدخول إلى مناطق معينة من الموقع، مثل قسم My Account يجب عليك الإلتزام بجميع التعليمات الأمنية و/ التوصيات المعطاة من قبلنا وتخبرنا فوراً إذا علمت أو شككت بأي استخدام غير مصرح به للدخول الآمن أو إذا أصبح الدخول الآمن متوفراً لطرف غير مصرح به. دون المساس بحقوقنا ووسائل الانتصاف الأخرى، يحق لنا تعليق دخولك إلى الموقع دون أية مسؤولية تجاهك، إذا وجدنا حسب رأينا المنطقي أن مثل هذا الإجراء ضروري لحماية الموقع.</li>
                                            <li>١-٤ قبل تقديم طلبك، ستتاح لك الفرصة لمراجعة اختيارك، التأكد من السعر الإجمالي لطلبك وتصحيح أية أخطاء.</li>
                                            <li>١-٥ نحن لا نقبل الطلبات حيث يكون عنوان التسليم المعطى غير لائق. في حال قبلنا أي طلب وأصبحنا على علم في وقت لاحق أن عنوان التسليم غير لائق لمثل هذا الطلب، يحق لنا إلغاء ذلك الطلب مع إخطارك بواسطة البريد الإلكتروني أو الهاتف.</li>
                                        </ol>
                                    </p>

                                    <h6><p className="text_rtl" style={{ color: '#c69736' }}><li>٢- الأسعار</li></p></h6>
                                    <p className="text_rtl">
                                        <ol>
                                            <li>٢-١ جميع أسعار المنتجات على الموقع هي أسعار المنتجات فقط. إعرف المزيد حول وجهات الشحن، التكاليف وأوقات التسليم.</li>
                                            <li>٢-٢ عطور مكاج قد تغير أسعار المنتجات المدرجة في الموقع في أي وقت وبدون أي إشعار ولكن مثل هذه التغييرات لا تنطبق على المنتجات التي وصلك تأكيد الإرسال لها.</li>
                                        </ol>
                                    </p>

                                    <h6><p className="text_rtl" style={{ color: '#c69736' }}><li>٣- شروط الدفع</li></p></h6>
                                    <p className="text_rtl">
                                        <ol>
                                            <li>٣-١ ستكون التكلفة الإجمالية لطلبك سعر شراء المنتجات )الذي تدفعه إلينا(إضافة إلى أية رسوم للتسليم. إعرف المزيد حول وجهات الشحن، التكاليف وأوقات التسليم.</li>
                                            <li>٣-٢ يمكنك إيجاد المزيد من المعلومات حول وسائل الدفع التي نقبلها وتفاصيل متى سيتم خصم قيمة الطلب من حسابك في قسم الدفع.</li>
                                            <li>٣-٤ أنت تؤكد أن البطاقة الائتمانية/المصرفية أو وسيلة الدفع المستخدمة هي ملكك وأن جميع التفاصيل التي زودتنا بها في هذا الشأن وتشمل ولكنها لا تقتصر على الإسم وتفاصيل العنوان هي كاملة وصحيحة ودقيقة. أنت تؤكد أيضاً أن البطاقة الائتمانية/المصرفية سارية المفعول وتفاصيل الدفع المعطاة صحيحة. يخضع جميع حاملي البطاقات الائتمانية/المصرفية وأصحاب حسابات الدفع لعمليات التحقق من الصحة والحصول على إذن من الجهة المصدرة للبطاقة أو المزودة لوسيلة الدفع. إذا رفضت الجهة المصدرة للبطاقة أو المزودة لوسيلة الدفع إعطاء أذن بالدفع، لن نقبل طلبك ولسنا مسؤولين عن أي تأخير أو عدم تسليم ولن نكون ملزمين بإبلاغك عن سبب الرفض.</li>
                                            <li>٣-٥ نحن لسنا مسؤولين عن أية رسوم أو مبالغ أخرى قد تطبقها الجهة المصدرة للبطاقة أو البنك أو الجهة المزودة لوسيلة الدفع نتيجة لقيامنا بإجراءات الدفع بالبطاقة الائتمانية/المصرفية أو وسائل الدفع الأخرى وفقاً لطلبك.</li>
                                            <li>٣-٦ إن لم تكن بطاقتك الائتمانية/المصرفية أو وسيلة الدفع بعملة شرائك الموضحة على الموقع، يمكن أن يتم قيد السعر النهائي بعملة بطاقتك أو حسابك. يتم احتساب هذا السعر النهائي وخصمه من قبل الجهة المصدرة للبطاقة أو البنك أو الجهة المزودة لوسيلة الدفع وبالتالي لن نكون مسؤولين عن أية تكاليف، مصاريف، رسوم أو إلتزامات أخرى قد تتحملها نتيجة لقيام الجهة المصدرة لبطاقتك أو المزودة لوسيلة الدفع بخصم المبلغ بعملة مختلفة عن عملة شرائك الموضحة على الموقع.</li>
                                        </ol>
                                    </p>

                                    <h6><p className="text_rtl" style={{ color: '#c69736' }}><li>٤- الفواتير</li></p></h6>
                                    <p className="text_rtl">
                                        <ol>
                                            <li>٤-١ حيث نختار، أو يقتضي القانون المطبق، أن نصدر أو نوفر فاتورة، نحتفظ بالحق لإصدار أو توفير فواتير إلكترونية وأنت توافق على هذا الشكل من الفواتير.</li>
                                        </ol>
                                    </p>

                                    <h6><p className="text_rtl" style={{ color: '#c69736' }}><li>٥-التسليم والمخاطر</li></p></h6>
                                    <p className="text_rtl">
                                        <ol>
                                            <li>٥-١ نقوم حالياً بتسليم المنتجات فقط ضمن الإمارات العربية المتحدة والمملكة العربية السعودية.</li>
                                            <li>٥-٢ بعد أن تختار طريقة التسليم المفضلة بالنسبة لك من الطرق المتوفرة لوجهة الشحن المختارة وفي حال قبولنا لطلبك، سيتم تجهيز طلبك لإرساله وفقاً لأوقات التسليم المحددة في وجهات الشحن، الوزن، التكاليف وأوقات التسليم. يرجى مشاهدة منطقة الخدمات وقيودها. الطلبات التي يتم استلامها بعد أوقات التوقف المحددة أو مواعيد “آخر طلب” أو في يوم ليس من أيام العمل )الذي هو أي يوم تفتح فيه البنوك في الإمارات العربية المتحدة ولا يكون يوم جمعة أو سبت( يتم تجهيزها في يوم العمل التالي. يتم حساب أوقات التسليم التقديرية من تاريخ تجهيز الطلب.</li>
                                            <li>٥-٣ كجزء من عملية إجراء الطلب، سوف تتلقى إتصال لتسليم البضائع بواسطة   سمسا. عند القيام بالطلب، سوف تتلقى عدة تأكيدات بالبريد الإلكتروني فيما يتعلق بطلبك. بالنسبة لكل هذه التأكيدات وأية تأكيدات مماثلة أخرى بالبريد الإلكتروني، يرجى أخذ العلم أن المراجع المتعلقة بالشحن، خدمات التسليم السريع وأية مصطلحات مشابهة أخرى تكون متعلقة بالخدمات التي تقدمها   سمسا.</li>
                                            <li>٥-٤ سيتم تخزين معلومات التسليم مثل إسم العميل، العنوان ورقم الاتصال لأغراض التسليم. وسيتم تخزين معلومات شخصية تعريفية أخرى.</li>
                                        </ol>
                                    </p>

                                    <h6><p className="text_rtl" style={{ color: '#c69736' }}><li>٦- الإلغاء والإعادة</li></p></h6>
                                    <p className="text_rtl">
                                        <ol>
                                            <li>٦-١ في حال رغبت بإلغاء أو إعادة أية منتجات، يمكنك القيام بذلك فقط بموجب سياستنا للإعادة والإلغاء. هذه السياسة للإعادة والإلغاء لا تؤثر على حقوقك القانونية كمستهلك في التشريع المعمول به للبيع عن بعد أو أنظمة التجارة     الإلكترونية في المنطقة التي يتم شحن المنتج إليها )الأنظمة</li>
                                            <li>٦-٢ حيث يمكنك إرجاع منتج بموجب الأنظمة، سنصدر لك إعادة القيمة الكاملة ولكن يجب عليك إعادة المنتج على نفقتك الخاصة )إذا كنت قد استلمت المنتج(، ما لم تنص سياستنا للإعادة والإلغاء على خلاف ذلك.</li>
                                        </ol>
                                    </p>

                                    <h6><p className="text_rtl" style={{ color: '#c69736' }}><li>٧- متطلبات العمر</li></p></h6>
                                    <p className="text_rtl">
                                        <ol>
                                            <li>٧-١ إذا طلبت منتجاً يطبق عليه شرط الحد الأدنى للعمر، فأنت تؤكد من خلال طلب المنتج أنك تبلغ العمر القانوني المطلوب. إذا اعتقدنا بشكل منطقي أنك غير مؤهل قانونياً بطلب منتج ما، فإننا نحتفظ بالحق لإلغاء طلبك.</li>
                                        </ol>
                                    </p>
                                </ol>
                            </div>
                        </div>

                        <div style={{ textAlign: 'center', marginTop: '10vh' }}>
                            <p className="contact_us" onClick={() => window.location.href = '/contact'}>للحصول على أي استفسارات أخرى ، يرجى الاتصال بنا</p>
                            <p>اشترك في نشرتنا الإخبارية واحصل على العروض الحصرية</p>
                        </div>
                    </div>
                </div>
    )
};