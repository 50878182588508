/* eslint-disable react-hooks/exhaustive-deps */
import "./navbar.css";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import axios from "axios";
import { Translator, Translate } from "react-auto-translate";

const Navbar = () => {
    const location = useLocation();
    const [category, setcategory] = useState([]);


    useEffect(async () => {
        const y = await axios.get(`${process.env.REACT_APP_API_URL}procategory`);
        setcategory(y.data.slice(0, 6));
    }, []);

    return (
        <div className="navbar" id="main-nav">
            <ul>
                <li className="nav1">
                    <Link
                        to="/"
                        style={{
                            color:
                                location.pathname == "/" || location.pathname == "/home"
                                    ? "white"
                                    : "#d6d6d6",
                            background:
                                location.pathname == "/" || location.pathname == "/home"
                                    ? "#775b20"
                                    : "transparent",
                        }}
                    >
                        {localStorage.getItem('translate') === 'en' ? 'Home' : 'المنزل'}
                    </Link>
                </li>

                <li className="nav1">
                    <span class="dropdown">
                        <button class="dropbtn">
                            {localStorage.getItem('translate') === 'en' ? 'Collection' : 'المجموعة'}
                        </button>
                        <span class="dropdown-content">
                            {category.map((data) => {
                                return (
                                    data.nav_include == true && (
                                        <Link to={`/category/${data.title}`}>
                                            {localStorage.getItem('translate') === 'en' ? data.title : data.title_ar}
                                        </Link>
                                    )
                                );
                            })}
                        </span>
                    </span>
                </li>

                <li className="nav1">
                    <Link
                        to="/products"
                        style={{
                            color: location.pathname == "/products" ? "white" : "#d6d6d6",
                            background:
                                location.pathname == "/products" ? "#775b20" : "transparent",
                        }}
                    >
                        {localStorage.getItem('translate') === 'en' ? 'Products' : 'المنتجات'}
                    </Link>
                </li>
                <li className="nav1">
                    <Link
                        to="/about-us"
                        style={{
                            color: location.pathname == "/about-us" ? "white" : "#d6d6d6",
                            background:
                                location.pathname == "/about-us" ? "#775b20" : "transparent",
                        }}
                    >
                        {localStorage.getItem('translate') === 'en' ? 'About Us' : 'للمعلومات'}
                    </Link>
                </li>
                <li className="nav1">
                    <Link
                        to="/contact"
                        style={{
                            color: location.pathname == "/contact" ? "white" : "#d6d6d6",
                            background:
                                location.pathname == "/contact" ? "#775b20" : "transparent",
                        }}
                    >
                        {localStorage.getItem('translate') === 'en' ? 'Contact us' : 'للتواصل معنا'}
                    </Link>
                </li>
            </ul>
        </div>
    );
};
export default Navbar;
