import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addproductcart } from "../../actions/cartactions";
import "./product.css";
import getSymbolFromCurrency from "currency-symbol-map";
import { Link } from "react-router-dom";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { addproductwishlist } from "../../actions/wishlistaction";
import { useState } from "react";

export default function Product(props) {
    const { price, product, inWishlist } = props;
    const dispatch = useDispatch();
    const [color, setcolor] = useState("");
    const [text, settext] = useState("");

    const addval = () => { };

    let defaultLanguage = localStorage.getItem('translate')

    return (
        <div key={product._id} className="card">
            {
                product.stock[price] <= 0
                    ? <div className="outOfStockOverlay" onClick={() => window.location.href = `/details/${product._id}/${product.title}`}>
                        <button className="button">Out of stock</button>
                    </div>
                    : ''
            }

            <p style={{ display: "none" }}>{text}</p>

            {
                JSON.parse(localStorage.getItem("login")) === true
                    ? inWishlist === true
                        ? <AiFillHeart
                            style={{
                                position: "absolute",
                                margin: "1rem",
                                color: "gold",
                                zIndex: "99999",
                            }}
                            onClick={() => window.alert("product already in wishlist")}
                        />
                        : <AiOutlineHeart
                            style={{
                                position: "absolute",
                                margin: "1rem",
                                color: "gold",
                                zIndex: "99999",
                            }}
                            onClick={() => {
                                dispatch(
                                    addproductwishlist(
                                        product.title,
                                        product.arabictitle,
                                        product.image[0],
                                        product.description,
                                        product.arabicdescription,
                                        product.size[0].value,
                                        product.dimensions || 'na',
                                        product._id,
                                        product.category,
                                        JSON.parse(localStorage.getItem("userInfo")).user,
                                        product.discountprice,
                                        product.price,
                                        price
                                    )
                                );
                                window.alert("product added to wishlist")
                                window.location.reload()
                            }}
                        />
                    : <AiOutlineHeart
                        style={{
                            position: "absolute",
                            margin: "1rem",
                            color: "gold",
                            zIndex: "99999",
                        }}
                        onClick={() => window.alert("Please login to add product to wishlist")}
                    />
            }



            <Link to={`/details/${product._id}/${product.title}`}>
                <img src={product.image[0]} alt="perfume" />
            </Link>

            <div className="card-body">
                <Link to={`/details/${product._id}/${product.title}`}>
                    <h2>{localStorage.getItem('translate') === 'en' ? product.title : product.arabictitle}</h2>
                </Link>

                <div className="price">
                    {
                        localStorage.getItem("translate") === "en"
                            ? localStorage.getItem("currency_code")
                            : localStorage.getItem("currency_icon")
                    }

                    {" "}

                    {
                        product.discountprice === undefined || product.price === undefined || product.discountprice === null || product.price === null
                            ? ''
                            : product.discountprice[price] === product.price[price]
                                ? product.price[price]
                                : product.price[price] > product.discountprice[price]
                                    ? <>
                                        <span style={{ textDecoration: 'line-through' }}>{product.price[price]}</span>
                                        {" "}
                                        <span>{product.discountprice[price]}</span>
                                        {" "}
                                        ({(((product.price[price] - product.discountprice[price]) / product.price[price]) * 100).toFixed(2)} % off)
                                    </>
                                    : product.price[price]
                    }
                </div>
                {
                    product.stock[price] <= 0
                        ? ''
                        : JSON.parse(localStorage.getItem("login")) === true
                            ? <div
                                className="add-to-cart-button"
                                onClick={() => {
                                    dispatch(
                                        addproductcart(
                                            product.title,
                                            product.arabictitle,
                                            product.image[0],
                                            product.description,
                                            product.price[price],
                                            product.size[0].value,
                                            product.dimensions,
                                            1,
                                            product._id,
                                            JSON.parse(localStorage.getItem("userInfo")).user,
                                            product.discountprice[price],
                                            price
                                        )
                                    )

                                    window.alert(defaultLanguage === 'en' ? "Product is added to cart" : 'يضاف المنتج الى سلة التسويق')
                                    window.location.href = '/cart';
                                }}
                            >
                                <button>{defaultLanguage === 'en' ? 'ADD TO CART' : 'اضف الى السلة'}</button>
                            </div>
                            : <div className="add-to-cart-button"
                                onClick={() => {
                                    if (!localStorage.getItem("guestid")) {
                                        let y = makeid(24);
                                        localStorage.setItem("guestid", y);
                                    }
                                    dispatch(
                                        addproductcart(
                                            product.title,
                                            product.arabictitle,
                                            product.image[0],
                                            product.description,
                                            product.price[price],
                                            product.size[0].value,
                                            product.dimensions,
                                            1,
                                            product._id,
                                            localStorage.getItem("guestid"),
                                            product.discountprice[price],
                                            price
                                        )
                                    )

                                    window.alert(defaultLanguage === 'en' ? "Product is added to cart" : 'يضاف المنتج الى سلة التسويق')
                                    window.location.href = '/cart';

                                }}
                            >
                                <button>{defaultLanguage === 'en' ? 'ADD TO CART' : 'اضف الى السلة'}</button>
                            </div>
                }
            </div>

        </div>

    );
}


function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    result.toString();
    return result;
}