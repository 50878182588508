import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderPost, shippingaddressget } from "../../actions/orderaction";
import { listPrice } from "../../actions/productaction";
import { Rightinfo } from "../../components/rightinfo/rightinfo";
import "./paymentscreen.css";
import store from "../../store";
import { Link, useParams } from "react-router-dom";
import $ from "jquery";
import { setcoupon } from "../../actions/cartactions";
import queryString from "query-string";
import { useHistory } from "react-router";
import { Translate } from "react-auto-translate";

export const PayTabSuccess = (props) => {
    const dispatch = useDispatch();
    const addresses = useSelector((state) => state.shipping);
    const { status } = useSelector((state) => state.order);

    const { address } = addresses;
    const prices = useSelector((state) => state.priceList);
    const { price } = prices;
    const [r, setr] = useState(null);
    const history = useHistory();
    const coupons = useSelector((state) => state.getcoupon);
    const { coupon } = coupons;

    const { id } = useParams();

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("login")) === true) {
            dispatch(
                shippingaddressget(JSON.parse(localStorage.getItem("userInfo")).user)
            );
        } else {
            dispatch(shippingaddressget(localStorage.getItem("guestid")));

        }

        dispatch(listPrice());
    }, [dispatch]);

    useEffect(() => {
        if (coupon != null) {
            localStorage.setItem("coupon", JSON.stringify(coupon));
        }
    }, [coupon]);


    localStorage.setItem("curr", price);

    useEffect(() => {
        let shipping = parseFloat(localStorage.getItem('shipping'))
        let total = parseFloat(localStorage.getItem('total'))
        let tax = parseFloat(localStorage.getItem('tax'))

        if (JSON.parse(localStorage.getItem("login")) === true) {

            store.dispatch(
                orderPost(
                    JSON.parse(localStorage.getItem('userInfo')).user,
                    "Paytab",
                    {
                        id: "",
                        status: "",
                        update_time: "",
                        email_address: ""
                    },
                    tax,
                    total - tax > 200 ? 0 : shipping,
                    total,
                    "",
                    true,
                    Date.now(),
                    "",
                    id
                )
            );
        } else {
            store.dispatch(
                orderPost(
                    localStorage.getItem("guestid"),
                    "Paytab",
                    {
                        id: "",
                        status: "",
                        update_time: "",
                        email_address: ""
                    },
                    tax,
                    total - tax > 200 ? 0 : shipping,
                    total,
                    "",
                    true,
                    Date.now(),
                    "",
                    id
                )
            );
        }
        if (localStorage.getItem('coupon') !== '') {
            store.dispatch(setcoupon(JSON.parse(localStorage.getItem('coupon'))));
            localStorage.setItem('coupon', '');
        }
        localStorage.setItem('apply', false);
    }, []);

    useEffect(() => {
        console.log(id)
        console.log(status)
        if (status === true) {
            localStorage.setItem('tran_ref', id)
            setTimeout(() => {
                if (JSON.parse(localStorage.getItem("login")) === true) {
                    axios.post(`${process.env.REACT_APP_API_URL}postmail`, {
                        email: JSON.parse(localStorage.getItem("userInfo")).email,
                        user: JSON.parse(localStorage.getItem("login")) === true ? JSON.parse(localStorage.getItem("userInfo")).user : localStorage.getItem("guestid")
                    });
                } else {
                    axios.post(`${process.env.REACT_APP_API_URL}postmail`, {
                        email: localStorage.getItem("email"),
                        user: JSON.parse(localStorage.getItem("login")) === true ? JSON.parse(localStorage.getItem("userInfo")).user : localStorage.getItem("guestid")
                    });
                }

                window.location.replace("/orders");
            }, 3000);
        }
    }, [status]);

    return (
        <div>
            Payment Successfull
        </div>
    );
};