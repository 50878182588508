import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { allorderlist, orderlist } from "../../actions/orderaction";
import { Sidebar } from "../../components/sidebar/sidebar";
import "./myorders.css";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";

import { defaultLanguage, getCurrency } from "../../helpers"

export const MyOrders = (props) => {
    const dispatch = useDispatch();
    const orderslist = useSelector((state) => state.allorders);

    const { orders } = orderslist;
    const [filterorders, setfilterorders] = useState(orders);
    const [isActive, setActive] = useState(false);
    const [activeone, setactiveone] = useState(1);
    useEffect(() => {
        if (JSON.parse(localStorage.getItem("login")) === true) {

            dispatch(allorderlist(JSON.parse(localStorage.getItem("userInfo")).user));
        }
        else {
            dispatch(allorderlist(localStorage.getItem("guestid")));

        }
    }, [dispatch]);

    useEffect(() => {
        setfilterorders(orders);
    }, [orders]);

    const cancelOrderFunction = (id) => {
        let apiUrl = process.env.REACT_APP_API_URL + 'cancel-order';

        const reqBody = {
            "id": id,
            "reason": "cancelled"
        }

        axios.post(apiUrl, reqBody)
            .then((response) => {
                console.log(response)
                if (response.data.message === 'Order cancelled successfully') {

                    confirmAlert({
                        title: defaultLanguage === "en" ? "Info" : "معلومات",
                        message: defaultLanguage === "en"
                            ? <>Your order cancelled successfully, you will receive refund within 7 to 10 working days.</>
                            : <>تم إلغاء طلبك بنجاح، وستسترد أموالك خلال 7 إلى 10 أيام عمل.</>
                        ,
                        buttons: [
                            {
                                label: defaultLanguage === "en" ? "Ok" : "نعم",
                                onClick: () => {
                                    window.location.href = '/ordercancel';
                                }
                            }
                        ]
                    });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const cancelOrderWarning = (id) => {
        confirmAlert({
            title: defaultLanguage === "en" ? "Info" : "معلومات",
            message: defaultLanguage === "en"
                ? <>Are you sure to cancel this order?{id}</>
                : <>هل أنت متأكد من إلغاء هذا الطلب؟{id}</>
            ,
            buttons: [
                {
                    label: defaultLanguage === "en"
                        ? "Back"
                        : "خلف"
                    ,
                    onClick: () => { }
                },
                {
                    label: defaultLanguage === "en"
                        ? "Ok"
                        : "نعم"
                    ,
                    onClick: () => {
                        cancelOrderFunction(id);
                    }
                }
            ]
        });
    }

    const isCancelable = (order) => {
        const createdAt = new Date(order.createdAt);
        const currentTime = new Date();

        const timeDifferenceInMilliseconds = currentTime - createdAt;
        const timeDifferenceInMinutes = Math.floor(timeDifferenceInMilliseconds / (1000 * 60));

        if (timeDifferenceInMinutes > 120) {
            return false;
        } else {
            return true;
        }
    }

    return orders != null && filterorders != null ? (
        <div>
            <h1 className="breadcrumb-css">
                {
                    defaultLanguage === "en"
                        ? 'MY ORDERS'
                        : 'طلباتي'
                }
            </h1>
            <div className="my-account">
                <div className="sidebar-left">
                    {" "}
                    <Sidebar />
                </div>

                <div className="myorder-right">

                    <div>
                        <ul className="myorder-ulbar">
                            <li className="active1"
                                style={{
                                    background: activeone === 1 ? "#c69736" : "transparent"
                                }}
                                onClick={() => {
                                    setfilterorders(orders.filter((item) => item));
                                }}
                            >
                                <button
                                    style={{
                                        background: activeone === 1 ? "#c69736" : "transparent",
                                        border: "none",
                                        padding: "0",
                                        color: "#fff",
                                    }}
                                    onClick={() => {
                                        setactiveone(1);

                                    }}
                                >
                                    {
                                        defaultLanguage === "en"
                                            ? "All Orders"
                                            : "جميع الطلبات"
                                    }
                                </button>
                            </li>
                            <li className="active1"
                                style={{
                                    background: activeone === 2 ? "#c69736" : "transparent",
                                }}
                                onClick={() => {
                                    setfilterorders(
                                        orders.filter((items) => items.isDelivered === false)
                                    );
                                }}
                            >
                                <button
                                    style={{
                                        background: activeone === 2 ? "#c69736" : "transparent",
                                        border: "none",
                                        padding: "0",
                                        color: "#fff",
                                    }}
                                    onClick={() => {
                                        setactiveone(2);

                                    }}
                                >
                                    {
                                        defaultLanguage === "en"
                                            ? "Open Orders"
                                            : "الأوامر المفتوحة"
                                    }
                                </button>
                            </li>
                            <li
                                style={{
                                    background: activeone === 3 ? "#c69736" : "transparent"
                                }}
                                onClick={() => {

                                    setfilterorders(
                                        orders.filter((items) => items.isDelivered !== false)
                                    );
                                }}
                            >
                                <button
                                    style={{
                                        background: activeone === 3 ? "#c69736" : "transparent",
                                        border: "none",
                                        padding: "0",
                                        color: "#fff",
                                    }}
                                    onClick={() => {
                                        setactiveone(3);

                                    }}
                                >
                                    {
                                        defaultLanguage === "en"
                                            ? "Completed Orders"
                                            : "الطلبات المكتملة"
                                    }
                                </button>
                            </li>
                            <li
                                style={{
                                    background: activeone === 4 ? "#c69736" : "transparent"
                                }}
                                onClick={() => {
                                    setfilterorders(
                                        orders.filter((items) => items.isCancelled !== false)
                                    );
                                }}
                            >
                                <button
                                    style={{
                                        background: activeone === 4 ? "#c69736" : "transparent",
                                        border: "none",
                                        padding: "0",
                                        color: "#fff",
                                    }}
                                    onClick={() => {
                                        setactiveone(4);

                                    }}
                                >
                                    {
                                        defaultLanguage === "en"
                                            ? "Cancel Orders"
                                            : "إلغاء الطلبات"
                                    }
                                </button>
                            </li>
                        </ul>

                        <div className="">
                            <table className="myorder-table">
                                <tr className="myorder-table-th">
                                    <th>{defaultLanguage === "en" ? "ITEM" : ""}</th>
                                    <th>{defaultLanguage === "en" ? "PRICE" : "السعر"}</th>
                                    <th>{defaultLanguage === "en" ? "QTY" : "الكمية"}</th>
                                    <th>{defaultLanguage === "en" ? "STATUS" : "الحالة"}</th>
                                    <th>{defaultLanguage === "en" ? "ORDER DATE" : "تاريخ الطلب"}</th>
                                    <th style={{ textAlign: 'center' }}>{defaultLanguage === "en" ? "ACTION" : "فعل"}</th>
                                </tr>

                                {console.log(filterorders[0])}

                                {filterorders.map((items) => {
                                    const created = items.createdAt
                                    const ordStat = items.isCancelled === true
                                        ? 'cancelled'
                                        : items.orderStatus === null || items.orderStatus === undefined
                                            ? 'Order Placed'
                                            : items.orderStatus.ScanDescription
                                    return items.orderItems.map((item) => {
                                        return (<tr className="myorder-table-tr">
                                            <td className="myorder-table-img">
                                                <Link to={`/orderdetails/${items._id}`}>
                                                    <img src={item.image} alt="perfume" />
                                                </Link>
                                                <h2 style={{ color: "#fff" }}>{defaultLanguage === "en" ? item.title : item.arabictitle}</h2>
                                            </td>
                                            <td className="myorder-table-price">
                                                <p>{getCurrency(item.pricecon)} {" "} {item.discountprice[0]}</p>
                                            </td>
                                            <td className="myorder-table-qty">
                                                <p>{item.count}</p>
                                            </td>
                                            <td>
                                                <p>{ordStat}</p>
                                            </td>
                                            <td>
                                                <p>{created.split('T')[0]}</p>
                                            </td>
                                            <td>
                                                {
                                                    isCancelable(items) === false
                                                        ? ''
                                                        : items.isCancelled === true
                                                            ? ''
                                                            : <button style={{ borderRadius: '10px', border: '0px', backgroundColor: '#c69736' }} onClick={() => cancelOrderWarning(items._id)}>Cancel Order</button>
                                                }
                                            </td>
                                        </tr>
                                        );
                                    });
                                })}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div style={{ color: "white" }}>
            {
                defaultLanguage === "en"
                    ? 'No Orders yet'
                    : 'لا توجد طلبات حتى الآن'
            }
        </div>
    );
};