import { QRCodeCanvas } from "qrcode.react";

const QrCode = (props) => {
    const qrcode = (
        <QRCodeCanvas
            id="qrCode"
            value={props.invoice}
            size={150}
            bgColor={"#ffffff"}
            level={"H"}
        />
    );

    const handleButtonClick = () => {
        window.open(props.invoice, '_blank');
    }

    return (
        <div className="qrcode__container" onClick={() => handleButtonClick()} style={{
            backgroundColor: '#ffffff',
            padding: '10px 10px 5px 10px',
            borderRadius: '5px'
        }}>
            <p style={{ color: '#000000', textAlign: 'center' }}>Invoice</p>
            <div>{qrcode}</div>
        </div>
    );
};

export default QrCode;