/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useHistory } from "react-router-dom";
import { IoEarthOutline } from "react-icons/io5";
import { MdSearch } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { BsBag } from "react-icons/bs";
import "./topbar.css";
import { IoIosClose } from "react-icons/io";
import { useEffect, useState } from "react";
import { logins, register, resetpass } from "../../actions/useraction";
import { useDispatch, useSelector } from "react-redux";
import { GoogleLogin } from "react-google-login";
import {
    LOGIN_DISPLAY_FLEX,
    LOGIN_DISPLAY_NONE,
    USER_LOGOUT,
} from "../../constant/user";
import { FiLogOut } from "react-icons/fi";
import { listPrice } from "../../actions/productaction";
import getSymbolFromCurrency from "currency-symbol-map";
import {
    Navbar,
    Nav,
    Container,
    NavDropdown,
    Modal,
    Button,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { cartlist } from "../../actions/cartactions";
import { FaFacebook } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";
import { Translate } from "react-auto-translate";
import useGeoLocation from "react-ipgeolocation";
import logo from "../../assets/makkaj-website-logo.png";
import axios from "axios";
import { getCurrencySymbol, defaultLanguage, getShippingCharge } from '../../helpers';

const Topbar = () => {
    const [displays, setdisplay] = useState("none");
    const [email, setemail] = useState("");
    const [pass, setpass] = useState("");
    const userLogin = useSelector((state) => state.userLogin);
    const { loading, error, userInfo, logined } = userLogin;
    const userRegister = useSelector((state) => state.userRegister);
    const { registered, regError, user } = userRegister;
    const dispatch = useDispatch();
    const history = useHistory();
    const prices = useSelector((state) => state.priceList);
    const { price } = prices;
    const [fname, setfname] = useState("");
    const [lname, setlname] = useState("");
    const [email2, setemail2] = useState("");
    const [phone, setphone] = useState("");
    const [pass2, setpass2] = useState("");
    const [open, setopen] = useState(false);
    const [confpass, setconfpass] = useState("");
    const cartList = useSelector((state) => state.addCart);
    const { cart } = cartList;
    const getdisplay = useSelector((state) => state.getdisplay);
    const { display } = getdisplay;
    const [modalShow, setModalShow] = useState(false);

    const location = useGeoLocation();

    useEffect(() => {
        console.log(localStorage.getItem("userInfo"))
        if (JSON.parse(localStorage.getItem("login")) === true && localStorage.getItem("userInfo") !== null) {
            dispatch(cartlist(JSON.parse(localStorage.getItem("userInfo")).user));
        }
    }, [dispatch]);

    const submit = () => {
        // confirmAlert({
        //     title: "Confirm to submit",
        //     message: "Are you sure to change password.",
        //     buttons: [
        //         {
        //             label: "Yes",
        //             onClick: () => {
        //                 alert("Password change link send to your mail");
        //                 dispatch(
        //                     resetpass(JSON.parse(localStorage.getItem("userInfo")).email)
        //                 );
        //             },
        //         },
        //         {
        //             label: "No",
        //             onClick: () => alert("Click No"),
        //         },
        //     ],
        // });
        window.location.href = '/changepassword'
    };

    useEffect(() => {
        if (userInfo === true) {
            setdisplay("none");
            dispatch({
                type: LOGIN_DISPLAY_NONE,
            });
            localStorage.setItem("login", true);
            window.alert(defaultLanguage === 'en' ? "You have loggedin successfully" : 'لقد قمت بتسجيل الدخول بنجاح')
            window.location.reload()
        }

        console.log({ user })

        if (registered) {
            if (user.data.success === true) {
                window.alert(defaultLanguage === 'en' ? "You have registered successfully, we have sent you a verification email just click on the link sent to verify yourself." : 'لقد قمت بتسجيل الدخول بنجاح')
                document.getElementById("login").style.display = "block";
                document.getElementById("register").style.display = "none";
                document.getElementById("forgetPass").style.display = "none";
            } else {
                window.alert(user.data.message)
                window.location.reload()
            }
        }

        dispatch(listPrice());
        console.log({ userInfo })
    }, [userInfo]);

    const changeHandler = (e) => {
        setemail(e.target.value);
    };
    const changeHandler1 = (e) => {
        setpass(e.target.value);
    };
    const changeHandler2 = (e) => {
        setfname(e.target.value);
    };
    const changeHandler3 = (e) => {
        setlname(e.target.value);
    };
    const changeHandler4 = (e) => {
        setemail2(e.target.value);
    };
    const changeHandler5 = (e) => {
        setphone(e.target.value);
    };
    const changeHandler6 = (e) => {
        setpass2(e.target.value);
    };
    const changeHandler7 = (e) => {
        setconfpass(e.target.value);
    };

    const responseGoogle = (response) => {
    };

    useEffect(async () => {
        if (window.wpwl !== undefined && window.wpwl.unload !== undefined) {
            window.wpwl.unload();
        }

        let country = location.country

        let country_name = localStorage.getItem('country_name')
        let country_code = localStorage.getItem('country_code')
        let country_code_iso3 = localStorage.getItem('country_code_iso3')
        let currency = localStorage.getItem('currency')
        let currency_name = localStorage.getItem('currency_name')
        let currency_icon = localStorage.getItem('currency_icon')

        // let shipping = localStorage.getItem('shipping')
        // let seller = localStorage.getItem('seller')
        // let seller_warehouse = localStorage.getItem('seller_warehouse')

        if (country_name === null || country_code === null || country_code_iso3 === null || currency === null || currency_name === null || currency_icon === null) {
            if (country === "SA") {
                localStorage.setItem('country_name', 'Kingdom of Saudi Arabia')
                localStorage.setItem('country_code', 'SA')
                localStorage.setItem('country_code_iso3', 'KSA')
                localStorage.setItem('currency', 'SAR')
                localStorage.setItem('currency_name', 'Saudi Arabian Riyal')
                localStorage.setItem('currency_icon', 'ر.س')
                localStorage.setItem('currency_code', 'SAR')
            } else if (country === "AE") {
                localStorage.setItem('country_name', 'United Arab Emirates')
                localStorage.setItem('country_code', 'AE')
                localStorage.setItem('country_code_iso3', 'UAE')
                localStorage.setItem('currency', 'AED')
                localStorage.setItem('currency_name', 'United Arab Emirates dirham')
                localStorage.setItem('currency_icon', 'د.إ')
                localStorage.setItem('currency_code', 'AED')
            } else {
                localStorage.setItem('country_name', 'United Arab Emirates')
                localStorage.setItem('country_code', 'AE')
                localStorage.setItem('country_code_iso3', 'UAE')
                localStorage.setItem('currency', 'AED')
                localStorage.setItem('currency_name', 'United Arab Emirates dirham')
                localStorage.setItem('currency_icon', 'د.إ')
                localStorage.setItem('currency_code', 'AED')
            }
            console.log({ country_name, country_code, country_code_iso3, currency, currency_name, currency_icon })
        } else {
            console.log({ country_name, country_code, country_code_iso3, currency, currency_name, currency_icon })
        }

        if (localStorage.getItem('shipping') === null) {
            const shippingCharge = await getShippingCharge(0)
            console.log('shipping', shippingCharge)
        }
    }, []);

    const clearCart = async () => {
        let uid = JSON.parse(localStorage.getItem("login")) !== true
            ? { userId: localStorage.getItem("guestid") }
            : { userId: JSON.parse(localStorage.getItem("userInfo")).user }

        let resp = await axios.post(`${process.env.REACT_APP_API_URL}clearcart`, uid)

        let cn = 0

        while (resp.status === 200 || cn < 100) {
            cn = cn + 1
            console.log(resp)
            window.location.href = process.env.REACT_APP_WEBSITE
            break;
        }
    }

    let defaultLanguage = localStorage.getItem('translate')

    const storeLocalData = async (data) => {
        if (data === "KSA") {
            localStorage.setItem('country_name', 'Kingdom of Saudi Arabia')
            localStorage.setItem('country_code', 'SA')
            localStorage.setItem('country_code_iso3', 'KSA')
            localStorage.setItem('currency', 'SAR')
            localStorage.setItem('currency_name', 'Saudi Arabian Riyal')
            localStorage.setItem('currency_icon', 'ر.س')
            localStorage.setItem('currency_code', 'SAR')
        } else if (data === "UAE") {
            localStorage.setItem('country_name', 'United Arab Emirates')
            localStorage.setItem('country_code', 'AE')
            localStorage.setItem('country_code_iso3', 'UAE')
            localStorage.setItem('currency', 'AED')
            localStorage.setItem('currency_name', 'United Arab Emirates dirham')
            localStorage.setItem('currency_icon', 'د.إ')
            localStorage.setItem('currency_code', 'AED')
        }

        if (localStorage.getItem('shipping') === null) {
            const shippingCharge = await getShippingCharge(0)
            console.log('shipping', shippingCharge)
        }
    }

    const loginFunction = async () => {
        await dispatch(logins(email, pass));
    }

    const registerFunction = async () => {
        if (pass2.length >= 8 && pass2 === confpass) {
            dispatch(
                register(fname, lname, phone, email2, pass2, confpass)
            );
        } else if (pass2 !== confpass) {
            window.alert(
                "Your password and confirm password is not matching"
            );
        } else {
            window.alert(
                "Please insert the password of atleast 8 character"
            );
        }
    }

    return (
        <>
            <div className="marquee-container">
                {
                    defaultLanguage === 'en'
                        ? <p className="marquee">Enjoy FREE SHIPPING on orders over {getCurrencySymbol()} 200!</p>
                        : <p className="marquee"> استمتع بالشحن المجاني للطلبات التي تزيد عن ذلك {getCurrencySymbol()} 200!</p>
                }
            </div>

            <div className="topbar-box">
                <div className="topbar-language">
                    <div className="topbar-currancy"></div>

                    <div className="topbar-currancy-name">
                        <div className="topbar-language-name">
                            <span class="dropdown" style={{ zIndex: 2 }}>
                                <button class="dropbtn">
                                    {price && (
                                        <p>
                                            {localStorage.getItem('country_code_iso3') + " "}
                                            ({
                                                localStorage.getItem("translate") === "en"
                                                    ? localStorage.getItem("currency_code")
                                                    : localStorage.getItem("currency_icon")
                                            })
                                            {
                                                localStorage.getItem('country_code_iso3') === "IND"
                                                    ? (
                                                        <img
                                                            alt=""
                                                            style={{ width: "30px", height: "15px", marginLeft: "5px" }}
                                                            src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png"
                                                        />
                                                    )
                                                    : localStorage.getItem('country_code_iso3') === "KSA" ? (
                                                        <img
                                                            alt=""
                                                            style={{ width: "30px", height: "15px", marginLeft: "5px" }}
                                                            src="https://cdn.britannica.com/79/5779-004-DC479508/Flag-Saudi-Arabia.jpg"
                                                        />
                                                    ) : (
                                                        <img
                                                            alt=""
                                                            style={{ width: "30px", height: "15px", marginLeft: "5px" }}
                                                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Flag_of_the_United_Arab_Emirates.svg/255px-Flag_of_the_United_Arab_Emirates.svg.png"
                                                        />
                                                    )}
                                        </p>
                                    )}
                                </button>

                                <span class="dropdown-content" style={{ zIndex: 2 }}>
                                    <div style={{ backgroundColor: 'red' }}>
                                        <a style={{ cursor: "pointer" }} onClick={() => {
                                            storeLocalData("KSA");
                                            clearCart();
                                        }}>
                                            KSA
                                        </a>
                                    </div>

                                    <div style={{ backgroundColor: 'black' }}>
                                        <a style={{ cursor: "pointer" }} onClick={() => {
                                            storeLocalData("UAE");
                                            clearCart();
                                        }}>
                                            UAE
                                        </a>
                                    </div>
                                </span>
                            </span>
                        </div>
                    </div>

                    <div className="topbar-language-pic">
                        <IoEarthOutline />
                    </div>

                    <div className="topbar-language-name">
                        <span class="dropdown">
                            <button class="dropbtn">
                                {"  "}
                                {localStorage.getItem("translate") === "en"
                                    ? " English"
                                    : " العربية"}
                            </button>
                            <span class="dropdown-content">
                                <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        localStorage.setItem("translate", "ar");
                                        window.location.reload();
                                    }}
                                >
                                    العربية
                                </a>
                                <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        localStorage.setItem("translate", "en");
                                        window.location.reload();
                                    }}
                                >
                                    English
                                </a>
                            </span>
                        </span>
                    </div>
                </div>

                <div className="topbar-logo-box" style={{ zIndex: 1 }}>
                    <Link to="/">
                        <img src={logo} alt="logo" className="topbar-logo" />
                    </Link>
                </div>
                <div className="topbar-icons">
                    <ul style={{ display: "flex" }}>
                        {/* <li>
              <MdSearch style={{ paddingTop: "0.5rem" }} />
            </li> */}
                        {userInfo ? (
                            <li className="first-li" style={{ color: "white" }}>
                                <NavDropdown
                                    style={{
                                        padding: "0.5rem",
                                        color: "#c69736",
                                    }}
                                    title={
                                        defaultLanguage === 'en'
                                            ? "My Account"
                                            : "حسابي"
                                    }
                                    id="username"
                                    className="text-black text-right mr-0 dropdown-menu-right cus-dropdown-menu"
                                >
                                    <LinkContainer to="/add-address" className="">
                                        <NavDropdown.Item>
                                            {
                                                defaultLanguage === 'en'
                                                    ? "Address Book"
                                                    : "دليل العناوين"
                                            }
                                        </NavDropdown.Item>
                                    </LinkContainer>

                                    <LinkContainer to="/myorders" className="">
                                        <NavDropdown.Item>
                                            {
                                                defaultLanguage === 'en'
                                                    ? "My Orders"
                                                    : "طلباتي"
                                            }
                                        </NavDropdown.Item>
                                    </LinkContainer>

                                    <LinkContainer to="/wishlist" className="">
                                        <NavDropdown.Item>
                                            {
                                                defaultLanguage === 'en'
                                                    ? "Wishlist"
                                                    : "قائمة الرغبات"
                                            }
                                        </NavDropdown.Item>
                                    </LinkContainer>
                                    {/* <LinkContainer to="/writereview" className="">
                    <NavDropdown.Item>Your reviews</NavDropdown.Item>
                  </LinkContainer> */}

                                    {/* <LinkContainer to="/addpayment" className="">
                          <NavDropdown.Item>Payment Option</NavDropdown.Item>
                        </LinkContainer> */}

                                    <NavDropdown.Item onClick={submit}>
                                        {
                                            defaultLanguage === 'en'
                                                ? "Change Password"
                                                : "تغير كلمة المرور"
                                        }
                                    </NavDropdown.Item>

                                    <NavDropdown.Item
                                        onClick={() => {
                                            dispatch({ type: USER_LOGOUT });
                                            localStorage.removeItem("userInfo");
                                            localStorage.setItem("login", false);
                                            localStorage.removeItem("jwt_access_token");
                                            window.location.reload();
                                        }}
                                    >
                                        {
                                            defaultLanguage === 'en'
                                                ? "Logout"
                                                : "تسجيل الخروج"
                                        }
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </li>
                        ) : (
                            <li
                                className="user-icon"
                                onClick={() => {
                                    setdisplay("flex");
                                    dispatch({
                                        type: LOGIN_DISPLAY_FLEX,
                                    });
                                }}
                            >
                                <AiOutlineUser />
                            </li>
                        )}
                        <li>
                            <span className="second-li">
                                <Link to="/cart">
                                    <BsBag style={{ paddingTop: "0.5rem" }} />
                                    <p className="counter-cart">{cart ? cart.length : 0}</p>
                                </Link>
                            </span>
                        </li>
                        <li
                            className="menu-toggle"
                            onClick={() => {
                                var x = document.getElementById("main-nav");
                                if (open) {
                                    x.style.display = "none";
                                    setopen(false);
                                } else {
                                    x.style.display = "block";
                                    setopen(true);
                                }
                            }}
                        >
                            <a href="javascript:void(0);" class="icon">
                                <i class="fa fa-bars"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={`container login-container ${display === "flex" ? "activebox" : ""}`} style={{ display: display }}>
                <div className="left-box">
                    <div className="login-buttons">
                        <h1>
                            {
                                defaultLanguage === 'en'
                                    ? "Sign in Using"
                                    : "تسجيل الدخول"
                            }
                        </h1>

                        <button className="sign-btn">
                            <FaGoogle />
                            <span>Sign in with Google</span>
                        </button>
                        <button className="sign-btn">
                            <FaFacebook />
                            <span>Sign in with Facebook</span>
                        </button>
                    </div>
                    <div className="related-products or-div">
                        <div className="just-border-5"></div>
                        <div className="or-text">
                            <h1 style={{ color: "#000", fontSize: "1.2rem" }}>Or</h1>
                        </div>
                        <div className="just-border-6"></div>
                    </div>
                    <button
                        className="last-button"
                        onClick={() => {
                            document.getElementById("login").style.display = "none";
                            document.getElementById("register").style.display = "block";
                            document.getElementById("forgetPass").style.display =
                                "none";
                        }}
                    >
                        <FaGoogle />
                        <span>
                            {" "}
                            {
                                defaultLanguage === 'en'
                                    ? "Sign Up with E-mail"
                                    : "تسجيل عن طريق البريد الالكتروني"
                            }
                        </span>
                    </button>
                </div>
                <div className="right-box">
                    <div
                        onClick={() => {
                            setdisplay("none");
                            dispatch({
                                type: LOGIN_DISPLAY_NONE,
                            });
                        }}
                    >
                        <IoIosClose
                            style={{ fontSize: "3rem", position: "relative", left: "75%" }}
                        />
                    </div>
                    <div className="form-credentials" id="login">
                        <h1>
                            {
                                defaultLanguage === 'en'
                                    ? "Log In"
                                    : "تسجيل الدخول"
                            }
                        </h1>

                        <p>
                            {
                                defaultLanguage === 'en'
                                    ? "Email"
                                    : "الايميل"
                            }
                        </p>
                        <input
                            type="email"
                            placeholder={
                                defaultLanguage === 'en'
                                    ? "Enter your Email"
                                    : "ادخل بريدك الالكتروني"
                            }
                            onChange={changeHandler}
                            value={email}
                        />
                        <p>
                            {
                                defaultLanguage === 'en'
                                    ? "Password"
                                    : "الباسورد"
                            }
                        </p>
                        <input
                            type="password"
                            placeholder={
                                defaultLanguage === 'en'
                                    ? "Enter your Password"
                                    : "ادخل رمزك"
                            }
                            value={pass}
                            onChange={changeHandler1}
                        />
                        {logined === false ? (
                            <p style={{ color: "red" }}>*Please check your username and password</p>
                        ) : (
                            <p></p>
                        )}
                        <button
                            type="submit"
                            className="lower-button"
                            onClick={() => {
                                // dispatch(logins(email, pass));
                                loginFunction()
                            }}
                        >
                            {
                                defaultLanguage === 'en'
                                    ? "Sign In"
                                    : "تسجيل الدخول"
                            }
                        </button>

                        <div
                            onClick={() => {
                                document.getElementById("register").style.display = "none";
                                document.getElementById("forgetPass").style.display = "block";
                                document.getElementById("login").style.display = "none";
                            }}
                            className="lower-info"
                        >
                            <div>
                                <p style={{ cursor: "pointer" }}>
                                    {
                                        defaultLanguage === 'en'
                                            ? "forgot password?"
                                            : "هل نسيت كلمة السر"
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="form-signup-credentials" id="register">
                        <h1>
                            Register
                        </h1>
                        <p id="verify"></p>
                        <div style={{ display: "flex" }}>
                            <div>
                                <input
                                    style={{ width: "87%" }}
                                    type="text"
                                    placeholder="First Name"
                                    onChange={changeHandler2}
                                    value={fname}
                                />
                            </div>
                            <div>
                                <input
                                    style={{ width: "100%" }}
                                    type="text"
                                    placeholder="Last Name"
                                    onChange={changeHandler3}
                                    value={lname}
                                />
                            </div>
                        </div>

                        <input
                            type="email"
                            placeholder="Email"
                            value={email2}
                            onChange={changeHandler4}
                        />

                        <input
                            type="phone"
                            placeholder="phone no."
                            value={phone}
                            onChange={changeHandler5}
                        />

                        <input
                            type="password"
                            placeholder="Enter your password"
                            value={pass2}
                            onChange={changeHandler6}
                        />

                        <input
                            type="password"
                            placeholder="confirm password"
                            value={confpass}
                            onChange={changeHandler7}
                        />

                        <button
                            type="submit"
                            className="lower-button"
                            onClick={() => registerFunction()}
                        >
                            Sign Up
                        </button>

                        <div className="lower-info">
                            <div
                                onClick={() => {
                                    document.getElementById("register").style.display = "none";
                                    document.getElementById("login").style.display = "block";
                                    document.getElementById("forgetPass").style.display = "none";
                                }}
                            >
                                <p style={{ cursor: "pointer" }}>
                                    Already a member?
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="form-signup-credentials" id="forgetPass">
                        <h1 >
                            Forget Password
                        </h1>
                        <p id="verify"></p>
                        <div style={{ display: "flex" }}>
                            <div>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email2}
                                    onChange={changeHandler4}
                                />

                                <button
                                    type="submit"
                                    className="lower-button"
                                    onClick={() => {
                                        alert("Password change link send to your mail");
                                        dispatch(resetpass(email2));
                                        document.getElementById("login").style.display = "block";
                                        document.getElementById("register").style.display = "none";
                                        document.getElementById("forgetPass").style.display =
                                            "none";
                                    }}
                                >
                                    Submit
                                </button>

                                <div className="lower-info">
                                    <div
                                        onClick={() => {
                                            document.getElementById("register").style.display =
                                                "none";
                                            document.getElementById("forgetPass").style.display =
                                                "none";
                                            document.getElementById("login").style.display = "block";
                                        }}
                                    >
                                        <p style={{ cursor: "pointer" }}>
                                            Back
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Topbar;
