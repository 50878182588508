export const ADD_ITEM_TO_WISHLIST = "ADD_ITEM_TO_WISHLIST";
export const REMOVE_ITEM_FROM_WISHLIST = "REMOVE_ITEM_FROM_WISHLIST";
export const ADD_ITEM_TO_WISHLIST_SUCCESS = "ADD_ITEM_TO_WISHLIST_SUCCESS";

export const REMOVE_ITEM_FROM_WISHLIST_SUCCESS = "REMOVE_ITEM_FROM_WISHLIST_SUCCESS";
export const ADD_ITEM_TO_WISHLIST_FAIL = "ADD_ITEM_TO_WISHLIST_FAIL";
export const REMOVE_ITEM_FROM_WISHLIST_FAIL = "REMOVE_ITEM_FROM_WISHLIST_FALI";

export const WISHLIST_LIST_REQUEST = "WISHLIST_LIST_REQUEST";
export const WISHLIST_LIST_SUCCESS = "WISHLIST_LIST_SUCCESS";
export const WISHLIST_LIST_FAIL = "WISHLIST_LIST_FAIL";


