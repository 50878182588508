import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cartlist, gettax, subtotal, subtotalget } from "../../actions/cartactions";
import { listPrice } from "../../actions/productaction";
import Cartcard from "../../components/cart/cartcard";
import Checkoutbox from "../../components/checkout/checkoutsummary";
import { Coupon } from "../../components/coupons/coupon";
import { Translate } from "react-auto-translate";
import "./cart.css";
import { LOGIN_DISPLAY_FLEX } from "../../constant/user";
import Loader from "../../components/loader/loader";

export const Cart = () => {
    const dispatch = useDispatch();
    const cartList = useSelector((state) => state.addCart);
    const prices = useSelector((state) => state.priceList);
    const { price } = prices;

    const { cart, loading } = cartList;

    useEffect(() => {
        console.log(cart)

        if (JSON.parse(localStorage.getItem("login")) === true && localStorage.getItem("userInfo") !== null) {
            dispatch(cartlist(JSON.parse(localStorage.getItem("userInfo")).user));
        } else if (JSON.parse(localStorage.getItem("login")) !== true) {
            dispatch(cartlist(localStorage.getItem("guestid")));
        }

        dispatch(subtotalget());
        dispatch(listPrice());
        dispatch(gettax());
    }, [dispatch]);

    let defaultLanguage = localStorage.getItem('translate')

    return (
        <div className="cart-screen">
            <div
                style={{
                    width: "100%",
                    height: "6vh",
                    borderTop: "2px solid #fff",
                    borderBottom: "2px solid #c69736",
                    color: "#c69736",
                }}
            >
                <h3
                    style={{
                        fontWeight: "400",
                        margin: "0.4rem",
                        marginLeft: "0",
                        paddingLeft: "2rem",
                    }}
                >
                    {defaultLanguage === 'en' ? 'SHOPPING BAG' : 'حقيبة التسوق'}
                </h3>
            </div>
            {
                loading === false
                    ? <>
                        <div className="cartcard-holder">
                            {
                                cart.length > 0
                                    ? cart.map((items) => {
                                        return (
                                            <Cartcard
                                                id={items._id}
                                                productid={items.productId}
                                                img={items.image}
                                                description={items.description}
                                                title={items.title}
                                                arabictitle={items.arabictitle}
                                                count={items.count}
                                                price={price}
                                                item={items}
                                            />
                                        );
                                    })
                                    : cart.length == 0
                                        ? <p style={{ color: "gold" }}>
                                            {
                                                defaultLanguage === 'en'
                                                    ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>Cart is Empty</div>
                                                    : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>السلة فارغة</div>
                                            }
                                        </p>
                                        : <p style={{ color: "gold", cursor: "pointer", margin: "30px" }} onClick={() => {
                                            dispatch({
                                                type: LOGIN_DISPLAY_FLEX,
                                            });
                                        }}>Please Login</p>
                            }
                        </div>
                        <div className="checkkout-box">
                            <Checkoutbox item={cart.length} />
                        </div>
                    </>
                    : <>
                        <Loader text='Cart' />
                    </>
            }
            <Coupon />
        </div>
    );
};
