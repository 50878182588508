/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Translate } from "react-auto-translate";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listPrice } from "../../actions/productaction";
import "./footer.css";
import logo from "../../assets/logo.png";

export const Footer = () => {
    const [category, setcategory] = useState([]);
    const dispatch = useDispatch();

    const prices = useSelector((state) => state.priceList);
    const { price } = prices;

    useEffect(() => {
        dispatch(listPrice());
    }, [dispatch]);

    useEffect(async () => {
        const y = await axios.get(`${process.env.REACT_APP_API_URL}procategory`);
        setcategory(y.data);
    }, []);

    let defaultLanguage = localStorage.getItem('translate')
    const currentLocation = localStorage.getItem('country_code_iso3')

    const whatsapp_ksa = `https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP_KSA}&text=Hello,%20Feel%20free%20to%20ask%20any%20query`
    const whatsapp_uae = `https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP_UAE}&text=Hello,%20Feel%20free%20to%20ask%20any%20query`

    return (
        <>
            <div className="footer" id="foot">
                <div className="col-1">
                    <img
                        src={logo}
                        alt="logo"
                    />
                    <p>
                        {
                            defaultLanguage === 'en'
                                ? 'Makkaj Perfumes, pioneer in the intricate art of perfumery in the middle east, embarks on a new journey with the vision of bringing the essence of the Holy Land to the world through its Makkaj Fragrance Line.'
                                : 'تنطلق شركة مكاج للعطور الرائده في فن صناعة العطور في الشرق الاوسط رحلة جديده برؤيه تتمثل في جوهرالارض الى العالم من خلال خط عطور مكاج'
                        }
                    </p>
                </div>
                <div className="col-2">
                    <li>
                        <ul>
                            <h2>
                                {
                                    defaultLanguage === 'en'
                                        ? 'COLLECTIONS'
                                        : 'المجموعات'
                                }
                            </h2>
                        </ul>

                        <div className="footerborder"></div>

                        {category.map((data) => {
                            return (
                                data.nav_include === true && (
                                    <ul className="footer-categories">
                                        <Link to={`/category/${data.title}`}>-
                                            {
                                                defaultLanguage === 'en'
                                                    ? data.title
                                                    : data.title_ar
                                            }
                                        </Link>
                                    </ul>
                                )
                            );
                        })}
                    </li>
                </div>
                <div className="col-3">
                    {" "}
                    <li>
                        <ul>
                            <h2>
                                {
                                    defaultLanguage === 'en'
                                        ? 'OTHER LINKS'
                                        : 'روابط أخرى'
                                }
                            </h2>
                        </ul>
                        <div className="footerborder"></div>
                        <ul>
                            <Link to="/contact">- {defaultLanguage === 'en' ? 'CONTACT' : 'التواصل'}</Link>
                        </ul>
                        <ul>
                            <Link to="/delivery-and-return">- {defaultLanguage === 'en' ? 'DELIVERY AND RETURN' : 'الاستلام والاسترجاع '}</Link>
                        </ul>
                        <ul>
                            <Link to="/privacy-policy">- {defaultLanguage === 'en' ? 'PRIVACY POLICY' : 'سياسة الخصوصية '}</Link>
                        </ul>
                        <ul>
                            <Link to="/terms-and-conditions">- {defaultLanguage === 'en' ? 'TERMS AND CONDITIONS' : 'الاحكام والشروط '}</Link>
                        </ul>
                        {/* <ul>
                            <Link to="/shipping-policy">- {defaultLanguage === 'en' ? 'SHIPPING POLICY' : 'سياسة الشحن'}</Link>
                        </ul> */}
                    </li>
                </div>
                <div className="col-4">
                    <li>
                        <ul>
                            <h2>{defaultLanguage === 'en' ? 'OUR PROMISE' : ''}</h2>
                        </ul>
                        <div className="footerborder"></div>
                        <ul>
                            <p>
                                {
                                    defaultLanguage === 'en'
                                        ? 'We make emotive, uncomplicated fragrances designed to make you feel good. All our products are 100% vegan, cruelty-free and fun to use.'
                                        : 'نصنع عطور عاطفية وغير معقدة لتجعلك تشعر بالارتياح لجميع منتجاتنا ولم يتم الاساءه الى الحيوانات لصنع منتجاتنا نباتيه 100%'
                                }
                            </p>
                        </ul>
                        <ul className="flex">
                            <div>
                                <a
                                    href="https://www.instagram.com/makkajperfumes/?hl=en"
                                    target="_blank"
                                >
                                    <i className="fab fa-instagram"></i>
                                </a>
                            </div>
                            <div>
                                <a
                                    href="https://www.facebook.com/makkajperfume/"
                                    target="_blank"
                                >
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                            </div>
                            <div>
                                <a href="https://twitter.com/MakkajPerfumes" target="_blank">
                                    <i className="fab fa-twitter"></i>
                                </a>
                            </div>
                            <div>
                                <a href="https://www.snapchat.com/add/makkaj.perfumes?share_id=J5Wp4uh2bCM&locale=en-GB" target="_blank">
                                    <i className="fab fa-snapchat"></i>
                                </a>
                            </div>
                        </ul>
                    </li>
                </div>
            </div>
            <div className="footer-bottom">
                {
                    defaultLanguage === 'en'
                        ? <p className="copyName">Copyright © {new Date().getFullYear()} MAKKAJ PERFUMES KSA</p>
                        : <p className="copyName">{new Date().getFullYear()}@حقوق النشرmakkajperfumes ksa</p>
                }
            </div>
            <div className="whatsapp_div">
                {
                    currentLocation === "KSA"
                        ? <a href={whatsapp_ksa} className="btn whatsappbtn">
                            <div className="what_txt">
                                <i className="fab fa-whatsapp"></i>
                            </div>
                        </a>
                        : currentLocation === "UAE"
                            ? <a href={whatsapp_uae} className="btn whatsappbtn">
                                <div className="what_txt">
                                    <i className="fab fa-whatsapp"></i>
                                </div>
                            </a>
                            : <a href={whatsapp_uae} className="btn whatsappbtn">
                                <div className="what_txt">
                                    <i className="fab fa-whatsapp"></i>
                                </div>
                            </a>
                }
            </div>
        </>
    );
};
