/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ScrollUpButton from "react-scroll-up-button";
import { BsDroplet } from "react-icons/bs";
import { SiSonarlint } from "react-icons/si";
import { CgBee } from "react-icons/cg";
import { AiOutlinePoundCircle } from "react-icons/ai";
import { FiHexagon } from "react-icons/fi";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./Homescreen.css";
import "../../css/mainslider.css";
import "../../css/sliderbtn.css";
import Testimonial from "../../components/testimonials/testimonials";
import { useDispatch, useSelector } from "react-redux";
import { getbanner } from "../../actions/banneraction";
import { listPrice, listProducts } from "../../actions/productaction";
import axios from "axios";
import { Link } from "react-router-dom";
import getSymbolFromCurrency from "currency-symbol-map";
import { Translate } from "react-auto-translate";
import useGeoLocation from "react-ipgeolocation";

import { getCurrencySymbol, defaultLanguage, getShippingCharge } from '../../helpers';

//somewhere in your app/component
export const Homescreen = () => {
    const dispatch = useDispatch();
    const banners = useSelector((state) => state.banner);
    const { banner } = banners;

    const [datas, setdatas] = useState(null);
    const [category, setcategory] = useState([]);
    const productList = useSelector((state) => state.productList);
    const { products, loading } = productList;

    const location = useGeoLocation();

    function makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        result.toString();
        return result;
    }

    useEffect(async () => {
        if (window.wpwl !== undefined && window.wpwl.unload !== undefined) {
            window.wpwl.unload();
        }

        if (!localStorage.getItem("guestid")) {
            let y = makeid(24);
            localStorage.setItem("guestid", y);
        }

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}home`);
        setdatas(data.user[0]);

        const y = await axios.get(`${process.env.REACT_APP_API_URL}procategory`);
        setcategory(y.data.reverse());
    }, []);

    useEffect(async () => {
        let country = location.country

        let country_name = localStorage.getItem('country_name')
        let country_code = localStorage.getItem('country_code')
        let country_code_iso3 = localStorage.getItem('country_code_iso3')
        let currency = localStorage.getItem('currency')
        let currency_name = localStorage.getItem('currency_name')
        let currency_icon = localStorage.getItem('currency_icon')
        // let shipping = localStorage.getItem('shipping')
        // let seller = localStorage.getItem('seller')
        // let seller_warehouse = localStorage.getItem('seller_warehouse')

        if (country_name === null || country_code === null || country_code_iso3 === null || currency === null || currency_name === null || currency_icon === null) {
            if (country === "SA") {
                localStorage.setItem('country_name', 'Kingdom of Saudi Arabia')
                localStorage.setItem('country_code', 'SA')
                localStorage.setItem('country_code_iso3', 'KSA')
                localStorage.setItem('currency', 'SAR')
                localStorage.setItem('currency_name', 'Saudi Arabian Riyal')
                localStorage.setItem('currency_icon', 'ر.س')
                localStorage.setItem('currency_code', 'SAR')
            } else if (country === "AE") {
                localStorage.setItem('country_name', 'United Arab Emirates')
                localStorage.setItem('country_code', 'AE')
                localStorage.setItem('country_code_iso3', 'UAE')
                localStorage.setItem('currency', 'AED')
                localStorage.setItem('currency_name', 'United Arab Emirates dirham')
                localStorage.setItem('currency_icon', 'د.إ')
                localStorage.setItem('currency_code', 'AED')
            } else {
                localStorage.setItem('country_name', 'United Arab Emirates')
                localStorage.setItem('country_code', 'AE')
                localStorage.setItem('country_code_iso3', 'UAE')
                localStorage.setItem('currency', 'AED')
                localStorage.setItem('currency_name', 'United Arab Emirates dirham')
                localStorage.setItem('currency_icon', 'د.إ')
                localStorage.setItem('currency_code', 'AED')
            }

            console.log({ country_name, country_code, country_code_iso3, currency, currency_name, currency_icon })
        } else {
            console.log({ country_name, country_code, country_code_iso3, currency, currency_name, currency_icon })
        }

        if (localStorage.getItem('shipping') === null) {
            const shippingCharge = await getShippingCharge(0)
            console.log('shipping', shippingCharge)
        }
    }, []);

    const prices = useSelector((state) => state.priceList);
    const { price } = prices;

    useEffect(() => {
        dispatch(getbanner());
        dispatch(listProducts());
        dispatch(listPrice());
    }, [dispatch]);

    let filterProduct = products.filter(
        (item) => item.category === "bestseller"
    );

    let defaultLanguage = localStorage.getItem('translate')

    if (banner.length > 0 && datas != null) {
        return localStorage.getItem("translate") === "en" ? (
            <div style={{
                fontFamily:
                    localStorage.getItem("translate") == "ar" &&
                    localStorage.getItem("translate") == null &&
                    "Times New Roman",
            }}>
                <OwlCarousel
                    className="owl-theme"
                    dots={true}
                    navText={[
                        "<div className='nav-btn prev-slides'><i class='fa fa-chevron-left'></i></div>",
                        "<div className='nav-btn next-slides'><i class='fa fa-chevron-right'></i></div>",
                    ]}
                    lazyLoad={true}
                    autoplay={true}
                    items={1}
                    autoHeight={false}
                    loop
                    margin={0}
                    nav
                >
                    {banner.length > 0 ? (
                        banner.map((items, index) => {
                            return (
                                <div class="item" key={index}>
                                    <Link to={items.link}><img src={items.image} alt="" /></Link>
                                </div>
                            );
                        })
                    ) : (
                        <div></div>
                    )}
                </OwlCarousel>
                <div className="sliderbottom-box">
                    <div className="sliderbottom-head1">
                        <p>{datas.title.INR}</p>
                    </div>
                    <div className="sliderbottom-head2">
                        <p>{datas.quote.INR}</p>
                    </div>
                    <div className="sliderbottom-head3">
                        <p>{datas.quotetext.INR}</p>
                    </div>
                    <div className="sliderbottom-icons">
                        <div className="sliderbottom-flex">
                            <div>
                                <BsDroplet />
                                <p>{datas.items1.INR}</p>
                            </div>
                            <div>
                                <SiSonarlint />
                                <p>{datas.items2.INR}</p>
                            </div>
                            <div>
                                <CgBee />
                                <p>{datas.items3.INR}</p>
                            </div>
                            <div>
                                <FiHexagon />
                                <p>{datas.items4.INR}</p>
                            </div>
                            <div>
                                <AiOutlinePoundCircle />
                                <p>{datas.items5.INR}</p>
                            </div>
                        </div>
                    </div>
                    <div className="sliderbottom-know">
                        <Link to='/about-us'> <p className="button-know">KNOW MORE</p></Link>
                    </div>
                </div>
                <div className="categories">
                    <h3>DISCOVER</h3>
                    <h1>{datas.imagehead.INR}</h1>
                    <div className="row-images">
                        {category.map((data, index) => {
                            if (index < 4) {
                                return data.home_include == true && (
                                    <Link to={`/category/${data.title}`} className="y">
                                        <div
                                            className="firstimage"
                                            style={{ backgroundImage: `url(${data.image})` }}
                                        >
                                            <button className="info-label">{data.title}</button>
                                        </div>
                                    </Link>
                                );
                            }
                        })}
                    </div>
                </div>

                <div className="largeimage" style={{
                    backgroundImage: "url('images/ads.jpg')",
                }}>
                    <div className="inner">
                        {/* <h1>{datas.imagehead1.INR}</h1> */}
                        <h1>THE ART OF PERFUMES</h1>

                        <div className="borderbottom"></div>
                        {/* <p>{datas.image3title.INR}</p> */}
                        <p> According to science, the sense of smell is something that evokes strong emotions. That’s how significant perfume is! Therefore, you should be paying more attention to the kind of fragrances you choose to wear for different occasions.</p>
                    </div>
                </div>

                <div className="bestseller-section">
                    <h3>BEST SELLER</h3>
                    <h1> {datas.head4.INR}</h1>
                    <div className="bestseller-innersection">
                        <div className="first">
                            {filterProduct[0] != null && <Link to={`/details/${filterProduct[0]._id}/${filterProduct[0].title}`}><div className="divide">
                                <img className="bestseller-img-1"
                                    src={filterProduct[0].image}
                                    alt="perfume"
                                />
                                <h3>{filterProduct[0].title}</h3>
                                <h4>{localStorage.getItem("translate") == "en" && price != "INR" ? price : getSymbolFromCurrency(price)} {filterProduct[0].price[price]}</h4>
                            </div></Link>}
                            {filterProduct[1] != null && <Link to={`/details/${filterProduct[1]._id}/${filterProduct[0].title}`}><div className="divide1">
                                <img className="bestseller-img-1"
                                    src={filterProduct[1].image}
                                    alt="perfume"
                                />
                                <h3>{filterProduct[1].title}</h3>
                                <h4>{localStorage.getItem("translate") == "en" && price != "INR" ? price : getSymbolFromCurrency(price)} {filterProduct[1].price[price]}</h4>
                            </div></Link>}
                        </div>
                        <div className="first">
                            {filterProduct[2] != null && <Link to={`/details/${filterProduct[2]._id}/${filterProduct[0].title}`}><div >
                                <img className="bestseller-img-2"
                                    src={filterProduct[2].image}
                                    alt="perfume"
                                />
                                <h3>{filterProduct[2].title}</h3>
                                <h4>{localStorage.getItem("translate") == "en" && price != "INR" ? price : getSymbolFromCurrency(price)} {filterProduct[2].price[price]}</h4>
                            </div></Link>}
                        </div>
                        <div className="first">
                            {filterProduct[3] != null && <Link to={`/details/${filterProduct[3]._id}/${filterProduct[0].title}`}><div className="divide2">
                                <img className="bestseller-img-1"
                                    src={filterProduct[3].image}
                                    alt="perfume"
                                />
                                <h3>{filterProduct[3].title}</h3>
                                <h4>{localStorage.getItem("translate") == "en" && price != "INR" ? price : getSymbolFromCurrency(price)} {filterProduct[3].price[price]}</h4>
                            </div></Link>}
                            {filterProduct[4] != null && <Link to={`/details/${filterProduct[4]._id}/${filterProduct[0].title}`}><div className="divide3">
                                <img className="bestseller-img-1"
                                    src={filterProduct[4].image}
                                    alt="perfume"
                                />
                                <h3>{filterProduct[4].title}</h3>
                                <h4>{localStorage.getItem("translate") == "en" && price != "INR" ? price : getSymbolFromCurrency(price)} {filterProduct[4].price[price]}</h4>
                            </div></Link>}
                        </div>
                        <div className="first">
                            {filterProduct[5] != null && <Link to={`/details/${filterProduct[5]._id}/${filterProduct[0].title}`}><div >
                                <img className="bestseller-img-2"
                                    src={filterProduct[5].image}
                                    alt="perfume"
                                />
                                <h3>{filterProduct[5].title}</h3>
                                <h4>{localStorage.getItem("translate") == "en" && price != "INR" ? price : getSymbolFromCurrency(price)} {filterProduct[5].price[price]}</h4>
                            </div></Link>}
                        </div>
                    </div>
                </div>

                <Testimonial datas={datas} />
                <ScrollUpButton style={{ width: 75 }} ToggledStyle={{ right: 100 }} />
            </div>
        ) : (
            <div style={{
                fontFamily:
                    localStorage.getItem("translate") == "ar" &&
                    localStorage.getItem("translate") == null &&
                    "Times New Roman",
            }}>
                <OwlCarousel
                    className="owl-theme"
                    dots={true}
                    navText={[
                        "<div className='nav-btn prev-slides'><i class='fa fa-chevron-left'></i></div>",
                        "<div className='nav-btn next-slides'><i class='fa fa-chevron-right'></i></div>",
                    ]}
                    lazyLoad={true}
                    autoplay={true}
                    items={1}
                    autoHeight={false}
                    loop
                    margin={0}
                    nav
                >
                    {banner.length > 0 ? (
                        banner.map((items, index) => {
                            return (
                                <div class="item" key={index}>
                                    <Link to={items.link}><img src={items.image} alt="" /></Link>
                                </div>
                            );
                        })
                    ) : (
                        <div></div>
                    )}
                </OwlCarousel>
                <div className="sliderbottom-box">
                    <div className="sliderbottom-head1">
                        <p>{datas.title.AED}</p>
                    </div>
                    <div className="sliderbottom-head2">
                        <p>{datas.quote.AED}</p>
                    </div>
                    <div className="sliderbottom-head3">
                        <p>{datas.quotetext.AED}</p>
                    </div>
                    <div className="sliderbottom-icons">
                        <div className="sliderbottom-flex">
                            <div>
                                <BsDroplet />
                                <p>{datas.items1.AED}</p>
                            </div>
                            <div>
                                <SiSonarlint />
                                <p>{datas.items2.AED}</p>
                            </div>
                            <div>
                                <CgBee />
                                <p>{datas.items3.AED}</p>
                            </div>
                            <div>
                                <FiHexagon />
                                <p>{datas.items4.AED}</p>
                            </div>
                            <div>
                                <AiOutlinePoundCircle />
                                <p>{datas.items5.AED}</p>
                            </div>
                        </div>
                    </div>
                    <div className="sliderbottom-know">
                        <Link to='/about-us'> <p className="button-know">{defaultLanguage === 'en' ? 'Know More' : 'للتعرف اكثر'}</p></Link>
                    </div>
                </div>
                <div className="categories">
                    <h3>{defaultLanguage === 'en' ? 'DISCOVER' : 'يكتشف'}</h3>
                    <h1>{datas.imagehead.AED}</h1>
                    <div className="row-images">
                        {category.map((data, index) => {
                            if (index < 4) {
                                return data.home_include == true && (
                                    <Link to={`/category/${data.title}`} className="y">
                                        <div className="firstimage" style={{ backgroundImage: `url(${data.image})` }}>
                                            <button className="info-label">{defaultLanguage === 'en' ? data.title : data.title_ar}</button>
                                        </div>
                                    </Link>
                                );
                            }
                        })}
                    </div>
                </div>

                <div className="largeimage" style={{
                    backgroundImage: "url('images/ads.jpg')",
                }}>
                    <div className="inner">
                        {/* <h1>{datas.imagehead1.INR}</h1> */}
                        <h1>فن العطور</h1>

                        <div className="borderbottom"></div>
                        {/* <p>{datas.image3title.INR}</p> */}
                        <p>وفقا للعلم,فان حاسة الشم هي شيء يثير مشاعر قوية مدى اهمية العطر ولذلك يجب ان تعطي المزيد من الاهتمام بنوع العطر التي تختارها للمناسبات المختلفة.</p>
                    </div>
                </div>

                <div className="bestseller-section">
                    {
                        defaultLanguage === 'en'
                            ? <h3>BEST SELLER</h3>
                            : <h3>الاكثر مبيعا</h3>
                    }
                    <h1> {datas.head4.AED}</h1>
                    <div className="bestseller-innersection">
                        <div className="first">
                            {filterProduct[0] != null && <Link to={`/details/${filterProduct[0]._id}/${filterProduct[0].title}`}><div className="divide">
                                <img className="bestseller-img-1"
                                    src={filterProduct[0].image}
                                    alt="perfume"
                                />
                                <h3>{filterProduct[0].arabictitle}</h3>
                                <h4>{localStorage.getItem("translate") == "en" && price != "INR" ? price : getSymbolFromCurrency(price)} {filterProduct[0].price[price]}</h4>
                            </div></Link>}
                            {filterProduct[1] != null && <Link to={`/details/${filterProduct[1]._id}/${filterProduct[0].title}`}><div className="divide1">
                                <img className="bestseller-img-1"
                                    src={filterProduct[1].image}
                                    alt="perfume"
                                />
                                <h3>{filterProduct[1].arabictitle}</h3>
                                <h4>{localStorage.getItem("translate") == "en" && price != "INR" ? price : getSymbolFromCurrency(price)} {filterProduct[1].price[price]}</h4>
                            </div></Link>}
                        </div>
                        <div className="first">
                            {filterProduct[2] != null && <Link to={`/details/${filterProduct[2]._id}/${filterProduct[0].title}`}><div >
                                <img className="bestseller-img-2"
                                    src={filterProduct[2].image}
                                    alt="perfume"
                                />
                                <h3>{filterProduct[2].arabictitle}</h3>
                                <h4>{localStorage.getItem("translate") == "en" && price != "INR" ? price : getSymbolFromCurrency(price)} {filterProduct[2].price[price]}</h4>
                            </div></Link>}
                        </div>
                        <div className="first">
                            {filterProduct[3] != null && <Link to={`/details/${filterProduct[3]._id}/${filterProduct[0].title}`}><div className="divide2">
                                <img className="bestseller-img-1"
                                    src={filterProduct[3].image}
                                    alt="perfume"
                                />
                                <h3>{filterProduct[3].arabictitle}</h3>
                                <h4>{localStorage.getItem("translate") == "en" && price != "INR" ? price : getSymbolFromCurrency(price)} {filterProduct[3].price[price]}</h4>
                            </div></Link>}
                            {filterProduct[4] != null && <Link to={`/details/${filterProduct[4]._id}/${filterProduct[0].title}`}><div className="divide3">
                                <img className="bestseller-img-1"
                                    src={filterProduct[4].image}
                                    alt="perfume"
                                />
                                <h3>{filterProduct[4].arabictitle}</h3>
                                <h4>{localStorage.getItem("translate") == "en" && price != "INR" ? price : getSymbolFromCurrency(price)} {filterProduct[4].price[price]}</h4>
                            </div></Link>}
                        </div>
                        <div className="first">
                            {filterProduct[5] != null && <Link to={`/details/${filterProduct[5]._id}/${filterProduct[0].title}`}><div >
                                <img className="bestseller-img-2"
                                    src={filterProduct[5].image}
                                    alt="perfume"
                                />
                                <h3>{filterProduct[5].arabictitle}</h3>
                                <h4>{localStorage.getItem("translate") == "en" && price != "INR" ? price : getSymbolFromCurrency(price)} {filterProduct[5].price[price]}</h4>
                            </div></Link>}
                        </div>
                    </div>
                </div>

                <Testimonial datas={datas} />
                <ScrollUpButton style={{ width: 75 }} ToggledStyle={{ right: 100 }} />
            </div>
        );
    }
    return <div></div>;
};